<div class="ui-g">
  <div class="ui-g-12">
    <div class="title">
      <h1>{{'proposal_title' | translate}}</h1>
    </div>
  </div>
  <div class="content">
    <div>
      <div class="ui-g-3" *ngFor="let proposal of proposals.proposals; let i = index;">
        <div class="proposal" [ngStyle]="{ 'background' : getColor(i) }">
          <h5>{{proposal}}</h5>
        </div>
      </div>
    </div>
    <mat-card class="docs-container" *ngIf="proposals.documents.length > 0">
        <img
          *ngIf="proposals.documents && !selectDocs"
          class="select_elements"
          (click)="activeSelection()"
          src="./assets/icons/add_to_cart.png"  alt="add_to_cart"/>
        <img
          *ngIf="selectDocs"
          class="select_elements"
          (click)="addToCart()"
          src="./assets/icons/ic_add_customer.png"  alt="add_customer"/>
        <div class="docs-container-line" *ngFor="let doc of proposals.documents">
          <img [src]="mimeHelper.getIconFromFileName(doc.path)" alt="fileType"/>
          <a (click)="!selectDocs && mediaHelper.openMedia(doc.path)">
              <h6>{{doc.displayName}}</h6>
          </a>
          <mat-checkbox
            *ngIf="selectDocs"
            (change)="selectItem($event, doc)"
            [checked]="isSelected(doc)"
            color="primary">
          </mat-checkbox>
        </div>
      </mat-card>
  </div>
</div>
