import {Directive, DoCheck, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';

@Directive({selector: '[appVscroll]'})
export class VscrollDirective implements DoCheck, OnInit {

  @Input() vscrollHidden: any = null;
  @Input() vscrollColor: any  = null;
  @Input() vscrollSize: any   = '5vw';

  private el: ElementRef;
  private readonly container: ElementRef;
  private readonly up: ElementRef;
  private readonly down: ElementRef;
  private renderer: Renderer2;
  private currentHeight: number;
  private currentWidth: number;
  private currentTop: string;
  private currentLeft: string;

  constructor(el: ElementRef, renderer: Renderer2) {
    this.el       = el;
    this.renderer = renderer;
    renderer.setStyle(el.nativeElement, 'position', 'relative');
    renderer.setStyle(el.nativeElement, 'overflow-y', 'scroll');

    const container = this.container = renderer.createElement('div');
    renderer.setStyle(container, 'position', 'absolute');
    renderer.setStyle(container, 'overflow', 'hidden');
    renderer.setStyle(container, 'opacity', '1');

    const up = this.up = renderer.createElement('i');
    renderer.addClass(up, 'fas');
    renderer.addClass(up, 'fa-chevron-circle-up');
    renderer.setStyle(up, 'position', 'absolute');
    renderer.setStyle(up, 'top', '0');
    renderer.setStyle(up, 'z-index', '10');
    renderer.setStyle(up, 'transform', 'translateX(-50%)');
    renderer.appendChild(container, up);

    const down = this.down = renderer.createElement('i');
    renderer.addClass(down, 'fas');
    renderer.addClass(down, 'fa-chevron-circle-down');
    renderer.setStyle(down, 'position', 'absolute');
    renderer.setStyle(down, 'bottom', '5vw');
    renderer.setStyle(down, 'z-index', '10');
    renderer.setStyle(down, 'transform', 'translateX(-50%)');
    renderer.appendChild(container, down);

    this.addElements();
  }

  @HostListener('scroll') onScroll(): void {
    if (!this.vscrollHidden && this.el.nativeElement.scrollHeight - this.el.nativeElement.clientHeight > 10) {
      if (this.el.nativeElement.scrollTop === 0) {
        this.renderer.setStyle(this.up, 'visibility', 'hidden');
      } else {
        this.renderer.setStyle(this.up, 'visibility', 'visible');
      }

      if ((this.el.nativeElement.scrollHeight - this.el.nativeElement.scrollTop - this.el.nativeElement.clientHeight) < 1) {
        this.renderer.setStyle(this.down, 'visibility', 'hidden');
      } else {
        this.renderer.setStyle(this.down, 'visibility', 'visible');
      }
    }
  }

  ngOnInit(): void {
    const color = this.vscrollColor || '#37609a';
    this.renderer.setStyle(this.up, 'color', color);
    this.renderer.setStyle(this.down, 'color', color);
    this.renderer.setStyle(this.up, 'font-size', this.vscrollSize);
    this.renderer.setStyle(this.up, 'left', '50%');
    this.renderer.setStyle(this.down, 'font-size', this.vscrollSize);
    this.renderer.setStyle(this.down, 'left', '50%');
  }

  ngDoCheck(): void {
    if (this.el.nativeElement.scrollHeight - this.el.nativeElement.clientHeight < 10) {
      this.renderer.setStyle(this.down, 'visibility', 'hidden');
      this.renderer.setStyle(this.up, 'visibility', 'hidden');
    }

    if (!!this.vscrollHidden) {
      this.renderer.setStyle(this.down, 'visibility', 'hidden');
      this.renderer.setStyle(this.up, 'visibility', 'hidden');
    }

    if (this.el.nativeElement.offsetWidth !== this.currentWidth) {
      this.currentWidth = this.el.nativeElement.offsetWidth;
      this.renderer.setStyle(this.container, 'width', `${this.el.nativeElement.offsetWidth}px`);
    }

    if (this.el.nativeElement.offsetHeight !== this.currentHeight) {
      this.currentHeight = this.el.nativeElement.offsetHeight;
      this.renderer.setStyle(this.container, 'height', `${this.currentHeight}px`);
    }

    if (this.el.nativeElement.offsetTop !== this.currentTop) {
      this.currentTop = this.el.nativeElement.offsetTop;
      this.renderer.setStyle(this.container, 'top', `${this.currentTop}px`);
    }

    if (this.el.nativeElement.offsetLeft !== this.currentLeft) {
      this.currentLeft = this.el.nativeElement.offsetLeft;
      this.renderer.setStyle(this.container, 'left', `${this.currentLeft}px`);
    }
  }

  private addElements(): void {
    if (!this.el.nativeElement.parentNode) {
      setTimeout(() => {
        this.addElements();
      }, 100);
      return;
    }

    this.renderer.insertBefore(this.el.nativeElement.parentNode, this.container, this.el.nativeElement);
  }
}
