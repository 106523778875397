<div class="media_container" *ngIf="!filterString">
  <div class="ui-g scroll_container">
    <div class="ui-g-3 titles">
      <h1>
        {{ 'markets_sales_arguments' | translate }}
      </h1>
    </div>
    <div class="ui-g-9 media-grid">
      <div class="separator_line_container">
        <div class="separator_line"></div>
      </div>
    </div>
    <div class="ui-g-12 media-grid">
      <div *ngFor="let subMarket of subMarkets | search:'Name':filterString">
        <div class="ui-g-3">
          <button
            color="primary"
            mat-raised-button
            class="media_category"
            (click)="openMarketMediaDetail(subMarket.Name)">
            <h4>{{ subMarket.Name }}</h4>
          </button>
        </div>
      </div>
    </div>
    <div class="ui-g-3 titles">
      <h1>
        {{ 'offers_selling_points' | translate }}
      </h1>
    </div>
    <div class="ui-g-9 media-grid">
      <div class="separator_line_container">
        <div class="separator_line"></div>
      </div>
    </div>
    <div class="ui-g-12 media-grid">
      <div *ngFor="let subOffer of subOffers | search:'Name':filterString; let i = index">
        <div class="ui-g-3">
          <button
            color="primary"
            mat-raised-button
            class="media_category"
            (click)="openMediaDetail(i)">
            <h4>{{ subOffer.Name }}</h4>
          </button>
        </div>
      </div>
    </div>
    <div class="ui-g-3 titles">
      <h1>
        {{ 'OTHERS' | translate }}
      </h1>
    </div>
    <div class="ui-g-9 media-grid">
      <div class="separator_line_container">
        <div class="separator_line"></div>
      </div>
    </div>
    <div class="ui-g-12 media-grid">
      <div *ngFor="let i = index; let other of others | search:'Name':filterString">
        <div class="ui-g-3">
          <button
            color="primary"
            mat-raised-button
            class="media_category"
            (click)="openOtherMediaDetail(i)">
            <h4>{{ other.Name }}</h4>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-welcome-detail
  [filterString]="filterString"
  [medias]="medias"
  [isSearchView]="false"
  *ngIf="isDetail && !filterString">
</app-welcome-detail>

<div class="media_container media_container_search" *ngIf="filterString">
    <app-welcome-detail *ngFor="let subMarket of subMarkets"
      [medias]="segments[subMarket.Name]"
      [filterString]="filterString"></app-welcome-detail>
    <app-welcome-detail
      [medias]="process"
      [filterString]="filterString"></app-welcome-detail>
    <app-welcome-detail
      [medias]="gas"
      [filterString]="filterString"></app-welcome-detail>
    <app-welcome-detail
      [medias]="supply"
      [filterString]="filterString"></app-welcome-detail>
    <app-welcome-detail *ngFor="let otherDetail of other"
      [medias]="otherDetail"
      [filterString]="filterString"></app-welcome-detail>
</div>
