import {ArrayTyper} from '@caliatys/array-typer';
import {ALDocument} from './document.model';


export class MediaDocument {
  mediaPremiumGas: ALDocument[];
  mediaSupplyModes: ALDocument[];
  mediaProcessSolution: ALDocument[];
  mediaBeyond: ALDocument[];

  constructor(json: any) {
    if (!json) {
      return null;
    }

    this.mediaPremiumGas      = ArrayTyper.asArray(ALDocument, json.PremiumGas || []);
    this.mediaProcessSolution = ArrayTyper.asArray(ALDocument, json.ProcessSolution || []);
    this.mediaSupplyModes     = ArrayTyper.asArray(ALDocument, json.SupplyMode || []);
    this.mediaBeyond          = ArrayTyper.asArray(ALDocument, json.Beyond || []);
  }
}
