import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {CookieService} from 'ngx-cookie-service';
import {Observable, throwError} from 'rxjs';
import {catchError, timeout} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

/*HELPERS*/
import {StorageHelper} from '../helpers/storage.helper';
import {GContact} from '../models/gcontact.model';

/*MODELS*/
import {User} from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  static readonly SIGNATURE_KEY = 'SIGNATURE';
  static readonly CONTACTS_KEY  = 'CONTACTS';
  users: User[];
  headers: HttpHeaders          = null;
  options: { headers: any, withCredentials: true }     = null;

  constructor(public  http: HttpClient,
              private storageHelper: StorageHelper,
              private _cookieService: CookieService) {
    this.setHeaders();
  }

  public sendMail(subject: string, htmlContent: string, contactsTo: GContact[], contactsCc: GContact[] = []): Observable<void> {
    this.setHeaders();

    const body = {Subject: subject, To: contactsTo, Cc: contactsCc, Body: htmlContent};

    return this.http.post<void>(environment.api + '/mail', JSON.stringify(body), this.options)
      .pipe(
        timeout(environment.xhrTimeout),
        catchError(err => throwError(err || 'Server error'))
      );
  }

  public saveSignature(htmlText: string): void {
    this.storageHelper.saveItem(htmlText, MailService.SIGNATURE_KEY);
  }

  public getSignature(): string {
    return this.storageHelper.getItem(MailService.SIGNATURE_KEY) as string;
  }

  public saveContacts(contacts: GContact[]): void {
    this.storageHelper.saveItem(contacts, MailService.CONTACTS_KEY);
  }

  public getContacts(): GContact[] {
    return this.storageHelper.getItem(MailService.CONTACTS_KEY) as GContact[];
  }

  private setHeaders(): void {
    this.headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        Accept        : 'application/json',
      });
    this.options = {headers: this.headers, withCredentials: true};
  }
}
