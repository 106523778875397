import {Boundary} from './boundary.model';

export class Neighbour {
  boundary: Boundary;
  sectorPosition: number;

  constructor(json: any) {
    if (!json) {
      return null;
    }
    this.boundary       = new Boundary(json.bounds);
    this.sectorPosition = json.sector;
  }
}
