<div class="component_content">
  <div class="title">
    <h1>{{'main_expectation_title' | translate}}</h1>
  </div>
<div class="ui-g">
  <div class="ui-g-4">
    <div
      (click)="removeFromSelection(0)"
      [ngClass]="{'selected_expectation' : getItemByIndex(1),
                'unselected_expectation' : !getItemByIndex(1)}" >
      <div *ngIf="selectedItems[0]">
        <p>{{ selectedItems[0] }}</p>
      </div>
    </div>
  </div>
  <div class="ui-g-4">
    <div
      (click)="removeFromSelection(1)"
      [ngClass]="{'selected_expectation' : getItemByIndex(2),
                'unselected_expectation' : !getItemByIndex(2)}" >
        <div *ngIf="selectedItems[1]">
            <p>{{ selectedItems[1] }}</p>
        </div>
    </div>
  </div>
  <div class="ui-g-4">
    <div
      (click)="removeFromSelection(2)"
      [ngClass]="{'selected_expectation' : getItemByIndex(3),
                'unselected_expectation' : !getItemByIndex(3)}" >
        <div *ngIf="selectedItems[2]">
            <p>{{ selectedItems[2] }}</p>
        </div>
    </div>
  </div>
  <div class="ui-g-4" *ngFor="let expectation of expectations.expectations">
    <button
      mat-raised-button
      (click)="addToSelection(expectation)"
      class="expectation">
      {{expectation}}
    </button>
  </div>
</div>
<div class="done-container">
  <button
    mat-raised-button
    color="primary"
    (click)="addToCart()"
    [disabled]="!selectionIsValid()"
    class="done">
    {{'ok' | translate}}
  </button>
</div>
</div>
