import {v4 as uuid} from 'uuid';

export class GContact {
  id: string;
  Name: string;
  Email: string;

  constructor() {
    this.id = uuid();
  }
}
