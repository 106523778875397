import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';
import {AppComponent} from '../app.component';

/*COMPONENTS*/
import {LoadingComponent} from '../shared/components/loading/loading.component';

/*ENUMS*/
import {AnalyticsType} from '../shared/enums/analytics.enum';

/*HELPERS*/
import {NavigationHelper} from '../shared/helpers/navigation.helper';
import {ThemeHelper} from '../shared/helpers/theme.helper';

/*MODELS*/
import {AppData} from '../shared/models/appdata.model';
import {AnalyticsService} from '../shared/services/analytics.service';

/*SERVICES*/
import {CatalogService} from '../shared/services/catalog.service';

@Component({
  templateUrl: 'supply-modes.component.html',
  styleUrls  : ['supply-modes.component.css'],
})

export class SupplyModesComponent {
  public supplyModes: any = null;

  private loadingRef: any = null;

  public constructor(private catalogService: CatalogService,
                     private navigationHelper: NavigationHelper,
                     private analyticsService: AnalyticsService,
                     private themeHelper: ThemeHelper,
                     private parent: AppComponent,
                     private translate: TranslateService,
                     private dialog: MatDialog,
                     private snackBar: MatSnackBar,
                     private router: Router) {
    this.parent.activeMenu();

    this.themeHelper.setPageTitle('');

    this.supplyModes = this.navigationHelper.routerData;

    if (this.supplyModes === undefined || this.supplyModes === null) // INFO: User refresh page case
    {
      this.supplyModes = {tiles: [], title: null};
      this.showLoadingDialog(true);
      this.catalogService.fetchCurrentData()
        .then(data => {
          this.showLoadingDialog(false);
          this.onSuccessParseData(data);
        })
        .catch((err) => {
          this.showLoadingDialog(false);
          this.snackBar.open(err, 'x',
            {
              duration: 5000
            });
          throw new Error('HomeComponent : constructor -> ' + err);
        });
    }

    this.analyticsService.sendEvent(AnalyticsType.OPEN_VIEW, this.router.url);

    this.navigationHelper.routerData = null;
  }

  private onSuccessParseData(data: AppData): void {
    Object.keys(data.supplyModeTiles)
      .forEach((item) => {
        this.supplyModes.tiles.push(data.supplyModeTiles[item]);
      });
  }

  private showLoadingDialog(open: boolean): void {
    if (!open && this.loadingRef !== null) {
      this.loadingRef.close();
      this.loadingRef = null;
      return;
    }

    this.loadingRef = this.dialog.open(LoadingComponent, {disableClose: true});
  }
}
