<h1 mat-dialog-title>{{ 'sing_in' | translate }}</h1>
<form (ngSubmit)="login()" [formGroup]="loginForm">
  <mat-dialog-content class="popup-content">
    <div class="ui-g">
      <div class="ui-g-12">
        <mat-form-field class="add-input">
          <input
              matInput
              placeholder="{{ 'sign_salesapp_username' | translate }}"
              type="text"
              formControlName="username">
          <mat-hint
              class="hint_errors"
              align="start"
              *ngIf="loginForm.controls.username.errors">
            {{ 'REQUIRED_FIELD' | translate }}
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="ui-g-12">
        <mat-form-field class="add-input">
          <input
              matInput
              placeholder="{{ 'sign_salesapp_password' | translate }}"
              type="password"
              formControlName="password">
          <mat-hint
              class="hint_errors"
              align="start"
              *ngIf="loginForm.controls.password.errors">
            {{ 'REQUIRED_FIELD' | translate }}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="dialog_actions">
      <button
          mat-raised-button
          [mat-dialog-close]="false"
          class="dialog_left_button"
          type="button"
          color="primary">
        {{ 'cancel' | translate }}
      </button>
      <button
          mat-raised-button
          type="submit"
          class="dialog_right_button"
          [disabled]="!loginForm.valid"
          color="primary">
        {{ 'ok' | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</form>
