<div *ngIf="service" class="component_content">
  <mat-grid-list cols="4" rowHeight="fit">
    <mat-grid-tile [colspan]="4">
      <div class="header">
        <img class="header-background" [src]="service.backgroundPath | fromStorage"  alt="header_background"/>
        <img
          *ngIf="service.documents &&  !selectDocs"
          class="select_elements"
          (click)="activeSelection()"
          src="./assets/icons/add_to_cart.png"  alt="add_to_cart"/>
        <img
          *ngIf="selectDocs"
          class="select_elements"
          (click)="addToCart()"
          src="./assets/icons/ic_add_customer.png"  alt="add_customer"/>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="3" [rowspan]="4">
      <mat-card >
          <div class="content-text" [innerHTML]="sanitizer.bypassSecurityTrustHtml(service.subtitle)">
          </div>

          <div class="content-text" [innerHTML]="sanitizer.bypassSecurityTrustHtml(service.html)">
          </div>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="4">
      <mat-card>
        <div class="docs-container-line" *ngFor="let doc of service.documents">
          <img [src]="mimeHelper.getIconFromFileName(doc.path)" alt="filetype"/>
          <a (click)="!selectDocs && mediaHelper.openMedia(doc.path)">
              <h6>{{doc.displayName}}</h6>
          </a>
          <mat-checkbox
            *ngIf="selectDocs"
            [checked]="isSelected(doc)"
            (change)="selectItem($event, doc)"
            color="primary">
          </mat-checkbox>
        </div>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
