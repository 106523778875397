import {ArrayTyper} from '@caliatys/array-typer';
import {Boundary} from './boundary.model';
import {Legend} from './legend.model';
import {Neighbour} from './neighbour.model';
import {Point} from './point.model';


export class Sector {
  id: string;
  name: string; // INFO: Name of infography zone
  nameAnchor: Point; // INFO: the place of the name bubbles (x, y) in percent
  pointAnchor: Point; // INFO: the place of the bubble arrow   (x, y) in percent
  boundary: Boundary; // INFO: clickable zone of image
  infographyPath: string; // INFO: path of zoomed image
  legends: Legend[]; // INFO: bubbles of zoomed image
  neighbours: Neighbour[]; // INFO: Unused
  pins: Point[][]; // INFO: List of process points
  applicationIds: number[]; // INFO: List of process ids
  pinSorted = false;

  constructor(json: any) {
    if (!json) {
      return null;
    }

    this.id             = json.Id;
    this.name           = json.Name;
    this.nameAnchor     = new Point(json.NameAnchorArray);
    this.pointAnchor    = new Point(json.PointAnchorArray);
    this.boundary       = new Boundary(json.Boundary);
    this.infographyPath = json.InfographyPath;
    this.legends        = ArrayTyper.asArray(Legend, json.Legends || []);
    this.neighbours     = ArrayTyper.asArray(Neighbour, json.Neighbours || []);

    const pins: Point[][] = [];

    if (json.DisplayedPins) {
      for (const pointArray of JSON.parse(json.DisplayedPins)) {
        pins.push(ArrayTyper.asArray(Point, pointArray || []));
      }
    }

    this.pins           = pins;
    this.applicationIds = json.DisplayedApplicationsIds ? json.DisplayedApplicationsIds.split(',').map(Number) : [];
  }
}
