import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

import {ThemeHelper} from './theme.helper';

@Injectable({
  providedIn: 'root'
})
export class NavigationHelper {
  get routerData(): any // INFO: get navigation data object
  {
    return this.data;
  }

  set routerData(data: any) // INFO: set navigation data object
  {
    this.data = data;
  }

  public activatedComponent: Observable<any>; // = null;
  public observer: any; // = null;
  public videoObserver: any; // = null;
  public zoomInfoObserver: any; // = null;
  public viewProcessObserver: any; // = null;
  public mediaComponentObserver: any; // = null;
  public gasComponentObserver: any; // = null;
  public gasDetailComponentObserver: any; // = null;
  public customerComponentObserver: any; // = null;
  public mediaDetailObserver: any;
  public mediaObserver: any; // = null;
  public carouselIndex: number;
  private navItem: number; // = 0;
  private data: any; // = null;
  private _selectedMarketId: any;

  public get selectedMarketId(): any {
    return this._selectedMarketId;
  }

  public set selectedMarketId(value) {
    this._selectedMarketId = value;
  }

  public constructor(private router: Router,
                     private themeHelper: ThemeHelper,
                     private translate: TranslateService,
                     private location: Location) {
    this.navItem = 0;
  }

  public resetNavItem(): void {
    this.navItem = 0;
  }

  public back(): any // INFO: Activated Component maybe (true | null)
  {
    if (this.videoObserver !== undefined && this.videoObserver !== null) {
      this.videoObserver.next();
      this.videoObserver = null;
      return;
    }

    if (this.mediaDetailObserver !== undefined && this.mediaDetailObserver !== null) {
      this.mediaDetailObserver.next();
      this.mediaDetailObserver = null;
      return;
    }
    if (this.mediaObserver !== undefined && this.mediaObserver !== null) {
      this.mediaObserver.next();
      this.mediaObserver = null;
      return;
    }
    if (this.mediaComponentObserver !== undefined && this.mediaComponentObserver !== null) {
      this.mediaComponentObserver.next();
      this.mediaComponentObserver = null;
      return;
    }
    if (this.gasDetailComponentObserver !== undefined && this.gasDetailComponentObserver !== null) {
      this.gasDetailComponentObserver.next();
      this.gasDetailComponentObserver = null;
      return;
    }
    if (this.gasComponentObserver !== undefined && this.gasComponentObserver !== null) {
      this.gasComponentObserver.next();
      this.gasComponentObserver = null;
      return 0;
    }
    if (this.customerComponentObserver !== undefined && this.customerComponentObserver !== null) {
      this.customerComponentObserver.next();
      this.customerComponentObserver = null;
      return;
    }

    if (this.observer === undefined || this.observer === null) {
      if (this.viewProcessObserver !== undefined && this.viewProcessObserver !== null) {
        this.viewProcessObserver.next();
        this.viewProcessObserver = null;
        return;
      }

      if (this.zoomInfoObserver !== undefined && this.zoomInfoObserver !== null) {
        this.zoomInfoObserver.next();
        this.zoomInfoObserver = null;
        return;
      }

      this.location.back();
      return;
    }

    // INFO: hide component case
    this.observer.next(false);
    this.navItem -= 1;

    this.updateTitle();

    if (this.navItem === 0) {
      this.observer = null;
    }
    return this.navItem;
  }

  public initObservableComponent(): Observable<boolean> {
    this.navItem += 1;
    return new Observable<boolean>(observer => {
      this.observer = observer;
    });
  }

  public initVideoComponent(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.videoObserver = observer;
    });
  }

  public initZoomInfoObserver(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.zoomInfoObserver = observer;
    });
  }

  public initViewProcessObserver(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.viewProcessObserver = observer;
    });
  }

  public initGasComponent(): Observable<boolean> {
    if (this.customerComponentObserver) {
      this.customerComponentObserver.next();
    }
    this.customerComponentObserver = null;
    if (this.mediaObserver) {
      this.mediaObserver.next();
    }
    this.mediaObserver = null;
    if (this.mediaDetailObserver) {
      this.mediaDetailObserver.next();
    }
    this.mediaDetailObserver = null;
    if (this.mediaComponentObserver) {
      this.mediaComponentObserver.next();
    }
    this.mediaComponentObserver = null;
    return new Observable<boolean>(observer => {
      this.gasComponentObserver = observer;

    });
  }

  public initMediaComponent(): Observable<boolean> {
    if (this.customerComponentObserver) {
      this.customerComponentObserver.next();
    }
    this.customerComponentObserver = null;
    if (this.gasComponentObserver) {
      this.gasComponentObserver.next();
    }
    this.gasComponentObserver = null;
    return new Observable<boolean>(observer => {
      this.mediaComponentObserver = observer;
    });
  }

  public initGasDetailComponent(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.gasDetailComponentObserver = observer;
    });
  }

  public initMediaCategoryObserver(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.mediaObserver = observer;
    });
  }

  public initMediaDetailObservableComponent(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.mediaDetailObserver = observer;
    });
  }

  public initCustomerComponent(): Observable<boolean> {
    if (this.mediaObserver) {
      this.mediaObserver.next();
    }
    this.mediaObserver = null;
    if (this.mediaDetailObserver) {
      this.mediaDetailObserver.next();
    }
    this.mediaDetailObserver = null;
    if (this.mediaComponentObserver) {
      this.mediaComponentObserver.next();
    }
    this.mediaComponentObserver = null;
    if (this.gasComponentObserver) {
      this.gasComponentObserver.next();
    }
    this.gasComponentObserver = null;
    return new Observable<boolean>(observer => {
      this.customerComponentObserver = observer;
    });
  }

  public resetCarouselIndex(): void {
    this.carouselIndex = 0;
  }

  private updateTitle(): void {
    if (this.navItem === 0 && this.router.url === '/beyondGases') {
      this.themeHelper.setPageTitle(this.translate.instant('beyond_gases'));
    }

    if (this.navItem > 0 && this.router.url === '/beyondGases') {
      const currentPath = this.themeHelper.pageTitle;
      const lastItem    = currentPath.split('/').pop();
      const newPath     = currentPath.replace('/' + lastItem, '');

      this.themeHelper.setPageTitle(newPath);
    }
  }
}
