import {Component} from '@angular/core';
import {ComponentRef} from '@angular/core';
import {ElementRef} from '@angular/core';
import {Input} from '@angular/core';
import {ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NavigationHelper} from '../../helpers/navigation.helper';


@Component({
  selector   : 'app-video-fullscreen',
  templateUrl: 'video-fullscreen.component.html',
  styleUrls  : ['video-fullscreen.component.css'],
})

export class VideoFullscreenComponent {
  @ViewChild('video') public videoRef: ElementRef;
  @Input() public path: string;
  @Input() public ownRef: ComponentRef<VideoFullscreenComponent>;

  constructor(public navigationHelper: NavigationHelper,
              private router: Router) {
    this.navigationHelper.initVideoComponent()
      .subscribe(_ => {
        this.ownRef.destroy();
      });

    router.events.subscribe(_ => {
      this.navigationHelper.videoObserver = null;
      this.ownRef.destroy();
    });
  }
}
