import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MimeHelper} from '../helpers/mime.helper';
import {StorageHelper} from '../helpers/storage.helper';

@Pipe({
  name: 'fromStorage',
  pure: false
})
export class FromStoragePipe implements PipeTransform {

  private inProgress: { [key: string]: boolean } = {};
  private cachedData: { [key: string]: any }     = {};

  constructor(private storageHelper: StorageHelper,
              public domSanitizer: DomSanitizer) {
  }

  public updateCache(blob: any, fileKey: string): void {
    this.cachedData[fileKey] = blob ?
      this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob)) :
      './assets/images/not_found.png';
  }

  transform(fileKey: string): string {
    if (!fileKey) {
      return './assets/images/not_found.png';
    }

    fileKey = fileKey.replace('/', '\\');

    if (this.cachedData[fileKey]) {
      return this.cachedData[fileKey];
    }

    if (!this.inProgress[fileKey]) {
      this.inProgress[fileKey] = true;
      this.storageHelper.getBlobDbItem(fileKey, MimeHelper.lookup(fileKey.split('.').pop()))
        .then(data => {
          this.inProgress[fileKey] = false;
          this.updateCache(data, fileKey);
        })
        .catch((error) => {
          throw new Error('FromStorage : transform -> ' + error);
        });
    }
    return './assets/images/placeholder.png';
  }
}
