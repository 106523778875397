<div class="ui-g">
  <div class="ui-g-1 ui-g-nopad">
    <div class="left-menu">
      <button mat-button class="menu-button" (click)="goToHome()">
        <img src="./assets/icons/ic_home.png" alt="home"/>
      </button>
      <button mat-button (click)="goBack()" class="menu-button">
        <img src="./assets/icons/ic_back.png" class="menu-back-img" alt="back"/>
      </button>
      <div class="left-side-items">
        <button mat-button (click)="goToInfography()" [ngClass]="{'menu-button'     : menuState !== 1,
                                                                'menu-button-blue' : menuState === 1}">
          <img src="./assets/icons/process_icon_grey.png" *ngIf="menuState !== 1" alt="infography_grey"/>
          <img src="./assets/icons/process_icon_white.png" *ngIf="menuState === 1" alt="infography_white"/>
        </button>
        <button mat-button (click)="gotToSupplyModes()" [ngClass]="{'menu-button'      : menuState !== 2,
                                                                   'menu-button-blue' : menuState === 2}">
          <img src="./assets/icons/supply_mode_grey.png" *ngIf="menuState !== 2" alt="supplyMode_grey"/>
          <img src="./assets/icons/supply_mode_icon_white.png" *ngIf="menuState === 2" alt="supplyMode_white"/>
        </button>
        <button mat-button (click)="gotToBeyondGases()" [ngClass]="{'menu-button'      : menuState !== 3,
                                                                   'menu-button-blue' : menuState === 3}">
          <img src="./assets/icons/people_icon_grey.png" *ngIf="menuState !== 3" alt="beyondGases_grey"/>
          <img src="./assets/icons/people_icon_white.png" *ngIf="menuState === 3" alt="beyondGases_white"/>
        </button>
        <button mat-button (click)="gotToGasLibrary()" [ngClass]="{'menu-button'      : menuState !== 4,
                                                                   'menu-button-blue' : menuState === 4}">
          <img src="./assets/icons/ic_gas_grey.png" *ngIf="menuState !== 4" alt="gas_grey"/>
          <img src="./assets/icons/ic_gas_white.png" *ngIf="menuState === 4" alt="gas_white"/>
        </button>
      </div>
    </div>
  </div>
  <div class="ui-g-11 ui-g-nopad">
    <div class="left-side-menu" [@zoomMenuAnimation]="isZoomed" *ngIf="sector !== null && isZoomed">
      <mat-nav-list class="process-list">
        <mat-list-item
          *ngFor="let app of applications; let index = index"
          [ngClass]="{'process-item'     : !isSelected(index),
                      'process-sel-item' : isSelected(index)}"
          (click)="onSelectedAppIndexChanged(index)">
          <p>{{ app.name }}</p>
          <img [src]="isSelectedAsset(index)" class="list-icon" [ngClass]="{'pin-selected': isSelected(index)}"
               alt="pin">
        </mat-list-item>
      </mat-nav-list>
      <div class="legend-activate" *ngIf="isDetail !== 1 && isZoomed">
        <mat-slide-toggle
          [(ngModel)]="isLegend"
          labelPosition="before"
          color="primary"
          (change)="activeLegends($event)">
          <h5>{{ 'legend' | translate }}</h5>
        </mat-slide-toggle>
      </div>
    </div>
    <div class="right-side-content">
      <div
        class="segment-infos"
        *ngIf="segment !== null && market !== null">
        <h5>{{ market.name }}</h5>
        <h4>{{ segment.name }}</h4>
        <div class="gradient-underline">
        </div>
      </div>
      <img
        id="infography_image"
        class="infography-image"
        [src]="segment.infographyPath | fromStorage" alt="infography"/>
      <div
        *ngIf="containerH*containerW"
        class="infography-container"
        [ngStyle]="{'height' : containerH + 'px' , 'width' : containerW + 'px' }">
        <div class="sector-area" [ngStyle]="{'height' : containerH + 'px' , 'width' : containerW + 'px' }">

          <div *ngFor="let sector of sectors; let i = index">
            <div
              [@sectorAnimation]="isSector"
              class="sector-bubble"
              [ngStyle]="{'left' : sector.nameAnchor.left + '%',
                        'top'  : sector.nameAnchor.top + '%'
                       }">
              <div class="sector-bubble-text">
                <p>{{ sector.name }}</p>
              </div>
            </div>
            <svg class="points-area" [@sectorAnimation]="isSector"
                 [ngStyle]="{'height' : containerH + 'px' , 'width' : containerW + 'px' }">
              <line
                [attr.x1]="getArrowTop(sector.pointAnchor.left)"
                [attr.y1]="getArrowBottom(sector.pointAnchor.top)"
                [attr.x2]="getTopArrowTop(sector.nameAnchor.left)"
                [attr.y2]="getTopArrowBottom(sector.nameAnchor.top)"
                stroke="black"/>
            </svg>
          </div>
          <svg class="points-area" [ngStyle]="{'height' : containerH + 'px' , 'width' : containerW + 'px' }">
            <g id="{{'area_' + i }}" *ngFor="let sector of segment.sectors; let i = index">
              <polygon
                [attr.points]="getPointsBySector(sector)"
                style="fill:transparent;"/>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div
      class="detail-process"
      [@detailAnimation]="isDetail">
      <div
        class="detail-underline"
        *ngIf="isDetail !== -1">
      </div>
      <button
        mat-fab
        color="primary"
        (click)="extendDetail()"
        *ngIf="isDetail !== -1 && isDetail === 0"
        class="extend-detail">
       <span
         class="fas fa-chevron-down fa-2x extend-detail-icon">
      </span>
      </button>
      <button
        mat-fab
        color="primary"
        (click)="extendDetail()"
        *ngIf="isDetail !== -1 && isDetail === 1"
        class="extend-detail-top">
     <span
       class="fas fa-chevron-up fa-2x extend-detail-icon">
     </span>
      </button>
      <app-infography-detail
        [application]="application">
      </app-infography-detail>
    </div>
    <app-infography-zoom
      [isLegend]="isLegend"
      [sector]="sector"
      [selectedAppIndex]="selectedAppIndex"
      (selectedAppIndexChange)="onSelectedAppIndexChanged($event)"
      *ngIf="sector !== null && isZoomed">
    </app-infography-zoom>
  </div>
  <div
    (click)="navigationHelper.back()"
    class="infography-preview"
    *ngIf="isZoomed && isDetail !== 1">
    <mat-card>
      <img [src]="segment.infographyPath | fromStorage" alt="infography_preview"/>
    </mat-card>
  </div>
</div>
