<div class="component_content">
  <div class="ui-g">
    <div class="ui-g-4">
      <mat-card>
        <h2>{{ 'INSTANCE_SELECTION' | translate}}</h2>
        <mat-card-content>
          <h4>{{ 'SETUP_INSTANCES' | translate}}</h4>

          <div class="countries-list">
            <mat-expansion-panel *ngFor="let country of countries">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ country.Name }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list>
                <mat-list-item
                  *ngFor="let lang of country.Languages"
                  (click)="checkItem(lang)">
                  <mat-checkbox
                    *ngIf="lang.IsOffline"
                    color="primary"
                    [(ngModel)]="lang.IsActive">
                    {{ lang.Name }}
                  </mat-checkbox>
                  <mat-checkbox
                    *ngIf="!lang.IsOffline"
                    [(ngModel)]="lang.IsActive">
                    {{ lang.Name }}
                  </mat-checkbox>
                </mat-list-item>
              </mat-list>
            </mat-expansion-panel>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="ui-g-4">
      <mat-card>
        <h2>{{ 'INSTANCE_ACTIVE' | translate}}</h2>
        <mat-card-content>
          <h4>{{ 'SETUP_ACTIVE' | translate}}</h4>
          <mat-select
            placeholder="{{ 'select_country_language' | translate }}"
            [(ngModel)]="currentLang"
            (selectionChange)="saveSelectedLanguage()">
            <mat-option *ngFor="let language of languages" [value]="language.key">
              {{ language.value }}
            </mat-option>
          </mat-select>
       </mat-card-content>
      </mat-card>
    </div>
    <div class="ui-g-4">
      <mat-card>
        <h2>{{ 'sync' | translate}}</h2>
        <mat-card-content>
          <div class="last_date_label">
            <div *ngIf='syncService.getLastUpdateDate() !== "none" '>
              <p>{{ 'LAST_UPDATE' | translate }} : {{ syncService.getLastUpdateDate() }}</p>
            </div>
            <br/>
            <br/>
            <p>
              {{ 'STORAGE' | translate }} :
              {{ syncService.storeSize }}
            </p>
          </div>
          <div class="sync_container">
            <mat-chip-list *ngIf="syncService.update" class="badge-container">
              <mat-chip class="badge" >
                {{ updateNumber }}
              </mat-chip>
            </mat-chip-list>
            <button class="sync_button"
             mat-raised-button
             (click)="synchronize()"
             color="primary">
              {{ 'SYNCHRONIZE' | translate }}
              <i class="material-icons">autorenew</i>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
