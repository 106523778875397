import {Component} from '@angular/core';
import {Input} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

/*EXTERNAL LIBRARIES*/
import {TranslateService} from '@ngx-translate/core';

/*SERVICES*/
import {AnalyticsService} from '../../shared/services/analytics.service';
import {CustomerService} from '../../shared/services/customer.service';

/*HELPERS*/
import {ThemeHelper} from '../../shared/helpers/theme.helper';

/*MODELS*/
import {Expectation} from '../../shared/models/expectation.model';
import {AnalyticsType} from '../../shared/enums/analytics.enum';

@Component({
  selector   : 'app-expectation',
  templateUrl: 'expectation.component.html',
  styleUrls  : [
    'expectation.component.css'
  ]
})

export class ExpectationComponent {
  @Input() public process: string;
  @Input() public expectations: any;

  public selectedItems: string[] = [];

  constructor(private themeHelper: ThemeHelper,
              private customerService: CustomerService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private analyticsService: AnalyticsService) {
  }

  public addToSelection(expectation: string): void {
    let idx = 0;
    const res       = this.selectedItems.indexOf(expectation) === -1;

    if (res) {
      if (this.selectedItems.length < 3) {
        this.selectedItems.push(expectation);
        return;
      }
      idx                     = this.selectedItems.indexOf(null);
      this.selectedItems[idx] = expectation;
    }
  }

  public removeFromSelection(index: number): void {
    this.selectedItems[index] = null;
  }

  public getItemByIndex(index: number): boolean {
    const res = this.selectedItems[index - 1];

    return !(res === undefined || res === null);
  }

  public addToCart(): void {
    const selCustomer = this.customerService.getSelectedCustomer();

    if (selCustomer === null || selCustomer === undefined) {
      this.snackBar.open(this.translate.instant('PLS_SEL_BEFORE'), 'x',
        {
          duration: 5000
        });
      return;
    }

    const expectation: Expectation = new Expectation();

    expectation.Process      = this.process;
    expectation.Expectations = this.selectedItems.filter(itm => !!itm);

    selCustomer.Expectations = selCustomer.Expectations.filter(e => e.Process !== expectation.Process);

    selCustomer.Expectations.push(expectation);

    this.customerService.setSelectedCustomer(selCustomer);

    if (this.selectionIsValid()) {
      const expString: string = this.process + ' ' +
        this.selectedItems.filter(itm => !!itm).join(' ');

      this.analyticsService.sendEvent(AnalyticsType.ADD_EXPECTATION, expString);
    }

    this.snackBar.open(this.translate.instant('expactations_saved'), 'x',
      {
        duration: 5000
      });
  }

  public selectionIsValid(): boolean {
    return this.selectedItems.some(itm => !!itm);
  }
}
