import {Injectable} from '@angular/core';

import {MediaType} from '../enums/media-type.enum';

@Injectable({
  providedIn: 'root'
})
export class MimeHelper {
  public static lookup(ext: string): string {
    if (!ext) {
      return null;
    }

    switch (ext) {
      case 'pdf'  :
        return 'application/pdf';
      case 'mp4'  :
        return 'video/mp4';
      case 'png'  :
        return 'image/png';
      case 'jpg'  :
        return 'image/jpeg';
      case 'jpeg' :
        return 'image/jpeg';
      default     :
        return null;
    }
  }

  public getIconFromFileName(fileName: string): string {
    if (!fileName) {
      return null;
    }

    switch (fileName.split('.').pop()) {
      case 'mp3'                         :
        return './assets/icons/audio.png';
      case 'mp4'                         :
        return './assets/icons/mov.png';
      case 'jpeg':
      case 'jpg':
      case 'png':
        return './assets/icons/img.png';
      case 'pdf'                         :
        return './assets/icons/pdf.png';
      default                            :
        return null;
    }
  }

  public getMediaType(fileName: string): MediaType {
    if (!fileName) {
      return MediaType.Unknown;
    }

    switch (fileName.split('.').pop()) {
      case 'mp3'                         :
        return MediaType.Audio;
      case 'mp4'                         :
        return MediaType.Video;
      case 'jpeg':
      case 'jpg':
      case 'png':
        return MediaType.Image;
      case 'pdf'                         :
        return MediaType.Pdf;
      default                            :
        return null;
    }
  }
}
