import {Injectable} from '@angular/core';

import JSZip from 'jszip';

import {StorageHelper} from './storage.helper';

@Injectable({
  providedIn: 'root'
})
export class ZipHelper {

  public constructor(private storageHelper: StorageHelper) {
  }

  public loadZipFiles(data: ArrayBuffer): Promise<void> {
    return new JSZip().loadAsync(data)
      .then(zip => this.storeData(zip))
      .catch(console.error);
  }

  private async storeData(zip: JSZip): Promise<void> {
    for (const key of Object.keys(zip.files)) {
      const file = zip.file(key);
      await file.async('blob')
        .then(blob => this.storageHelper.saveDbItem(blob, key));
    }
  }
}
