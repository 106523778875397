import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

/*COMPONENTS*/
import {AppComponent} from '../app.component';

/*ENUMS*/
import {AnalyticsType} from '../shared/enums/analytics.enum';
import {NavigationHelper} from '../shared/helpers/navigation.helper';

/*HELPERS*/
import {ThemeHelper} from '../shared/helpers/theme.helper';

/*SERVICES*/
import {AnalyticsService} from '../shared/services/analytics.service';
import {CatalogService} from '../shared/services/catalog.service';
import {SynchronizeService} from '../shared/services/synchronize.service';

@Component({
  templateUrl: 'welcome.component.html',
  styleUrls  : ['welcome.component.css'],
})

export class WelcomeComponent {
  set filter(s: string) {
    this.filterString = s;
  }

  public documents: any    = null;
  public markets: any      = null;
  public others: any[]     = [];
  public subOffers: any[]  = [];
  public subMarkets: any[] = [];
  public isDetail          = false;
  public medias: any[]     = [];
  public filterString      = '';
  public gas: any[]        = [];
  public supply: any[]     = [];
  public process: any[]    = [];
  public other: any[]      = [];
  public segments: any     = {};

  constructor(private parent: AppComponent,
              private translate: TranslateService,
              private catalogService: CatalogService,
              private navigationHelper: NavigationHelper,
              private syncService: SynchronizeService,
              private analyticsService: AnalyticsService,
              private router: Router,
              private themeHelper: ThemeHelper) {
    this.parent.hideDetail();

    this.parent.activeMenu();

    this.themeHelper.setPageTitle(this.translate.instant('welcome'));

    this.analyticsService.sendEvent(AnalyticsType.OPEN_VIEW, this.router.url);

    this.parent.setDataSource(this);

    this.initData();

    this.syncService.isNotified = true;
  }

  public openMediaDetail(type: number): void {
    const title = this.translate.instant('offers_selling_points');

    switch (type) {
      case 0 :
        this.medias = this.gas;
        break;
      case 1 :
        this.medias = this.process;
        break;
      case 2 :
        this.medias = this.supply;
        break;
    }

    if (this.medias && this.medias[0] && this.medias[0].title) {
      this.analyticsService.sendEvent(AnalyticsType.OPEN_WELCOME, title + ' ' + this.medias[0].title);
    }

    this.openDetail();
  }

  public openMarketMediaDetail(name: string): void {
    const title = this.translate.instant('markets_sales_arguments');

    this.analyticsService.sendEvent(AnalyticsType.OPEN_WELCOME, title + ' ' + name);

    this.medias = this.segments[name];
    this.openDetail();
  }

  public openOtherMediaDetail(index: number): void {
    const title = this.translate.instant('OTHERS');

    this.medias = this.other[index];

    if (this.medias && this.medias[0] && this.medias[0].title) {
      this.analyticsService.sendEvent(AnalyticsType.OPEN_WELCOME, title + ' ' + this.medias[0].title);
    }

    this.openDetail();
  }

  private initData(): void {
    this.catalogService.fetchCurrentData()
      .then((data: any) => {
        if (data.welcomeDocument === undefined) {
          throw new Error('WelcomeComponent : initData -> No Welcome documents');
        }

        this.documents = data.welcomeDocument;

        if (data.markets === undefined) {
          throw new Error('WelcomeComponent : initData -> No Welcome markets');
        }

        this.markets = data.markets;

        for (const other of Object.keys(this.documents.welcomeOthers)) {
          this.others.push({Name: other, docs: this.documents.welcomeOthers[other]});
        }
        this.initMarkets();

        this.initMedias(this.subOffers[0].Name, this.documents.welcomePremiumGas, this.gas);
        this.initMedias(this.subOffers[1].Name, this.documents.welcomeProcessSolution, this.process);
        this.initMedias(this.subOffers[2].Name, this.documents.welcomeSupplyModes, this.supply);
        this.other = new Array(this.others.length);
        for (let i = 0; i < this.other.length; i++) {
          this.other[i] = [];
        }
        this.others.forEach((other, index) => {
          this.initMedias(other.Name, other.docs, this.other[index]);
        });

        Object.keys(this.markets)
          .forEach((item) => {
            const market = this.markets[item];

            this.segments[market.name] = [];

            Object.keys(market.segments)
              .forEach(tmp => {
                const temp = market.segments[tmp];

                this.initMedias(temp.name, temp.welcomeDocuments, this.segments[market.name]);
              });
          });

        this.subMarkets = this.subMarkets.filter(itm => {
          const count = this.segments[itm.Name].reduce((prev, curr) => prev + curr.medias.length, 0);
          return count > 0;
        });

      })
      .catch((err) => {
        throw new Error(err);
      });

    this.initOffers();
  }

  private openDetail(): void {
    this.isDetail = true;
    this.navigationHelper.initObservableComponent()
      .subscribe(res => {
        this.isDetail = res;
      });
  }

  private initOffers(): void {
    this.translate.get('premium_gas')
      .subscribe((res: any) => {
        this.subOffers.push({Name: res});
      });

    this.translate.get('process_solution')
      .subscribe((res: any) => {
        this.subOffers.push({Name: res});
      });

    this.translate.get('supply_modes')
      .subscribe((res: any) => {
        this.subOffers.push({Name: res});
      });
  }

  private initMarkets(): void {
    if (!this.markets) {
      return;
    }

    Object.keys(this.markets)
      .forEach((item) => {
        const marketName = this.markets[item].name;

        this.subMarkets.push({Name: marketName});
      });
  }

  private initMedias(title: any, array: any, out: any): void {
    if (!array) {
      return;
    }

    const paths: any[] = [];

    array.forEach((item) => {
      paths.push({name: item.displayName, path: item.path});
    });

    out.push({title, medias: paths});
  }
}
