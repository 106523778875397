export class AnalyticsType {
  public static readonly OPEN_VIEW                     = 'OpenView';
  public static readonly GO_TO                         = 'GoTo';
  public static readonly OPEN_DOCUMENT                 = 'OpenDocument';
  public static readonly OPEN_INFOGRAPHY               = 'OpenInfography';
  public static readonly OPEN_SEGMENT                  = 'OpenSegment';
  public static readonly OPEN_SUPPLY_MODE              = 'OpenSupplyMode';
  public static readonly OPEN_PROCESS                  = 'OpenProcess';
  public static readonly OPEN_OFFER                    = 'OpenOffer';
  public static readonly OPEN_PRODUCT                  = 'OpenProduct';
  public static readonly OPEN_BEYOND_GASES             = 'OpenBeyondGases';
  public static readonly DOWNLOAD_FILE                 = 'DownloadFile';
  public static readonly SEND_MAIL                     = 'SendMail';
  public static readonly ADD_TO_CART                   = 'AddToCart';
  public static readonly ADD_EXPECTATION               = 'AddExpectation';
  public static readonly INSTANCE_SELECT               = 'InstanceSelect';
  public static readonly ADD_CUSTOMER                  = 'AddCustomer';
  public static readonly OPEN_WELCOME                  = 'OpenWelcome';
  public static readonly OPEN_ABOUT_AL                 = 'OpenAboutAL';
  public static readonly OPEN_FOCUS                    = 'OpenFocus';
  public static readonly OPEN_SUPPLY_MODE_FROM_PRODUCT = 'OpenSupplyModeFromProduct';
  public static readonly OPEN_PROCESS_FROM_HOME        = 'OpenProcessFromHome';
  public static readonly OPEN_DOCUMENT_FROM_MEDIA      = 'OpenDocumentFromMedia';
  public static readonly OPEN_DOCUMENT_FROM_WELCOME    = 'OpenDocumentFromWelcome';
  public static readonly OPEN_MARKET                   = 'OpenMarket';
  public static readonly OPEN_BEYOND_GASES_FROM_HOME   = 'OpenBeyondGasesFromHome';
}
