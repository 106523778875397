import {ArrayTyper} from '@caliatys/array-typer';
import {TileType} from '../enums/tiletype.enum';

export class Tile {
  order: number;
  type: TileType;
  tileText: any;
  tileMedia: string;
  tileService: any;
  tileOffer: any;
  tileApplication: any;
  tileBrand: any;
  tileBeyond: any;
  tileProduct: any;
  tileVideo: any;

  constructor(json: any) {
    if (!json) {
      return null;
    }
    this.order = json.Order;
    this.type  = json.Type;

    switch (this.type) {
      case TileType.Web:
      case TileType.Text:
        this.tileText = {
          text: json.Text
        };
        break;
      case TileType.TextBackground:
        this.tileText = {
          text     : json.Text,
          textColor: '#FFFFFF',
          path     : json.DocumentPath
        };
        break;
      case TileType.Picture:
        this.tileMedia = json.DocumentPath;
        break;
      case TileType.Video:
        this.tileVideo = {
          videoPath    : json.DocumentPath,
          thumbnailPath: json.ThumbnailPath
        };
        break;
      case TileType.Service:
        this.tileService = {
          text: json.Text,
          id  : json.ServiceId
        };
        break;
      case TileType.Offer:
        this.tileOffer = {
          id  : json.SupplyModeId,
          text: json.Text,
          path: json.DocumentPath
        };
        break;
      case TileType.Product:
        this.tileProduct = {
          id         : json.ProductId,
          text       : json.Text,
          supplyModes: json.Focuses ?
            json.Focuses.map(s => s.Id) : // ArrayTyper.asArray(SupplyMode, json.Focuses)),
            null,
          proposals  : json.Proposals
        };
        break;
      case TileType.Brand:
        const tilesBrand = ArrayTyper.asDict(Tile, json.Tiles || [], tile => tile.order);
        this.tileBrand   = {
          text : json.Text,
          tiles: Object.keys(tilesBrand).sort().map(key => tilesBrand[key]),
          path : json.DocumentPath
        };
        break;
      case TileType.Application:
        this.tileApplication = {
          text: json.Text,
          id  : json.ApplicationId
        };
        break;
      case TileType.Beyond:
        const tilesBeyond = ArrayTyper.asDict(Tile, json.Tiles || [], tile => tile.order);
        this.tileBeyond   = {
          text          : json.Text,
          textColor     : '#FFFFFF',
          backgroundPath: json.DocumentPath,
          tiles         : Object.keys(tilesBeyond).sort().map(key => tilesBeyond[key])
        };
        break;
    }
  }
}
