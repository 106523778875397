import {ElementRef, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnimationHelper {
  public scrollToTop(applicationView: ElementRef): void {
    if (!applicationView || !applicationView.nativeElement || !applicationView.nativeElement.parentElement) {
      return;
    }
    const el         = applicationView.nativeElement;
    const parent     = applicationView.nativeElement.parentElement;
    parent.scrollTop = el.offsetTop - parent.scrollTop;
  }
}
