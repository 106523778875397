import {ArrayTyper} from '@caliatys/array-typer';
import {ALDocument} from './document.model';
import {Point} from './point.model';
import {Tile} from './tile.model';

export class Offer {
  id: number;
  imagePath: string;
  globalImagePath: string;
  anchors: Point[];
  defaultDetail: OfferDetail;
  details: OfferDetail[];

  constructor(json: any) {
    if (!json) {
      return null;
    }
    this.id              = json.Id;
    this.imagePath       = json.ImagePath;
    this.globalImagePath = json.GlobalImagePath;
    this.anchors         = ArrayTyper.asArray(Point, json.Anchors || []);
    this.defaultDetail   = new OfferDetail(json.DefaultDetail);
    this.details         = ArrayTyper.asArray(OfferDetail, json.Details || []);
  }
}

export class OfferDetail {
  title: string;
  descriptions: string[];
  products: any[];
  documents: ALDocument[][];

  private _slides: any[];

  get slides(): any[] {
    if (this._slides === undefined) {
      this._slides = [];

      for (let index = 0; index < this.descriptions.length; index++) {
        this._slides.push({
          description: this.descriptions[index],
          documents  : ArrayTyper.asArray(ALDocument, this.documents[index] || [])
        });
      }
    }
    return this._slides;
  }

  constructor(json: any) {
    if (!json) {
      return null;
    }
    this.title        = json.Title;
    this.descriptions = json.Descriptions;
    this.documents    = json.Documents;
    this.products     = [];
    if (json.Products && Array.isArray(json.Products)) {
      json.Products.forEach(product => {
        this.products.push({
          html       :
`<div style="margin-left:10%;background-color:#ffffff;border-radius: 50px 50px 50px 50px;width:80%;">
  <div style="padding:10px;text-align:center;border-radius: 50px 50px 50px 50px;border: 2px solid #000000;font-size: 20px;color: #1e5799;">
    ${product.OfferDetailText}
  </div>
</div>`,
          id         : product.ProductId,
          supplyModes: ArrayTyper.asArray(SupplyMode, product.Focuses || []).map((supplyMode => supplyMode.id))
        });
      });
    }
  }
}

export class SupplyMode {
  id: number;
  title: string;
  logoPath: string;
  image: string;
  offer: Offer;
  range: { [key: number]: Tile };

  constructor(json: any) {
    if (!json) {
      return null;
    }
    this.id       = json.Id;
    this.title    = json.Title;
    this.logoPath = json.LogoPath;
    this.image    = json.focus;
    this.offer    = new Offer(json.Offer);
    this.range    = ArrayTyper.asDict(Tile, json.range || [], tile => tile.order);
  }
}
