<h1 mat-dialog-title>{{ 'SIGNATURE' | translate }}</h1>
<mat-dialog-content class="popup-content">
  <div class="ui-g">
    <div class="ui-g-12">
      <quill-editor [(ngModel)]="htmlSignature" [modules]="quillConfig" [style]="{'min-height':'8vw'}">
      </quill-editor>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="dialog_actions">
    <button
      mat-raised-button
      (click)="saveSignature()"
      class="dialog_right_button"
      color="primary">
      {{ 'SAVE' | translate }}
    </button>
  </div>
</mat-dialog-actions>
