<div class="customers_container">
  <mat-radio-group [(ngModel)]="selCustomer.Id" name="type">
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container cdkColumnDef="SetClient">
        <mat-header-cell *cdkHeaderCellDef>
          {{ 'set_as_client' | translate }}
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <mat-radio-button
            color="primary"
            (change)="onSelCustomer(row)"
            [value]="row.Id">
          </mat-radio-button>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Name">
        <mat-header-cell *cdkHeaderCellDef>
          {{ 'client_name' | translate }}
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="handleRowClick(row)">
          <div>
            <p [ngClass]="{'customer_name': selCustomer.Id === row.Id}">
              {{row.Name}}
            </p>
            <p
              class="current_customer"
              *ngIf="selCustomer.Id === row.Id">
              {{ 'current_customer_folder_open' | translate }}
            </p>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="CompanyName">
        <mat-header-cell *cdkHeaderCellDef>
          {{ 'company_name' | translate }}
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="handleRowClick(row)">
          <b>{{row.CompanyName}} </b>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="DateOfCreation">
        <mat-header-cell *cdkHeaderCellDef>
          {{ 'date_of_creation' | translate }}
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="handleRowClick(row)">
          {{ getCreatedDate(row) }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Actions">
        <mat-header-cell *cdkHeaderCellDef>
          <div class="actions_right" *ngIf="!isReadOnly">
            <button
              mat-icon-button
              *ngIf="ids.length === 1"
              (click)="shareFile()">
              <i class="far fa-envelope fa-2x"></i>
            </button>
            <button
              mat-icon-button
              (click)="openRemoveCustomer()">
              <i class="far fa-trash-alt fa-2x"></i>
            </button>
            <mat-checkbox
              (change)="selectAllItemsChange($event)"
              [(ngModel)]="indeterminate"
              color="primary"
              [indeterminate]="indeterminate">
            </mat-checkbox>
          </div>
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <div class="actions_right" *ngIf="!isReadOnly">
            <button
              mat-icon-button
              class="edit_button"
              (click)="openEditCustomer(row)">
              <mat-icon>edit</mat-icon>
            </button>
            <mat-checkbox
              [checked]="ids.indexOf(row.Id) !== -1"
              class="bulk_checkbox"
              color="primary"
              (change)="checkUserChange($event, row)">
            </mat-checkbox>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="displayedColumns">
      </mat-header-row>
      <mat-row>
        <div class="ui-g-12">
          <mat-progress-bar mode="indeterminate" *ngIf="sending">
          </mat-progress-bar>
        </div>
      </mat-row>
      <mat-row
        *cdkRowDef="let row; columns: displayedColumns;"
        class="selected_row"
        [ngClass]="{
                    'selected_row': selCustomer.Id === row.Id,
                    'not_selected_row': selCustomer.Id !== row.Id
                   }">
      </mat-row>
    </mat-table>
  </mat-radio-group>
  <app-customer-detail *ngIf="showDetail" [customer]="customer">
  </app-customer-detail>
  <button
    *ngIf="!isReadOnly && !showDetail"
    mat-raised-button
    type="button"
    class="mail_list"
    (click)="openMailCustomerList()"
    color="primary">
    {{ 'send_list' | translate }}
    <img src="./assets/icons/people_icon_white.png" alt="mail_customer_list"/>
  </button>
  <button
    *ngIf="!isReadOnly && !showDetail"
    mat-raised-button
    type="button"
    class="edit_customer"
    (click)="openEditCustomer(null)"
    color="primary">
    {{ 'add_a_customer' | translate }}
    <img src="./assets/icons/ic_add_customer.png" alt="add_customer"/>
  </button>
</div>
