<div class="component_content">
  <div class="title">
    <h1>{{about.title}}</h1>
  </div>
    <div *ngIf="about.videoPath"
      class="content-video">
      <img [src]="about.thumbnailPath | fromStorage" alt="thumbnail">
      <button
        *ngIf="about.videoPath"
        (click)="openMedia(about.videoPath)"
        class="play"
        mat-mini-fab>
          <mat-icon>play_arrow</mat-icon>
      </button>
    </div>
    <div id="aboutContent"
      *ngIf="!about.videoPath"
      class="content-text"
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(about.text)"></div>
  <mat-card class="docs-container" *ngIf="about.documents.length > 0">
    <img
      *ngIf="about.documents && !selectDocs"
      class="select_elements"
      (click)="activeSelection()"
      src="./assets/icons/add_to_cart.png"  alt="addToCart"/>
    <img
      *ngIf="selectDocs"
      class="select_elements"
      (click)="addToCart()"
      src="./assets/icons/ic_add_customer.png"  alt="add_customer"/>
    <div class="docs-container-line" *ngFor="let doc of about.documents">
      <img [src]="mimeHelper.getIconFromFileName(doc.path)" alt="fileType"/>
      <a (click)="!selectDocs && mediaHelper.openMedia(doc.path)">
          <h6>{{doc.displayName}}</h6>
      </a>
      <mat-checkbox
        *ngIf="selectDocs"
        [checked]="isSelected(doc)"
        (change)="selectItem($event, doc)"
        color="primary">
      </mat-checkbox>
    </div>
  </mat-card>
</div>
