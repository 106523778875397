<h1 mat-dialog-title>{{ 'DOWNLOAD_ASSETS' | translate }}</h1>
<mat-dialog-content>
  <div *ngIf="!cancelMode">
    <div *ngIf="!error && !netError && !storage && !upToDate && download">
      <div>
        <p>{{currentZipNumber}} / {{totalZipNumber}} : {{ downloadProgress }} % </p>
        <mat-progress-bar
            color="primary"
            mode="determinate"
            [value]="downloadProgress">
        </mat-progress-bar>
      </div>
    </div>
    <div *ngIf="netError">
      <h3 class="error-message"> {{ 'no_network_connection_toast' | translate }}</h3>
    </div>
    <div *ngIf="error">
      <h3 class="error-message"> {{ 'PROBLEM' | translate }}</h3>
    </div>
    <div class="success_container" *ngIf="upToDate && !error && !storage && !netError">
      <img src="./assets/images/sync-ok.png" alt="sync_ok">
      <p> {{ 'DATA_UPTODATE' | translate }}</p>
    </div>
    <div class="success_container" *ngIf="!upToDate && !download && !error && !storage && !netError">
      <div class="loader_container">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <h3>{{ 'please_wait' | translate }}</h3>
      </div>
    </div>
    <div *ngIf="storage">
      <h3 class="error-message"> {{ 'STORAGE_ERROR' | translate }}</h3>
    </div>
  </div>
  <div *ngIf="cancelMode">
    <p>{{ 'DO_CANCEL' | translate }}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div *ngIf="!cancelMode" class="dialog_actions">
    <button
        class="cancel_button"
        mat-raised-button
        type="button"
        color="primary"
        *ngIf="download"
        (click)="askCancel()">
      {{ 'cancel' | translate }}
    </button>
    <button
        class="cancel_button"
        mat-raised-button
        type="button"
        color="primary"
        *ngIf="!download && (upToDate || error || storage || netError)"
        (click)="close()">
      {{ 'ok' | translate }}
    </button>
  </div>
  <div *ngIf="cancelMode" class="dialog_actions">
    <button
        mat-raised-button
        class="dialog_right_button"
        type="button"
        (click)="askCancel()"
        color="primary">
      {{ 'NO' | translate }}
    </button>
    <button
        mat-raised-button
        type="button"
        (click)="cancel()"
        color="primary">
      {{ 'yes' | translate }}
    </button>
  </div>
</mat-dialog-actions>
