import {ArrayTyper} from '@caliatys/array-typer';
import {Offer} from './offer.model';
import {Tile} from './tile.model';

export class SupplyMode {
  id: number;
  title: string;
  logoPath: string;
  image: string;
  offer: Offer;
  range: { [key: number]: Tile };

  constructor(json: any) {
    if (!json) {
      return null;
    }
    this.id       = json.Id;
    this.title    = json.Title;
    this.logoPath = json.LogoPath;
    this.image    = json.focus;
    this.offer    = new Offer(json.Offer);
    this.range    = ArrayTyper.asDict(Tile, json.range || [], tile => tile.order);
  }
}
