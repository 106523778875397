import {DataSource} from '@angular/cdk/collections';
import {DatePipe} from '@angular/common';
import {Component, ComponentRef, Inject, Input, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatRadioGroup} from '@angular/material/radio';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, merge, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AnalyticsType} from '../shared/enums/analytics.enum';
import {NavigationHelper} from '../shared/helpers/navigation.helper';

/*HELPERS*/
import {ThemeHelper} from '../shared/helpers/theme.helper';

/*MODELS*/
import {Customer} from '../shared/models/customer.model';

import {Expectation} from '../shared/models/expectation.model';
import {GContact} from '../shared/models/gcontact.model';
import {Media} from '../shared/models/media.model';
import {AnalyticsService} from '../shared/services/analytics.service';
import {AuthService} from '../shared/services/auth.service';
import {CatalogService} from '../shared/services/catalog.service';

/*SERVICES*/
import {CustomerService} from '../shared/services/customer.service';
import {MailService} from '../shared/services/mail.service';

/*COMPONENTS*/
import {AddCustomerComponent} from './add-customer/add-customer.component';
import {RemoveCustomerComponent} from './remove-customer/remove-customer.component';

@Component({
  templateUrl: 'customer.component.html',
  styleUrls  : ['customer.component.css'],
})

export class CustomerComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatRadioGroup) group: MatRadioGroup;

  @Input() public ownRef: ComponentRef<CustomerComponent>;
  @Input() public appComponent: any;
  @Input() public parentPageTitle: string;

  /*Data table items*/
  displayedColumns                               = ['SetClient', 'Name', 'CompanyName', 'DateOfCreation', 'Actions'];
  public customers$: BehaviorSubject<Customer[]> = new BehaviorSubject<Customer[]>([]);
  public dataSource: CustomerDataSource | null;
  public showDetail                              = false;
  public customer: Customer                      = new Customer();
  public isReadOnly                              = false;
  public selCustomer: Customer                   = new Customer();
  public indeterminate                           = true;
  public ids: string[]                           = [];
  public loadingRef: any                         = null;
  public sending                                 = false;
  private readonly routerSub: Subscription       = null;

  get customers(): Customer[] {
    return this.customers$.value;
  }

  set customers(customers: Customer[]) {
    this.customers$.next(customers);
  }

  public constructor(private dialog: MatDialog,
                     private snackBar: MatSnackBar,
                     private translate: TranslateService,
                     private router: Router,
                     private themeHelper: ThemeHelper,
                     private analyticsService: AnalyticsService,
                     private navigationHelper: NavigationHelper,
                     private customerService: CustomerService,
                     private mailService: MailService,
                     private authService: AuthService,
                     private catalogService: CatalogService,
                     @Inject(LOCALE_ID) private locale: string) {
    this.navigationHelper.initCustomerComponent()
      .subscribe(_ => {
        this.appComponent.customerShown = false;
        this.themeHelper.setPageTitle(this.parentPageTitle);
        if (this.routerSub) {
          this.routerSub.unsubscribe();
        }
        this.ownRef.destroy();
      });

    this.routerSub = router.events.subscribe(_ => {
      this.appComponent.customerShown = false;
      this.themeHelper.setPageTitle(this.parentPageTitle);
      this.navigationHelper.customerComponentObserver = null;
      this.routerSub.unsubscribe();
      this.ownRef.destroy();
    });

    this.analyticsService.sendEvent(AnalyticsType.OPEN_VIEW, '/customer');

    this.selCustomer = this.customerService.getSelectedCustomer();

    if (this.selCustomer === null || this.selCustomer === undefined) {
      this.selCustomer = new Customer();
    } else {
      this.customer = this.selCustomer;
    }
  }

  public ngOnInit(): void {
    // NOTE : Local customers founded case
    this.customerService.getCustomers()
      .then(customers => {

        this.initDefaultCustomer(this.selCustomer.Id, customers);

        this.customers = customers;

        this.dataSource = new CustomerDataSource(this, this.sort);

        this.appComponent.setDataSource(this.dataSource);

        if (this.isReadOnly) {
          this.updateDetail();
        }

        this.themeHelper.setPageTitle(this.translate.instant('customers') + '  ' + this.customers.length);
      })
      // NOTE : No local customers founded case
      .catch(_ => {
        this.customers$.next([]);

        this.dataSource = new CustomerDataSource(this, this.sort);

        this.appComponent.setDataSource(this.dataSource);

        this.themeHelper.setPageTitle(this.translate.instant('customers') + '  ' + this.customers.length);
      });
  }

  public updateDetail(): void {
    if (this.selCustomer === null || this.selCustomer === undefined) {
      this.snackBar.open(this.translate.instant('no_client_has_been_set'), 'x',
        {
          duration: 5000
        });
      return;
    }
    this.handleRowClick(this.selCustomer);

    setTimeout(() => {
      this.themeHelper.setPageTitle(this.selCustomer.Name);
    }, 100);
  }

  public handleRowClick(row: any): void {
    if (this.isReadOnly) {
      return;
    }

    this.showDetail = !this.showDetail;
    this.customer   = row;

    if (this.showDetail) {
      this.dataSource.filter = row.Name + '|' + row.CompanyName;
    } else {
      this.dataSource.filter = '';
      this.customerService.setCustomers(this.customers);
    }
  }

  public onSelCustomer(row: any): void {
    row.IsDefault = true;
    this.customerService.setSelectedCustomer(row);
    this.updateDefaultCustomer(row.Id);
  }

  public checkUserChange(event: any, row: any): void {
    this.indeterminate = true;

    if (event.checked) {
      this.ids.push(row.Id);
      return;
    }

    this.ids.splice(this.ids.indexOf(row.Id), 1);
  }

  public selectAllItemsChange(event: any): void {
    this.indeterminate = false;
    this.customers
      .forEach((item) => {
        if (event.checked) {
          this.ids.push(item.Id);
          return;
        }
        this.ids.splice(this.ids.indexOf(item.Id), 1);
      });
  }

  public openEditCustomer(row: any): void {
    const dialogRef = this.dialog.open(AddCustomerComponent,
      {
        data: JSON.parse(JSON.stringify(row))
      });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result === '') // Cancel task
        {
          return;
        }

        if (row !== null) // edit mode
        {
          this.updateCustomer(result);
          if (result.Id === this.customerService.getSelectedCustomer().Id) {
            this.customerService.setSelectedCustomer(result);
          }
          this.customerService.setCustomers(this.customers);
          return;
        }

        this.addNewCustomer(result);
        this.customerService.setCustomers(this.customers);
        this.themeHelper.setPageTitle(this.translate.instant('customers') + '  ' + this.customers.length);
      });
  }

  public openRemoveCustomer(): void {
    if (this.ids.length === 0) {
      this.translate.get('SELECT_BEFORE_REMOVE')
        .subscribe((res: string) => {
          this.snackBar.open(res, 'x',
            {
              duration: 5000
            });
        });
      return;
    }
    const dialogRef = this.dialog.open(RemoveCustomerComponent,
      {
        data: this.ids
      });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result === '') // NOTE : Cancel case
        {
          return;
        }

        let customers = this.customers;

        result.forEach(item => {
          const findCustomer: Customer = customers.find(c => c.Id === item);

          if (findCustomer !== undefined && findCustomer !== null) {
            if (findCustomer.Id === this.selCustomer.Id) {
              this.customerService.removeSelectedCustomer();
            }
          }

          customers = customers.filter(c => c.Id !== item);

          const idx = this.ids.indexOf(item);

          if (idx !== -1) {
            this.ids.splice(idx, 1);
          }
        });
        this.customers = customers;
        this.customerService.setCustomers(this.customers);
        this.themeHelper.setPageTitle(this.translate.instant('customers') + '  ' + this.customers.length);
      });
  }

  public getCreatedDate(customer: Customer): string {
    return new Date(customer.DateOfCreation).toDateString();
  }

  public shareFile(): void {
    this.catalogService.fetchCurrentData()
      .then(catalog => {
        const customers: any[] = [];

        this.ids.forEach(item => {
          customers.push(this.customers.find(d => d.Id === item));
        });

        let htmlSignature = this.mailService.getSignature();

        if (htmlSignature === null) {
          htmlSignature = '';
        }

        const subject = this.translate.instant('mail_subject');
        const start   = this.translate.instant('mail_start');
        const header  = this.translate.instant('mail_header');
        const footer  = this.translate.instant('mail_footer');

        const po    = '<p>';
        const pc    = '</p>';
        const bo    = '<b>';
        const bc    = '</b>';
        const ao    = '<a href="';
        const ac    = '</a>';
        const br    = '<br/>';
        let htmlExp = '';

        htmlExp += start + br + br;

        htmlExp += header + br + br;

        for (const customer of customers) {

          const medias: Media[]             = [...customer.Medias];
          const expectations: Expectation[] = [...customer.Expectations];

          for (const expectation of expectations) {

            htmlExp +=
              bo + expectation.Process + bc +
              po + expectation.Expectations.join(pc + po) + pc + br;
          }

          for (const media of medias) {

            htmlExp += ao + environment.baseUrl + media.Url + '">' + media.Name + ac + br;
          }
          if (catalog.aboutAl?.serverUrl) {
            htmlExp += ao + environment.baseUrl + catalog.aboutAl.serverUrl + '">' + this.translate.instant('about_al') + ac + br;
          }
        }

        htmlExp += br + footer + br + br;

        htmlSignature = htmlExp + htmlSignature + '<div id="myImage"></div>';

        this.sending  = true;
        const contact = new GContact();
        contact.Name  = '';
        contact.Email = this.authService.connectedMail;

        this.mailService.sendMail(subject, htmlSignature, [contact])
          .subscribe(_ => {
              this.snackBar.open(this.translate.instant('EMAIL_SENDED'), 'x',
                {
                  duration: 5000
                });
              this.analyticsService.sendEvent(AnalyticsType.SEND_MAIL, subject);
              this.sending = false;
            },
            (err) => {
              this.snackBar.open(this.translate.instant('EMAIL_ERROR'), 'x',
                {
                  duration: 5000
                });

              this.sending = false;
              throw new Error('EmailCustomerComponent : save -> ' + err);
            });
      });
  }

  public openMailCustomerList(): void {
    let htmlSignature = this.mailService.getSignature();

    if (htmlSignature === null) {
      htmlSignature = '';
    }

    const subject          = this.translate.instant('mail_client_list_subject');
    const start            = this.translate.instant('mail_client_list_start');
    const header           = this.translate.instant('mail_client_list_header');
    const footer           = this.translate.instant('mail_client_list_footer');
    const clientNameHead   = this.translate.instant('client_name');
    const companyNameHead  = this.translate.instant('company_name');
    const dateCreationHead = this.translate.instant('date_of_creation');

    const br    = '<br/>';
    let htmlExp = '';

    htmlExp += start + br + br;

    htmlExp += header + br + br;

    htmlExp += `
<table>
  <thead>
    <tr>
      <th>${clientNameHead}</th>
      <th>${companyNameHead}</th>
      <th>${dateCreationHead}</th>
    </tr>
  </thead>
  <tbody>`;

    for (const customer of this.customers) {
      htmlExp +=
        `<tr>
<td>${customer.Name}</td>
<td>${customer.CompanyName}</td>
<td>${new DatePipe(this.locale).transform(customer.DateOfCreation)}</td>
</tr>`;
    }

    htmlExp += '</tbody></table>';


    htmlExp += br + footer + br + br;

    htmlSignature = htmlExp + htmlSignature + '<div id="myImage"></div>';

    this.sending  = true;
    const contact = new GContact();
    contact.Name  = '';
    contact.Email = this.authService.connectedMail;

    this.mailService.sendMail(subject, htmlSignature, [contact])
      .subscribe(_ => {
          this.snackBar.open(this.translate.instant('EMAIL_SENDED'), 'x',
            {
              duration: 5000
            });
          this.analyticsService.sendEvent(AnalyticsType.SEND_MAIL, subject);
          this.sending = false;
        },
        (err) => {
          this.snackBar.open(this.translate.instant('EMAIL_ERROR'), 'x',
            {
              duration: 5000
            });

          this.sending = false;
          throw new Error('EmailCustomerComponent : save -> ' + err);
        });
  }

  private updateCustomer(customer: Customer): void {
    const sameUser = this.customers.filter(item => item.Id !== customer.Id &&
      item.CompanyName === customer.CompanyName &&
      item.Name === customer.Name);

    if (sameUser !== undefined && sameUser.length !== 0) // INFO: same name & company
    {
      this.snackBar.open(this.translate.instant('EXIST_CUSTOMER'), 'x',
        {
          duration: 5000
        });
      return;
    }

    if (customer === undefined || customer === null) {
      throw new Error('CustomerComponent : updateCustomer -> updated customer is null');
    }

    this.customers = this.customers.filter(c => c.Id !== customer.Id);
    this.customers = [...this.customers, customer];

    this.snackBar.open(this.translate.instant('customer_updated'), 'x',
      {
        duration: 5000
      });
  }

  private addNewCustomer(customer: Customer): void {
    const foundedCustomer = this.customers.find(c => c.Name === customer.Name && c.CompanyName === customer.CompanyName);

    if (foundedCustomer !== undefined) // same name & company
    {
      this.snackBar.open(this.translate.instant('EXIST_CUSTOMER'), 'x',
        {
          duration: 5000
        });
      return;
    }

    const exist = this.customerService.getSelectedCustomer();

    if (exist === null || exist === undefined) {
      this.selCustomer   = customer;
      customer.IsDefault = true;
      this.customerService.setSelectedCustomer(customer);
      this.updateDefaultCustomer(customer.Id);
      this.group.value = customer.Id;
    }

    this.analyticsService.sendEvent(AnalyticsType.ADD_CUSTOMER, customer.Name);

    this.customers = [...this.customers, customer];
    this.snackBar.open(this.translate.instant('customer_added'), 'x',
      {
        duration: 5000
      });
  }

  private initDefaultCustomer(id: string, customers: Customer[]): void {
    customers.forEach(
      customer => {
        customer.IsDefault = customer.Id === id;
      });

    if (customers.length === 0) {
      return;
    }

    const foundedCustomer = customers.find(c => c.IsDefault);

    if (foundedCustomer === undefined || foundedCustomer === null) {
      return;
    }

    this.selCustomer = foundedCustomer;
  }

  private updateDefaultCustomer(id: string): void {
    this.customers.forEach(
      customer => {
        customer.IsDefault = customer.Id === id;
      });

    if (this.customers.length === 0) {
      return;
    }

    const foundedCustomer = this.customers.find(c => c.IsDefault);

    if (foundedCustomer === undefined || foundedCustomer === null) {
      return;
    }

    this.selCustomer = foundedCustomer;
  }
}

export class CustomerDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  constructor(private _db: CustomerComponent, private _sort: MatSort) {
    super();
  }

  connect(): Observable<Customer[]> {
    const displayDataChanges =
            [
              this._db.customers$,
              this._filterChange,
              this._sort.sortChange // FIXME: is undefined on last angular io version
            ];

    return merge(...displayDataChanges)
      .pipe(
        map(() => this._db.customers.slice()
          .filter((item: Customer) => {
            const searchedValue: string = (this.filter).toLowerCase();
            const searchStr: string     = (item.Name).toLowerCase();

            if (this.filter.indexOf('|') !== -1) {
              const searchStr2 = (item.CompanyName).toLowerCase();
              const first      = this.filter.split('|')[0].toLowerCase();
              const secParam   = this.filter.split('|')[1].toLowerCase();

              return searchStr.indexOf(first) !== -1 &&
                searchStr2.indexOf(secParam) !== -1;
            }

            return searchStr.indexOf(searchedValue) !== -1;
          })
          .sort((a, b) => {
            if (a.IsDefault) {
              return -1;
            }

            if (b.IsDefault) {
              return 1;
            }

            return (a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 1);
          }))
      );
  }

  disconnect(): void {
  }
}
