import {Component, Input} from '@angular/core';

import {CatalogService} from '../shared/services/catalog.service';

@Component({
  selector   : 'app-process-detail',
  templateUrl: 'process-detail.component.html',
  styleUrls  : ['process-detail.component.css'],
})

export class ProcessDetailComponent {

  @Input() pageType: number;
  @Input() data: any;
  @Input() height: number;
  @Input() width: number;
  @Input() parent: any;

  public constructor(public catalogService: CatalogService) {

  }


  public getStyle(): {height: string; width: string} {
    const h: string = this.height + '%';
    const w: string = this.width + '%';

    return {height: h, width: w};
  }
}
