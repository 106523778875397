import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {AfterViewInit, Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {NavigationHelper} from '../../shared/helpers/navigation.helper';
import {Point} from '../../shared/models/point.model';

/*COMPONENTS*/
import {Sector} from '../../shared/models/sector.model';

@Component({
  selector   : 'app-infography-zoom',
  templateUrl: 'infography-zoom.component.html',
  styleUrls  : ['infography-zoom.component.css'],
  animations : [
    trigger('sectorAnimation', [
      state('0', style({transform: 'translateY(-100%)', opacity: 0})),
      state('1', style({transform: 'translateY(0%)', opacity: 1})),
      transition('0 => 1', animate(0)),
      transition('1 => 0', animate(0))
    ]),
    trigger('activatePin', [
      state('1', style({height: '50px'})),
      state('0', style({height: '35px'})),
      transition('0 => 1', [
        animate('0ms ease-in', keyframes([
          style({transform: 'translate3d(0,0,0)', offset: 0}),
          style({transform: 'translate3d(0,-20px,0)', offset: 0.5}),
          style({transform: 'translate3d(0,0,0)', offset: 1})
        ]))
      ])]
    )]
})

export class InfographyZoomComponent implements AfterViewInit {
  @Input() sector: Sector;
  @Input() isLegend: boolean;

  @Input() get selectedAppIndex(): number {
    return this._selectedAppIndex;
  }

  set selectedAppIndex(value: number) {
    this._selectedAppIndex = value;
    if (value === null) {
      this.selectedPin = null;
    }
  }

  @Output() selectedAppIndexChange: EventEmitter<number> = new EventEmitter<number>();

  public allPins: any[]     = null;
  public selectedPin: Point = null;
  public containerH         = 0;
  public containerW         = 0;
  private _selectedAppIndex: number;

  constructor(private navigationHelper: NavigationHelper) {
  }

  @HostListener('window:resize')
  initSvg(): void {
    setTimeout(() => // INFO: Needed for fluid int process
    {
      this.processContainer();
    }, 500);

    setTimeout(() => // INFO: Needed for fluid int process
    {
      this.allPins = this.sector.pins
        .reduce((memo, el) =>
          memo.concat(el));
    }, 1000);
  }

  private get associatedPins(): Point[] {
    if (this.selectedAppIndex === null) {
      return [];
    }
    return this.sector.pins[this.selectedAppIndex];
  }

  public ngAfterViewInit(): void {
    this.initSvg();
  }

  public isActivatedPin(pin): string {
    if (this.associatedPins.some((p) => p.isEqual(pin))) {
      return '50px';
    }

    return '35px';
  }

  public getZIndex(): string {
    if (!this.isLegend) {
      return '120';
    }

    return '109';
  }

  public processContainer(): void {
    setTimeout(() => {
      const elem: any = document.getElementById('infography_zoom_image');
      this.containerW = elem.width;
      this.containerH = elem.height;
    }, 0);
  }

  public pinSelected(pin: Point): void {
    let tmpSelectedAppIndex;
    if (this.selectedPin && this.selectedPin.isEqual(pin)) {
      this.selectedPin    = null;
      tmpSelectedAppIndex = null;
    } else {
      this.selectedPin    = pin;
      tmpSelectedAppIndex = this.sector.pins
        .findIndex(pinArray =>
          pinArray.some(elm => elm.isEqual(pin)));
    }
    if (tmpSelectedAppIndex !== this.selectedAppIndex) {
      this.navigationHelper.resetCarouselIndex();
      this.selectedAppIndex = tmpSelectedAppIndex;
      this.selectedAppIndexChange.emit(this.selectedAppIndex);
    }
  }

  public isAssociated(pin: any): string {
    if (this.selectedAppIndex === null) {
      return 'assets/images/polygone_offer.png';
    }

    if (this.selectedPin && pin.isEqual(this.selectedPin)) {
      // INFO: Check if the selected pin belong to the selected application
      // INFO: (handling the case where a pin has previously been selected and we change of application from the process list)
      const appIndexOfSelectedPin = this.sector.pins
        .findIndex(pinArray =>
          pinArray.some(elm => elm.isEqual(this.selectedPin)));

      if (appIndexOfSelectedPin === this.selectedAppIndex) {
        return 'assets/images/polygone_selected.png';
      }
    }

    for (const elm of this.associatedPins) {
      if (pin.x === elm.x && pin.y === elm.y) {
        return 'assets/images/polygone_offer_on.png';
      }
    }
    return 'assets/images/polygone_offer.png';
  }

  public getTopArrowBottom(percentLeft: number): string {
    return percentLeft + 5 + '%';
  }

  public getTopArrowTop(percentTop: number): string {
    return percentTop + 5 + '%';
  }

  public getArrowBottom(percentLeft: number): string {
    return percentLeft + '%';
  }

  public getArrowTop(percentTop: number): string {
    return percentTop + '%';
  }
}
