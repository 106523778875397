import {Component, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector     : 'app-side-menu',
  templateUrl  : 'side-menu.component.html',
  styleUrls    : ['side-menu.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class SideMenuComponent {
  constructor(public router: Router) {
  }
}
