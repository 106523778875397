export class Language {
  Id: string;
  Name: string;
  Code: string;
  IsActive: boolean;
  IsOffline: boolean;
  CountryId: string;

  public static GetUniqueKey(language: Language): string {
    return JSON.stringify({
      [language.CountryId]: language.Id
    });
  }
}
