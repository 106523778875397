import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {AppComponent} from '../../../app.component';

/*MODELS*/
/*COMPONENTS*/
import {LogoutComponent} from '../../../logout/logout.component';

/*HELPERS*/
import {MediaHelper} from '../../helpers/media.helper';
import {NavigationHelper} from '../../helpers/navigation.helper';
import {AuthService} from '../../services/auth.service';
import {CatalogService} from '../../services/catalog.service';

/*SERVICES*/
import {SynchronizeService} from '../../services/synchronize.service';

@Component({
  selector   : 'app-extras-menu',
  templateUrl: 'extras-menu.component.html',
  styleUrls  : ['extras-menu.component.css']
})
export class ExtrasMenuComponent {
  public appVersion: string = null;

  constructor(public  dialog: MatDialog,
              public  parent: AppComponent,
              public  authService: AuthService,
              public  router: Router,
              public  syncService: SynchronizeService,
              public  catalogService: CatalogService,
              private navigationHelper: NavigationHelper,
              private mediaHelper: MediaHelper) {
    this.appVersion = environment.version;
  }

  public logout(): void {
    this.dialog.open(LogoutComponent);
  }

  public sync(): void {
    if (this.router.url === '/synchronize' && (this.parent.customerShown || this.parent.mediaShown || this.parent.gasShown)) {
      this.navigationHelper.back();
      return;
    }
    this.router.navigate(['/synchronize'])
      .catch(console.error);

  }

  public goToSignature(): void {
    if (this.router.url === '/signature' && (this.parent.customerShown || this.parent.mediaShown || this.parent.gasShown)) {
      this.navigationHelper.back();
      return;
    }
    this.router.navigate(['/signature'])
      .catch(console.error);

  }

  public goToWelcome(): void {
    this.router.navigate(['/welcome'])
      .catch(console.error);

  }

  public openUserGuide(): void {
    this.catalogService.fetchCurrentData()
      .then((data: any) => {
        if (data.userGuide === undefined || data.userGuide === null) {
          throw new Error('User guide not exist in current data');
        }

        const path = data.userGuide.path;

        this.mediaHelper.openMedia(path);
      })
      .catch((err: any) => {
        throw new Error('ExtrasMenuComponent : openUserGuide -> ' + err);
      });
  }
}
