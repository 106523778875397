import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, ElementRef, QueryList, ViewChildren} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';
import {debounceTime} from 'rxjs/operators';
import {AppComponent} from '../app.component';

/*COMPONENTS*/
import {LoadingComponent} from '../shared/components/loading/loading.component';
import {AnalyticsType} from '../shared/enums/analytics.enum';
import {MediaHelper} from '../shared/helpers/media.helper';
import {NavigationHelper} from '../shared/helpers/navigation.helper';

/*HELPERS*/
import {PreloadingStrategyHelper} from '../shared/helpers/preloading-strategy.helper';
import {StorageHelper} from '../shared/helpers/storage.helper';
import {ThemeHelper} from '../shared/helpers/theme.helper';
import {AppData} from '../shared/models/appdata.model';
import {Application} from '../shared/models/application.model';
import {Focus} from '../shared/models/focus.model';

/*MODELS*/
import {Market} from '../shared/models/market.model';
import {Segment} from '../shared/models/segment.model';
import {AnalyticsService} from '../shared/services/analytics.service';

/*SERVICES*/
import {AuthService} from '../shared/services/auth.service';
import {CatalogService} from '../shared/services/catalog.service';

@Component({
  selector   : 'app-home',
  templateUrl: 'home.component.html',
  styleUrls  : ['home.component.css'],
  animations :
    [
      trigger('expandMarket', [
        state('1', style({height: '100%', transform: 'translateX(0%)', display: 'flex'})),  // big
        state('0', style({height: '0%', transform: 'translateX(100%)', display: 'none'})),  // small
        state('-1', style({height: '46%', transform: 'translateX(0%)', display: 'flex'})),  // initial
        transition('-1 => 0', animate(0)), // initial to big
        transition('-1 => 1', animate(0)), // initial to small
        transition('1 => -1', animate(0)), // big to initial
        transition('0 => -1', animate(0))  // small to initial
      ]),
      trigger('hideHeader', [
        state('1', style({height: '0'})),
        state('0', style({height: '*'})),
        transition('0 => 1', animate(0)),
        transition('1 => 0', animate(0))
      ]),
      trigger('expandMarketColumn', [
        state('1', style({width: '66%', height: '100%'})),
        state('0', style({width: '0'})),
        transition('0 => 1', animate(0)),
        transition('1 => 0', animate(0))
      ]),
      trigger('applicationSelected', [
        state('1', style({width: '0', transform: 'translateX(100%)', opacity: 0})),
        state('0', style({width: '*', transform: 'translateX(0%)', opacity: 1})),
        transition('0 => 1', animate(0)),
        transition('1 => 0', animate(0))
      ])
    ]
})

export class HomeComponent {
  @ViewChildren('applicationElm') applicationQL: QueryList<ElementRef>;

  public markets: Market[]                = [];
  public applications: Application[]      = [];
  public focuses: any[]                   = [];
  public beyondGases: any                 = {tiles: [], title: null};
  public supplyModes: any                 = {tiles: [], title: null};
  public filterInput: FormControl         = new FormControl();
  public filterProcess                    = '';
  public selectedMarket: Market           = null;
  public selectedApplication: Application = null;
  public marketState                      = false;
  public isSearch                         = false;
  public currentData: AppData;
  private aboutPath: any                  = '';
  private loadingRef: any                 = null;

  constructor(public  mediaHelper: MediaHelper,
              private translate: TranslateService,
              private parent: AppComponent,
              private preloadStrategy: PreloadingStrategyHelper,
              private storageHelper: StorageHelper,
              private authService: AuthService,
              private themeHelper: ThemeHelper,
              private dialog: MatDialog,
              private router: Router,
              private navigationHelper: NavigationHelper,
              private sanitizer: DomSanitizer,
              private snackBar: MatSnackBar,
              private catalogService: CatalogService,
              private analyticsService: AnalyticsService) {
    this.parent.activeMenu();

    this.analyticsService.sendEvent(AnalyticsType.OPEN_VIEW, this.router.url);

    this.themeHelper.setPageTitle('');

    this.initData();

    this.parent.initClickOnLogo()
      .subscribe((_) => {
        this.marketState = false;

        for (const market of this.markets) {
          (market as any).$$state = -1;
        }
      });
  }

  public openFocus(focus: Focus): void {
    this.analyticsService.sendEvent(AnalyticsType.OPEN_FOCUS, focus.getTitle(this.currentData));
    switch (focus.seeMoreAt) {
      case '/product':
        this.catalogService.initProduct(focus.targetId, focus.supplyModes,
          (processedData) => {
            this.navigationHelper.routerData = processedData;
            this.router.navigate(['/product'])
              .catch(console.error);
          });
        break;
      case '/supplymode':
        this.catalogService.initSupplyMode(focus.targetId,
          (processedData) => {
            this.navigationHelper.routerData = processedData;
            this.router.navigate(['/supplymode'])
              .catch(console.error);
          });
        break;
      case '/offer':
        this.catalogService.initOffer(focus.targetId,
          (processedData) => {
            this.navigationHelper.routerData = processedData;
            this.router.navigate(['/offer'])
              .catch(console.error);
          });
        break;
      case '/service':
        this.catalogService.initService(focus.targetId,
          (processedData) => {
            this.navigationHelper.routerData = processedData;
            this.themeHelper.setPageTitle(processedData.service.name);
            this.router.navigate(['/service'])
              .catch(console.error);
          });
        break;
      case '/freefocus':
        this.catalogService.initFreeFocus(focus.targetId,
          (processedData) => {
            this.navigationHelper.routerData = processedData;
            this.themeHelper.setPageTitle(processedData.service.name);
            this.router.navigate(['/service'])
              .catch(console.error);
          });
        break;
      case '/brand':
        Focus.initBrand(this.currentData, focus.targetId,
          (processedData) => {
            this.navigationHelper.routerData = processedData;
            this.router.navigate(['/brand'])
              .catch(console.error);
          });
        break;
    }
  }

  public goToSegment(segment: Segment): void {
    this.parent.hideMenu();
    this.navigationHelper.routerData =
      {
        segment,
        market     : this.selectedMarket,
        supplyModes: this.supplyModes,
        beyondGases: this.beyondGases
      };

    this.analyticsService.sendEvent(AnalyticsType.OPEN_SEGMENT, segment.name);
    this.router.navigate(['/infography'])
      .catch(console.error);
  }

  public gotToBeyondGases(): void {
    this.analyticsService.sendEvent(AnalyticsType.OPEN_BEYOND_GASES_FROM_HOME, AnalyticsType.OPEN_BEYOND_GASES_FROM_HOME);
    this.navigationHelper.routerData = this.beyondGases;
    this.router.navigate(['/beyondGases'])
      .catch(console.error);
  }

  public gotToSupplyModes(): void {
    this.navigationHelper.routerData = this.supplyModes;
    this.router.navigate(['/supplyModes'])
      .catch(console.error);
  }

  public activeSearch(): void {
    this.isSearch = !this.isSearch;

    setTimeout(() => {
      document.getElementById('focus').focus();
    }, 500);
  }

  public openMarket(market: any): void {
    this.analyticsService.sendEvent(AnalyticsType.OPEN_MARKET, market.name);

    if (market.$$state !== -1) {
      this.marketState = false;
      market.$$state   = -1;
      this.enableDisableStates(market, -1);
      this.navigationHelper.selectedMarketId = null;
      return;
    }
    this.navigationHelper.selectedMarketId = market.id;
    this.selectedMarket                    = market;
    this.marketState                       = true;
    market.$$state                         = 1;
    this.enableDisableStates(market, 0);
  }

  public openApplication(application: Application): void {
    this.analyticsService.sendEvent(AnalyticsType.OPEN_PROCESS_FROM_HOME, application.name);
    this.navigationHelper.resetCarouselIndex();

    this.themeHelper.customTiles = false;
    this.selectedApplication     = application;

    this.catalogService.initProcess(application.id, false,
      (processedData) => {
        this.navigationHelper.routerData = processedData;
        this.router.navigate(['/processList'])
          .catch(console.error);
      });
  }

  public openAboutAl(): void {
    this.analyticsService.sendEvent(AnalyticsType.OPEN_ABOUT_AL, AnalyticsType.OPEN_ABOUT_AL);
    this.mediaHelper.openMedia(this.aboutPath);
  }

  private initData(): void {
    this.showLoadingDialog(true);

    setTimeout(() => {
      this.filterInput.valueChanges
        .pipe(debounceTime(200))
        .subscribe(term => {
          this.filterProcess = term;
        });

      this.catalogService.fetchCurrentData()
        .then(data => {
          this.onSuccessParseData(data);
        })
        .catch(err => {
          this.snackBar.open(err, 'x',
            {
              duration: 5000
            });
          this.router.navigate(['synchronize'])
            .catch(console.error);
          throw new Error('HomeComponent : constructor -> ' + err);
        })
        .finally(() => this.showLoadingDialog(false));
    }, 500);
  }

  private onSuccessParseData(data: AppData): void {
    this.catalogService.setCurrentTranslations(data);
    this.currentData = data;
    this.markets     = Object.keys(data.markets)
      .map<Market>(key => data.markets[key])
      .sort((a, b) => a.name.localeCompare(b.name));

    this.catalogService.processApplications();

    this.applications = this.catalogService.mergedApplications;
    this.focuses      = data.focuses;

    Object.keys(data.beyondGasTiles)
      .forEach((item) => {
        this.beyondGases.tiles.push(data.beyondGasTiles[item]);
      });

    Object.keys(data.supplyModeTiles)
      .forEach((item) => {
        this.supplyModes.tiles.push(data.supplyModeTiles[item]);
      });

    this.aboutPath = data.aboutAl.folderName + '/' + data.aboutAl.fileName;

    this.initAnimationMarketState();
  }

  private showLoadingDialog(open: boolean): void {
    if (!open && this.loadingRef !== null) {
      this.loadingRef.close();
      this.loadingRef = null;
      return;
    }

    this.loadingRef = this.dialog.open(LoadingComponent, {disableClose: true});
  }

  private initAnimationMarketState(): void {
    this.markets
      .forEach((item: any) => {
        item.$$state = -1;
      });

    if (this.navigationHelper.selectedMarketId) {
      this.openMarket(this.markets.find((m) => m.id === this.navigationHelper.selectedMarketId));
    }
  }

  private enableDisableStates(market: any, stt: number): void {
    this.markets
      .forEach((item: any) => {
        if (item.id !== market.id) {
          item.$$state = stt;
        }
      });
  }
}
