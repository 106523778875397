import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MediaHelper} from '../../shared/helpers/media.helper';

/*HELPERS*/
import {MimeHelper} from '../../shared/helpers/mime.helper';
import {StorageHelper} from '../../shared/helpers/storage.helper';

/*MODELS*/
import {Customer} from '../../shared/models/customer.model';

/*SERVICES*/
import {CustomerService} from '../../shared/services/customer.service';

/*COMPONENTS*/
import {RemoveExpectationComponent} from '../remove-expectation/remove-expectation.component';
import {RemoveMediaComponent} from '../remove-media/remove-media.component';


@Component({
  selector   : 'app-customer-detail',
  templateUrl: 'customer-detail.component.html',
  styleUrls  : ['customer-detail.component.css'],
})

export class CustomerDetailComponent {
  @Input() customer: Customer;

  public loadingRef: any = null;
  public selId           = '';

  public constructor(public  mimeHelper: MimeHelper,
                     private snackBar: MatSnackBar,
                     private customerService: CustomerService,
                     private storageHelper: StorageHelper,
                     private mediaHelper: MediaHelper,
                     private dialog: MatDialog) {
  }

  public openRemoveMedia(row: any): void {
    this.selId = row.Id;

    const dialogRef = this.dialog.open(RemoveMediaComponent,
      {
        data: row
      });

    dialogRef.afterClosed()
      .subscribe(result => {
        this.selId = '';

        if (result === '') // INFO: Cancel task
        {
          return;
        }

        this.customer.Medias = this.customer.Medias.filter(m => m.Id !== result.Id);

        this.customerService.updateCurrentCustomer(this.customer);
      });
  }

  public openRemoveExpectation(row: any): void {
    this.selId = row.Id;

    const dialogRef = this.dialog.open(RemoveExpectationComponent,
      {
        data: row
      });

    dialogRef.afterClosed()
      .subscribe(result => {
        this.selId = '';

        if (result === '') // INFO: Cancel task
        {
          return;
        }

        this.customer.Expectations = this.customer.Expectations.filter(e => e.Id !== result.Id);

        this.customerService.updateCurrentCustomer(this.customer);
      });
  }

  public openMedia(mediaPath: string): void {
    this.mediaHelper.openMedia(mediaPath);
  }
}
