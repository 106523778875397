<div class="process-content">
  <app-carousel [items]="pages" [navTop]="true" [loop]=false (indexChange)="onCarouselIndexChanged($event)">
    <ng-template let-item="item">
      <div class="process-page-content" [ngSwitch]="item.type">
        <app-about *ngSwitchCase="PageType.About" [about]="item.about">
        </app-about>
        <app-expectation *ngSwitchCase="PageType.Expectation" [expectations]="item.expectations"
                         [process]="application.name">
        </app-expectation>
        <app-offer *ngSwitchCase="PageType.Offer" [applicationId]="application.id">
        </app-offer>
        <app-product [parent]="parent" *ngSwitchCase="PageType.Product" [productData]="item.productData">
        </app-product>
        <app-proposal *ngSwitchCase="PageType.Proposal" [proposals]="item.proposals">
        </app-proposal>
        <app-tiles *ngSwitchCase="PageType.Tiles" [tiles]="item.tiles">
        </app-tiles>
      </div>
    </ng-template>
  </app-carousel>
</div>

