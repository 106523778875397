<div
class="media_detail_container_search"
[ngClass]="{'media_detail_container': !isSearchView, 'media_detail_container_search': isSearchView}">
  <div *ngFor="let media of medias ">
    <h2 *ngIf="(media.medias | search:'name':filterString).length !== 0">{{ media.title }}</h2>
    <mat-nav-list class="media_list" *ngIf="(media.medias | search:'name':filterString).length !== 0">
      <mat-list-item
        (click)="openMedia(item.path)"
        *ngFor="let item of media.medias | search:'name':filterString">
        <img [src]="mimeHelper.getIconFromFileName(item.path)" />
        <div class="media_label">
          <p>{{ item.name }}</p>
        </div>
      </mat-list-item>
    </mat-nav-list >
  </div>
</div>
