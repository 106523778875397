<div class="gas_container" [ngClass]="{fromInfography: fromInfography}">
  <mat-toolbar
    *ngIf="fromInfography"
    class="top_menu_white">
    <button
      mat-icon-button
      (click)="isSearch = false; filterString = '';"
      *ngIf="isSearch"
      class="back_search">
      <img src="./assets/icons/ic_search_back.png" alt="back"/>
    </button>
    <mat-form-field
      [ngClass]="{'input_search_container_active': isSearch,
                  'input_search_container_inactive': !isSearch}">
      <button
        mat-icon-button
        class="input_search">
        <mat-icon>search</mat-icon>
      </button>
      <label>
        <input matInput [(ngModel)]="filterString">
      </label>
    </mat-form-field>
    <div class="button_menu_container" *ngIf="!isSearch">
      <button
        mat-icon-button
        (click)="isSearch = true"
        *ngIf="!isSearch"
        class="button_menu_search_blue">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <app-top-menu></app-top-menu>
    <div class="app_toolbar_spacer">
      <h1
        class="app_title_blue"
        *ngIf="!isSearch">
        {{ themeHelper.pageTitle }}
      </h1>
    </div>
  </mat-toolbar>
  <div class="ui-g scroll_container">
    <ng-container *ngIf="!isDetail">
      <div *ngFor="let productObj of products | search:'title':filterString">
        <div class="ui-g-3">
          <button
            color="primary"
            mat-raised-button
            class="gas_category"
            (click)="openProduct(productObj)">
            <h4>{{ productObj.product.title }}</h4>
          </button>
        </div>
      </div>
    </ng-container>
    <app-product *ngIf="isDetail" [parent]="appComponent" [productData]="product"></app-product>
  </div>
</div>
