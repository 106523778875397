<div class="component_content" (click)="open()" *ngIf="data">
    <img class="foreground" src="./assets/images/tile_foreground.png" alt="tile_foreground">
    <img class="background-image" *ngIf="data.path" [src]="data.path | fromStorage" alt="image_background">
     <button
      class="play"
      mat-mini-fab>
        <img src="./assets/icons/ic_play.png" alt="play">
    </button>
  <div
    class="text"
    [innerHTML]="content">
  </div>
</div>
