import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';

/*COMPONENTS*/
import {AppComponent} from '../../app.component';
import {StorageHelper} from '../../shared/helpers/storage.helper';

/*HELPERS*/
import {ThemeHelper} from '../../shared/helpers/theme.helper';

/*SERVICES*/
import {AnalyticsService} from '../../shared/services/analytics.service';

@Component({
  selector   : 'app-tile-beyond',
  templateUrl: 'tile-beyond.component.html',
  styleUrls  : [
    'tile-beyond.component.css'
  ]
})

export class TileBeyondComponent implements OnInit {
  @Input() public data: any;

  constructor(private themeHelper: ThemeHelper,
              private analyticsService: AnalyticsService,
              private storageHelper: StorageHelper,
              private router: Router,
              public  parent: AppComponent,
              public sanitizer: DomSanitizer) {
  }

  public ngOnInit(): void {
  }

  public open(): void {
    if (this.router.url === '/beyondGases') {
      this.themeHelper.setPageTitle(this.data.text);
    }

    if (this.router.url.indexOf('infography') !== -1) {
      this.parent.openDetail(2, this.data, true);
      return;
    }

    this.parent.openDetail(2, this.data);
  }
}
