import {Injectable} from '@angular/core';
import {StorageHelper} from '../helpers/storage.helper';

/*HELPERS*/
import {UserHelper} from '../helpers/user.helper';

/*MODELS*/
import {Customer} from '../models/customer.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public static readonly CUSTOMERS_KEY   = 'CUSTOMERS';
  public static readonly SELCUSTOMER_KEY = 'SELCUSTOMER';

  constructor(private userHelper: UserHelper,
              private storageHelper: StorageHelper) {
  }

  public setCustomers(customers: Customer[]): void {
    this.storageHelper.saveDbItem(JSON.stringify(customers), CustomerService.CUSTOMERS_KEY).catch(console.error);
  }

  public getCustomers(): Promise<Customer[]> {
    return this.storageHelper.getStringDbItem(CustomerService.CUSTOMERS_KEY)
      .then(custString => custString ? JSON.parse(custString) : []);
  }

  public setSelectedCustomer(customer: Customer): void {
    this.storageHelper.saveItem(customer, CustomerService.SELCUSTOMER_KEY);
    this.updateCurrentCustomer(customer, false);
  }

  public getSelectedCustomer(): Customer {
    return this.storageHelper.getItem(CustomerService.SELCUSTOMER_KEY) as Customer;
  }

  public removeSelectedCustomer(): void {
    this.storageHelper.removeItem(CustomerService.SELCUSTOMER_KEY);
  }

  public updateCurrentCustomer(customer: Customer, saveSelected: boolean = true): void {
    const selCustomer: Customer = this.getSelectedCustomer();

    this.getCustomers()
      .then(customers => {
        const saved = customers.find(c => c.Id === customer.Id);

        if (saved !== undefined) {
          customers = customers.filter(c => c.Id !== saved.Id);

          if (saveSelected && selCustomer !== undefined && customer.Id === selCustomer.Id) {
            this.setSelectedCustomer(customer);
          }

          customers.push(customer);
          this.setCustomers(customers);
        }
      });
  }
}
