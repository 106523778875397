<div class="component_content">
  <div class="title" *ngIf="isEmbedded">
    <h1>{{product?.title}}</h1>
  </div>
  <img
    *ngIf="canAddToCart  &&  !selectDocs"
    class="select_elements"
    (click)="activeSelection()"
    src="./assets/icons/add_to_cart.png" alt="activeSelection"/>
  <img
    *ngIf="selectDocs"
    class="select_elements"
    (click)="addToCart()"
    src="./assets/icons/ic_add_customer.png" alt="addToCart"/>

  <div class="ui-g">
    <div class="ui-g-4">
      <mat-grid-list class="grid-container" cols="1" rowHeight="fit">
        <mat-grid-tile [rowspan]="8">
          <mat-card>
            <mat-card-title>
              <h4>{{'visuals' | translate}}</h4>
            </mat-card-title>
            <mat-card-content class="visuals">
              <img [src]="product?.imagePath | fromStorage" alt="visuals"/>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
        <mat-grid-tile [rowspan]="5" *ngIf="product?.productPdfs?.length > 0">
          <mat-card>
            <mat-card-title>
              <h4>{{'product_sheet' | translate}}</h4>
            </mat-card-title>
            <mat-card-content class="sheet">
              <div class="docs-container">
                <div class="docs-container-line" *ngFor="let doc of product.productPdfs">
                  <img [src]="mimeHelper.getIconFromFileName(doc.path)" alt="fileType"/>
                  <a (click)="!selectDocs && mediaHelper.openMedia(doc.path)">
                    <h6>{{doc.displayName}}</h6>
                  </a>
                  <mat-checkbox
                    *ngIf="selectDocs"
                    [checked]="isSelected(doc)"
                    (change)="selectItem($event, doc)"
                    color="primary">
                  </mat-checkbox>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <div class="ui-g-4">
      <mat-grid-list class="grid-container" cols="1" rowHeight="fit">
        <mat-grid-tile [rowspan]="4">
          <mat-card>
            <mat-card-title>
              <h4>{{'composition' | translate}}</h4>
            </mat-card-title>
            <mat-card-content
              class="composition"
              [innerHTML]="sanitizer.bypassSecurityTrustHtml(product?.composition)"></mat-card-content>
          </mat-card>
        </mat-grid-tile>
        <mat-grid-tile [rowspan]="8">
          <mat-card>
            <mat-card-title>
              <h4>{{'specification' | translate}}</h4>
            </mat-card-title>
            <mat-card-content class="specification">
              <app-carousel [items]="product?.specifications">
                <ng-template let-item="item">
                  <div class="inner-specification" [innerHTML]="sanitizer.bypassSecurityTrustHtml(item)">
                  </div>
                </ng-template>
              </app-carousel>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <div class="ui-g-4">
      <mat-grid-list class="grid-container" cols="1" rowHeight="fit">
        <mat-grid-tile [rowspan]="4">
          <mat-card>
            <mat-card-title>
              <h4>{{'quality_certification' | translate}}</h4>
            </mat-card-title>
            <mat-card-content
              class="quality"
              [innerHTML]="sanitizer.bypassSecurityTrustHtml(product?.quality)">
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
        <mat-grid-tile [rowspan]="4" *ngIf="supplyModes && supplyModes.length > 0">
          <mat-card>
            <mat-card-title>
              <h4>{{'supply_modes' | translate}}</h4>
            </mat-card-title>
            <mat-card-content class="supplymode">
              <div #scrollMenu class="scroll_menu">
                <img
                  *ngFor="let supplyMode of supplyModes"
                  [src]="supplyMode.logoPath | fromStorage"
                  (click)="openSupplyMode(supplyMode)" alt="supplyMode"/>
              </div>
              <i
                (click)="prev()"
                class="fas fa-chevron-circle-left fa-3x left"
                aria-hidden="true">
              </i>
              <i
                (click)="next()"
                class="fas fa-chevron-circle-right fa-3x right"
                aria-hidden="true">
              </i>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
        <mat-grid-tile [rowspan]="4">
          <mat-card>
            <mat-card-title>
              <h4>{{ 'equipment' | translate }}</h4>
            </mat-card-title>
            <mat-card-content class="equipment">
              <div class="equipment-text" [innerHTML]="equipment"></div>
              <div class="docs-container">
                <div class="docs-container-line" *ngFor="let doc of product?.equipmentPdfs">
                  <img [src]="mimeHelper.getIconFromFileName(doc.path)" alt="fileType"/>
                  <a (click)="!selectDocs && mediaHelper.openMedia(doc.path)">
                    <h6>{{doc.displayName}}</h6>
                  </a>
                  <mat-checkbox
                    *ngIf="selectDocs"
                    [checked]="isSelected(doc)"
                    (change)="selectItem($event, doc)"
                    color="primary">
                  </mat-checkbox>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</div>
