import {Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';


// eslint-disable-next-line no-shadow
enum TouchEventAction {
  Unknown = 0,
  Start   = 1,
  End     = 2
}

// eslint-disable-next-line no-shadow
enum SwipeDirection {
  Unknown  = 0,
  Next     = 1,
  Previous = 2
}

@Component({
  selector   : 'app-carousel',
  templateUrl: 'carousel.component.html',
  styleUrls  : ['carousel.component.css'],
})

export class CarouselComponent implements OnInit {
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
  @Input() navTop                             = false;
  @Input() loop                               = true;
  @Output() indexChange: EventEmitter<number> = new EventEmitter<number>();

  touchEventAction  = TouchEventAction;
  transitionBlocked = true;

  private _index        = 0;
  private _items: any[] = [];


  private swipeCoords?: [number, number];
  private swipeTime?: number;

  constructor() {
  }

  public swipe(e: TouchEvent, type: TouchEventAction, callback?: (direction: SwipeDirection) => void): void {
    const coords: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
    const time                     = new Date().getTime();

    e.stopPropagation();

    if (type === TouchEventAction.Start) {
      this.swipeCoords = coords;
      this.swipeTime   = time;
    } else if (type === TouchEventAction.End) {
      const direction = [coords[0] - this.swipeCoords[0], coords[1] - this.swipeCoords[1]];
      const duration  = time - this.swipeTime;

      if (duration < 1000 // Short enough
        && Math.abs(direction[1]) < Math.abs(direction[0]) // Horizontal enough
        && Math.abs(direction[0]) > 30) {  // Long enough
        const swipe = direction[0] < 0 ? SwipeDirection.Next : SwipeDirection.Previous;

        if (callback) {
          callback(swipe);
        }
      }
    }
  }

  public set index(index: number) {
    setTimeout(() => {
      this._index = index;
      this.emitIndexChanged();
    }, 0);
  }

  public get index(): number {
    return this._index;
  }

  @Input() set items(values: any[]) {
    this._items = values;
    this.index  = 0;
  }

  get items(): any[] {
    return this._items;
  }

  get intNavTop(): number {
    return +this.navTop;
  }

  get intNavBottom(): number {
    return +!this.navTop;
  }

  public displayContent(index: number): boolean {
    return index === this.index;
  }

  public next(): void {
    if (this.loop) {
      this.index = (this.index + 1) % this.items.length;
    } else {
      this.index = Math.min(this.index + 1, this.items.length - 1);
    }
    this.emitIndexChanged();
  }

  public prev(): void {
    if (this.loop) {
      this.index = (this.items.length + this.index - 1) % this.items.length;
    } else {
      this.index = Math.max(this.index - 1, 0);
    }
    this.emitIndexChanged();
  }

  public displayNext(): boolean {
    if (!this.items) {
      return false;
    }
    return this.items.length > 1 && (this.loop || this.index !== this.items.length - 1);
  }

  public displayPrev(): boolean {
    if (!this.items) {
      return false;
    }
    return this.items.length > 1 && (this.loop || this.index !== 0);
  }

  public setIndex(index: number): void {
    if (this.index !== index) {
      this.index = index;
    }
  }

  public swipeResult =
           (direction: SwipeDirection) => {
             if (direction === SwipeDirection.Next) {
               this.next();
             }
             if (direction === SwipeDirection.Previous) {
               this.prev();
             }
           };

  public ngOnInit(): void {
    this.transitionBlocked = true;
    setTimeout(() => this.transitionBlocked = false, 500);
  }

  private emitIndexChanged(): void {
    this.indexChange.emit(this.index);
  }

}
