import {ArrayTyper} from '@caliatys/array-typer';
import {Point} from './point.model';


export class Boundary {
  points: Point[];


  constructor(json: any) {
    if (!json) {
      return null;
    }
    this.points = ArrayTyper.asArray(Point, json || []);
  }

  contains(point: Point): boolean {
    let result = false;
    for (let i = 0, j = this.points.length - 1; i < this.points.length; j = i++) {
      if ((this.points[i].y > point.y) !== (this.points[j].y > point.y) &&
        (
          point.x <
          (this.points[j].x - this.points[i].x) *
          (point.y - this.points[i].y) / (this.points[j].y - this.points[i].y) + this.points[i].x
        )
      ) {
        result = !result;
      }
    }
    return result;
  }
}
