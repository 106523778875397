import {AfterViewInit, Component, ElementRef, QueryList, ViewChildren} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';
import {debounceTime} from 'rxjs/operators';

/*MODELS*/
/*COMPONENTS*/
import {AppComponent} from '../app.component';
import {AnimationHelper} from '../shared/helpers/animation.helper';
import {NavigationHelper} from '../shared/helpers/navigation.helper';

/*HELPERS*/
import {ThemeHelper} from '../shared/helpers/theme.helper';
import {Application} from '../shared/models/application.model';
import {AnalyticsService} from '../shared/services/analytics.service';

/*SERVICES*/
import {CatalogService} from '../shared/services/catalog.service';

@Component({
  templateUrl: 'processlist.component.html',
  styleUrls  : ['processlist.component.css']
})

export class ProcessListComponent implements AfterViewInit {
  @ViewChildren('applicationElm') applicationQl: QueryList<ElementRef>;

  public applications: Application[];
  public selectedApplication: Application;
  public filterInput: FormControl = new FormControl();
  public filterProcess            = '';
  public isSearch                 = false;

  constructor(public  parent: AppComponent,
              private route: ActivatedRoute,
              private themeHelper: ThemeHelper,
              private catalogService: CatalogService,
              private analyticsService: AnalyticsService,
              private router: Router,
              private translater: TranslateService,
              private animationHelper: AnimationHelper,
              private navigationHelper: NavigationHelper,
              public  sanitizer: DomSanitizer) {
    this.parent.activeMenu();
    this.themeHelper.setPageTitle(null);

    const data: any = this.navigationHelper.routerData;
    if (data === undefined || data === null) {
      this.catalogService.fetchCurrentData()
        .then(_ => {
          this.navigationHelper.resetCarouselIndex();
          this.applications        = this.catalogService.mergedApplications;
          this.selectedApplication = this.applications.find((elm) => !!elm); // first element;
        });
      this.navigationHelper.routerData = null;
      return;
    } else {
      this.applications        = this.catalogService.mergedApplications;
      this.selectedApplication = data.application;
    }

    this.filterInput.valueChanges
      .pipe(debounceTime(200))
      .subscribe(term => {
        this.filterProcess = term;
      });
    this.navigationHelper.routerData = null;
  }

  public ngAfterViewInit(): void {
    this.applicationQl.changes
      .subscribe(_ => {
        const targetIndex = this.applications.findIndex(item => item.id === this.selectedApplication.id);

        if (targetIndex !== -1) {
          const applicationView = this.applicationQl.find((elm, idx) => idx === targetIndex);
          this.animationHelper.scrollToTop(applicationView);
        }
      });
  }

  public activeSearch(): void {
    this.isSearch = !this.isSearch;

    setTimeout(() => {
      document.getElementById('focus-detail').focus();
    }, 500);
  }

  public openApplication(application: Application): void {
    this.navigationHelper.resetCarouselIndex();

    this.parent.hideDetail();

    this.themeHelper.customTiles = false;
    this.selectedApplication     = application;
  }
}
