import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';
import {BeyondGasesComponent} from './beyond-gases/beyond-gases.component';
import {BrandComponent} from './brand/brand.component';
import {HomeComponent} from './home/home.component';
import {InfographyComponent} from './infography/infography.component';
import {LoginComponent} from './login/login.component';

import {PageNotFoundComponent} from './not-found/not-found.component';
import {OfferComponent} from './offer/offer.component';
import {ProcessListComponent} from './processlist/processlist.component';
import {ProductComponent} from './product/product.component';
import {ServiceComponent} from './service/service.component';
import {AuthGuardHelper} from './shared/helpers/auth-guard.helper';
import {PreloadingStrategyHelper} from './shared/helpers/preloading-strategy.helper';
import {SignatureComponent} from './signature/signature.component';
import {SupplyModesComponent} from './supply-modes/supply-modes.component';
import {SynchronizeComponent} from './synchronize/synchronize.component';
import {WelcomeComponent} from './welcome/welcome.component';

const appRoutes: Routes = [
  {
    path     : 'login',
    component: LoginComponent,
  },
  {
    path       : 'synchronize',
    component  : SynchronizeComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path       : 'home',
    component  : HomeComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path       : 'product',
    component  : ProductComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path       : 'offer',
    component  : OfferComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path       : 'supplymode',
    component  : OfferComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path       : 'service',
    component  : ServiceComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path       : 'processList',
    component  : ProcessListComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path       : 'infography',
    component  : InfographyComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path       : 'brand',
    component  : BrandComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path       : 'beyondGases',
    component  : BeyondGasesComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path       : 'supplyModes',
    component  : SupplyModesComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path       : 'welcome',
    component  : WelcomeComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path       : 'signature',
    component  : SignatureComponent,
    canActivate: [AuthGuardHelper]
  },
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports  : [
    RouterModule.forRoot(appRoutes,
      {
    useHash: true,
    preloadingStrategy: PreloadingStrategyHelper,
    relativeLinkResolution: 'legacy'
})
  ],
  exports  :
    [
      RouterModule
    ],
  providers:
    [
      PreloadingStrategyHelper,
      AuthGuardHelper
    ]
})
export class AppRoutingModule {
}
