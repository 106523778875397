<h1 mat-dialog-title>{{ 'google_sign_out' | translate }}</h1>
<mat-dialog-content>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="dialog_actions">
    <button
      mat-raised-button
      mat-dialog-close
      class="dialog_right_button"
      type="button"
      color="primary">
      {{ 'NO' | translate }}
    </button>
    <button
      mat-raised-button
      type="button"
      (click)="logout()"
      color="primary">
      {{ 'yes' | translate }}
    </button>
  </div>
</mat-dialog-actions>
