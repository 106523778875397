import {ArrayTyper} from '@caliatys/array-typer';
import {ALDocument} from './document.model';


export class WelcomeDocument {
  welcomePremiumGas: ALDocument[];
  welcomeProcessSolution: ALDocument[];
  welcomeSupplyModes: ALDocument[];
  welcomeOthers: { [key: string]: ALDocument[] };

  constructor(json: any) {
    if (!json) {
      return null;
    }

    this.welcomePremiumGas      = ArrayTyper.asArray(ALDocument, json.PremiumGas || []);
    this.welcomeProcessSolution = ArrayTyper.asArray(ALDocument, json.ProcessSolution || []);
    this.welcomeSupplyModes     = ArrayTyper.asArray(ALDocument, json.SupplyMode || []);

    this.welcomeOthers = {};
    Object.keys(json.Other).forEach(key =>
      this.welcomeOthers[key] = json.Other[key].map(otherDocument => new ALDocument(otherDocument.Document))
    );
  }
}
