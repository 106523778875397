// eslint-disable-next-line no-shadow
export enum PageType {
  Unknown     = 0,
  About       = 1,
  Expectation = 2,
  Tiles       = 3,
  Proposal    = 4,
  Offer       = 5,
  Product     = 6
}
