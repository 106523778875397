import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, timer} from 'rxjs';
import {catchError, map, timeout} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

import {StorageHelper} from '../helpers/storage.helper';

import {SynchronizeService} from './synchronize.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  public isOnline = false;

  constructor(public  http: HttpClient,
              private storageHelper: StorageHelper,
              private syncService: SynchronizeService) {
    timer(environment.xhrTimeout).subscribe(_ => this.connectivityChecker());
    timer(1000, 30000)
      .subscribe(() => {
        if (this.isOnline) {
          this.syncService.checkForUpdate().catch(console.error);
        }
      });
  }

  public sendEvent(action, category): void {
    const eventDate: string = new Date().getTime().toString();
    const event: any        = {
      hitType      : 'event',
      eventCategory: category,
      eventAction  : action,
      eventLabel   : eventDate
    };
    this.uploadEvent(event);
  }

  public connectivityChecker(): Observable<void> {
    return this.http.get<void>(environment.api + '/diagnostic/ping')
      .pipe(
        timeout(environment.xhrTimeout),
        map(() => {
          this.isOnline = true;
        }),
        catchError(err => {
          this.isOnline = false;
          throw err;
        }),
      );
  }

  private uploadEvent(event): void {
    this.connectivityChecker()
      .subscribe(
        () => (window as any).ga('send', event),
        // _ => this.storageHelper.saveItem(
        //   [...(this.storageHelper.getItem(AnalyticsService.GANA_KEY) as any[] ?? []), event],
        //   AnalyticsService.GANA_KEY
        // )
      );
  }
}
