<div class="extras_menu_container">
	<div class="menu-content">
		<div class="sync-menu-content" *ngIf="parent.checkTheme() === 1">
			<button mat-button  (click)="sync()">
				{{ 'sync' | translate }}
			</button>
      <div *ngIf="syncService.update" class="extras_badge"></div>
		</div>
		<button mat-button (click)="goToSignature()" *ngIf="parent.checkTheme() === 1">
			{{ 'SIGNATURE' | translate }}
		</button>
		<button mat-button  (click)="openUserGuide()" *ngIf="parent.checkTheme() === 1">
			{{ 'user_guide' | translate }}
		</button>
		<button mat-button (click)="goToWelcome()" *ngIf="parent.checkTheme() === 0">
			{{ 'PREPARATION_MODE' | translate }}
		</button>
		<button mat-button (click)="logout()">
			{{ 'google_sign_out' | translate }}
		</button>
	</div>
  <p class="instance-default" *ngIf="catalogService.defaultInstance">
    {{ catalogService.defaultInstance }}
  </p>
  <p class="version">
    {{ 'VERSION' | translate }} : {{ appVersion }}
  </p>
</div>
