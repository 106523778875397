import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GoogleAuth} from '@codetrix-studio/capacitor-google-auth';
import {CookieService} from 'ngx-cookie-service';
import {Observable, throwError} from 'rxjs';
import {catchError, map, timeout} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {UserHelper} from '../helpers/user.helper';
import {User} from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isLoggedIn    = false;
  public connectedMail = '';

  constructor(public  http: HttpClient,
              private userHelper: UserHelper,
              private cookieService: CookieService) {
  }

  public logout(): void {
    this.isLoggedIn = false;

    this.userHelper.removeConnectedUser();

    GoogleAuth.signOut().catch(console.error);
  }

  public googleServerAuth(idToken: string): Observable<User> {
    const body =
            {
              IdToken: idToken,
              IsWebApp: true
            };

    return this.http
      .post<User>(
        environment.api + '/loginWithGooglev2',
        JSON.stringify(body),
        {
          headers                           : new HttpHeaders({
            'Content-Type': 'application/json',
          }), withCredentials: true, observe: 'response'
        }
      )
      .pipe(
        timeout(environment.xhrTimeout),
        map(res => {
          return res.body;
        }),
        catchError(err => throwError(err || 'Server error'))
      );
  }

  public stdServerAuth(login: string, password: string): Observable<User> {
    const body = {UserName: login, Password: password, EncryptionLevel: 0};

    return this.http
      .post<any>(
        environment.api + '/login',
        JSON.stringify(body),
        {
          headers                           : new HttpHeaders({
            'Content-Type': 'application/json',
          }), withCredentials: true, observe: 'response'
        })
      .pipe(
        timeout(environment.xhrTimeout),
        map(res => {
          return res.body;
        }),
        catchError(err => throwError(err || 'Server error'))
      );
  }
}
