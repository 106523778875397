import {ArrayTyper} from '@caliatys/array-typer';
import {Page} from './page.model';

export class Application {
  id: number;
  name: string;
  pages: { [key: number]: Page };
  isHome: boolean;
  isTransverse: boolean;
  isMerged: boolean;

  constructor(json: any) {
    if (!json) {
      return null;
    }
    this.id           = json.Id;
    this.name         = json.Name;
    this.pages        = ArrayTyper.asDict(Page, json.Pages || [], page => page.order);
    this.isHome       = json.IsHome;
    this.isTransverse = json.IsTransverse;
  }
}
