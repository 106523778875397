import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../environments/environment';

@Component({
  templateUrl: 'login-dialog.component.html',
  styleUrls  : ['login-dialog.component.css']
})

export class LoginDialogComponent implements OnInit {
  public loginForm: FormGroup = null;

  constructor(public dialogRef: MatDialogRef<LoginDialogComponent>,
              public snackBar: MatSnackBar,
              private formBuilder: FormBuilder) {
  }

  public ngOnInit(): void {
    this.loginForm = this.formBuilder.group(
      {
        username: new FormControl(environment.defaultUsername, [Validators.required]),
        password: new FormControl(environment.defaultPassword, [Validators.required])
      });
  }

  public login(): void {
    this.dialogRef.close({
      username: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value
    });
  }
}
