import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

/*MODELS*/
import {Expectation} from '../../shared/models/expectation.model';

@Component({
  templateUrl: 'remove-expectation.component.html',
  styleUrls  : ['remove-expectation.component.css']
})

export class RemoveExpectationComponent {
  private readonly expectation: Expectation = new Expectation();

  constructor(public dialogRef: MatDialogRef<RemoveExpectationComponent>,
              public snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data !== null) {
      this.expectation = data;
    }
  }

  public save(): void {
    this.dialogRef.close(this.expectation);
  }
}
