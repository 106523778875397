import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

/*MODELS*/
import {AuthService} from '../shared/services/auth.service';

@Component({
  templateUrl: 'logout.component.html',
  styleUrls  : ['logout.component.css']
})

export class LogoutComponent {
  constructor(public dialogRef: MatDialogRef<LogoutComponent>,
              public authService: AuthService,
              public router: Router,
              public snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public logout(): void {
    this.authService.logout();
    this.router.navigate(['/login'])
      .catch(console.error);
    this.dialogRef.close();
  }
}
