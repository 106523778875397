import {Component, Input} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

/*MODELS*/
/*COMPONENTS*/
import {AnalyticsType} from '../../shared/enums/analytics.enum';
import {MediaHelper} from '../../shared/helpers/media.helper';

/*HELPERS*/
import {MimeHelper} from '../../shared/helpers/mime.helper';
import {StorageHelper} from '../../shared/helpers/storage.helper';
import {AnalyticsService} from '../../shared/services/analytics.service';

/*SERVICES*/
import {CustomerService} from '../../shared/services/customer.service';

@Component({
  selector   : 'app-welcome-detail',
  templateUrl: 'welcome-detail.component.html',
  styleUrls  : ['welcome-detail.component.css']
})

export class WelcomeDetailComponent {
  @Input() medias: any[];
  @Input() filterString: string;
  @Input() isSearchView = true;

  constructor(public  mimeHelper: MimeHelper,
              private snackBar: MatSnackBar,
              private storageHelper: StorageHelper,
              private translate: TranslateService,
              private customerService: CustomerService,
              private analyticsService: AnalyticsService,
              private mediaHelper: MediaHelper) {
  }

  public openMedia(itemPath: string): void {
    if (!itemPath) {
      return;
    }

    if (itemPath.length <= 0) {
      return;
    }

    this.analyticsService.sendEvent(AnalyticsType.OPEN_DOCUMENT_FROM_WELCOME, itemPath);
    itemPath.split('.').pop();
    this.mediaHelper.openMedia(itemPath);
  }
}
