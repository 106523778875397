import {Component, ComponentRef, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Subscription} from 'rxjs';

/*COMPONENTS*/
import {LoadingComponent} from '../shared/components/loading/loading.component';
import {AnalyticsType} from '../shared/enums/analytics.enum';
import {TileType} from '../shared/enums/tiletype.enum';

/*HELPERS*/
import {NavigationHelper} from '../shared/helpers/navigation.helper';
import {ThemeHelper} from '../shared/helpers/theme.helper';

/*MODELS*/
import {AppData} from '../shared/models/appdata.model';
import {MediaCategory} from '../shared/models/media-category.model';
import {Media} from '../shared/models/media.model';
import {Service} from '../shared/models/service.model';
import {Tile} from '../shared/models/tile.model';
import {AnalyticsService} from '../shared/services/analytics.service';

/*SERVICES*/
import {CatalogService} from '../shared/services/catalog.service';

@Component({
  templateUrl: 'media.component.html',
  styleUrls  : ['media.component.css']
})

export class MediaComponent implements OnInit {
  set filter(s: string) {
    this.filterString = s;
  }

  @Input() public ownRef: ComponentRef<MediaComponent>;
  @Input() public appComponent: any;
  @Input() public parentPageTitle: string;

  public processSolutions: MediaCategory[] = [];
  public supplyModes: MediaCategory[]      = [];
  public products: MediaCategory[]         = [];
  public mediaProduct: MediaCategory       = new MediaCategory();
  public mediaBeyond: MediaCategory        = new MediaCategory();
  public productCategoryKeys: string[];
  public productCategories: any            = {};
  public selectedProductCategory: string;
  public beyondCategories: any             = {};
  public beyondCategoryKeys: string[]      = [];
  public selectedBeyondCategory: string;
  public isDetail                          = false;
  public filterString                      = '';
  public otherString: string;
  public supplyString: string;
  public processString: string;
  public gasString: string;
  public beyondString: string;
  public mediaCategory: MediaCategory      = new MediaCategory();
  public mediasTitle: string               = null;
  private loadingRef: any                  = null;
  private readonly routerSub: Subscription = null;

  public constructor(public translate: TranslateService,
                     private catalogService: CatalogService,
                     private snackBar: MatSnackBar,
                     private themeHelper: ThemeHelper,
                     private router: Router,
                     private navigationHelper: NavigationHelper,
                     private analyticsService: AnalyticsService,
                     private dialog: MatDialog) {

    this.navigationHelper.initMediaComponent()
      .subscribe(_ => {
        this.appComponent.mediaShown = false;
        this.themeHelper.setPageTitle(this.parentPageTitle);
        if (this.routerSub) {
          this.routerSub.unsubscribe();
        }
        this.ownRef.destroy();
      });

    this.routerSub = router.events.subscribe(_ => {
      this.appComponent.mediaShown = false;
      this.themeHelper.setPageTitle(this.parentPageTitle);
      this.navigationHelper.mediaComponentObserver = null;
      this.routerSub.unsubscribe();
      this.ownRef.destroy();
    });

    this.analyticsService.sendEvent(AnalyticsType.OPEN_VIEW, '/media');

    this.showLoadingDialog(true);

    this.translate.get('other')
      .subscribe((res: string) => {
        this.otherString = res;
      });
    this.translate.get('process_solution')
      .subscribe((res: string) => {
        this.processString = res;
      });
    this.translate.get('premium_gas')
      .subscribe((res: string) => {
        this.gasString = res;
      });
    this.translate.get('supply_modes')
      .subscribe((res: string) => {
        this.supplyString = res;
      });
    this.translate.get('beyond_gases')
      .subscribe((res: string) => {
        this.beyondString = res;
      });

    setTimeout(
      () => {
        this.catalogService.fetchCurrentData()
          .then(data => {
            this.showLoadingDialog(false);
            this.processSolution(data);
            this.processProducts(data);
            this.processSupplyMode(data);
            this.processBeyond(data);
          })
          .catch((err) => {
            this.showLoadingDialog(false);
            this.snackBar.open('MediaComponent : constructor -> ' + err, 'x',
              {
                duration: 5000
              });
            throw new Error(err);
          });
      }, 500);
  }

  public ngOnInit(): void {
    this.themeHelper.setPageTitle(this.translate.instant('media'));
  }

  public selectProcessSolution(item: MediaCategory): void {
    this.translate.get('process_solution')
      .subscribe((res: string) => {
        this.openMediaDetail(res, item);
      });
  }

  public selectProductCategory(key: string): void {
    this.selectedProductCategory = key;

    this.navigationHelper.initMediaCategoryObserver()
      .subscribe(_ => {
        this.selectedProductCategory = null;
      });
  }

  public selectProducts(item: MediaCategory): void {
    this.translate.get('premium_gas')
      .subscribe((root: string) => {
        this.translate.get(this.selectedProductCategory)
          .subscribe((gas: string) => {
            this.openMediaDetail(root + ' > ' + gas, item);
          });
      });
  }

  public selectMediaProduct(): void {
    this.translate.get('premium_gas')
      .subscribe((res: string) => {
        this.openMediaDetail(res, this.mediaProduct);
      });
  }

  public selectMediaBeyond(): void {
    this.translate.get('beyond_gases')
      .subscribe((res: string) => {
        this.openMediaDetail(res, this.mediaBeyond);
      });
  }

  public selectSupplyMode(item: MediaCategory): void {
    this.translate.get('supply_modes')
      .subscribe((res: string) => {
        this.openMediaDetail(res, item);
      });
  }

  public selectBeyond(item: MediaCategory): void {
    this.translate.get('beyond_gases')
      .subscribe((res: string) => {
        this.openMediaDetail(res, item);
      });
  }

  public selectBeyondCategories(key: string): void {
    this.selectedBeyondCategory = key;
    this.navigationHelper.initMediaCategoryObserver()
      .subscribe(_ => {
        this.selectedBeyondCategory = null;
      });
  }

  public topTileIsActive(list: any[], key: string): boolean {
    if (!list || !key) {
      return false;
    }

    const items = list[key];

    if (!items) {
      return false;
    }

    for (const mediaCategory of items) {
      if (!mediaCategory.Medias) {
        continue;
      }

      if (mediaCategory.Medias.length > 0) {
        return true;
      }
    }

    return false;
  }

  private openMediaDetail(title: string, item: MediaCategory): void {
    this.mediasTitle   = title;
    this.mediaCategory = item;
    this.isDetail      = true;
    this.navigationHelper.initMediaDetailObservableComponent()
      .subscribe(res => {
        this.isDetail = res;
      });
  }

  /*INFO: PROCESS SOLUTIONS*/
  private processSolution(data: AppData): void {
    if (data.applications === undefined) {
      return;
    }

    const objKeys: any[] = Object.keys(data.applications);
    const apps: any[]    = [];

    objKeys.forEach(key => {
      const application = data.applications[key];

      if (application !== null && application !== undefined) {
        apps.push(application);
      }
    });
    this.processSolutionItems(apps);

    this.translate.get('other')
      .subscribe((res: string) => {
        const mediaCategory = new MediaCategory();
        mediaCategory.Name  = res;
        for (const doc of data.mediaDocument.mediaProcessSolution) {
          this.processPdf(doc, mediaCategory);
        }

        if (mediaCategory.Medias !== undefined && mediaCategory.Medias.length === 0) {
          return;
        }

        this.processSolutions.push(mediaCategory);
      });
  }

  private processSolutionItems(apps: any[]): void {
    let mediaCategory: MediaCategory = null;

    apps.forEach(
      item => {
        if (item.pages !== undefined) {
          const pageKeys = Object.keys(item.pages);
          pageKeys.forEach(key => {
            const page = item.pages[key];

            if (page.offer !== undefined) {
              mediaCategory      = new MediaCategory();
              mediaCategory.Name = page.title;

              this.processOffer(page.offer, mediaCategory);

              this.mergeMedias(this.processSolutions, mediaCategory);
            }
          });
        }
      });
  }

  /*INFO: GAS MODES*/
  private processProducts(data: AppData): void {
    if (data.products === undefined) {
      return;
    }

    const objKeys: any[]  = Object.keys(data.products);
    const products: any[] = [];

    objKeys.forEach(key => {
      const product = data.products[key];

      if (product !== null && product !== undefined) {
        products.push(product);
      }
    });

    this.processProductsItems(products);

    this.translate.get('other')
      .subscribe((res: string) => {
        const mediaCategory = new MediaCategory();
        mediaCategory.Name  = res;
        for (const doc of data.mediaDocument.mediaPremiumGas) {
          this.processPdf(doc, mediaCategory);
        }

        if (mediaCategory.Medias !== undefined && mediaCategory.Medias.length === 0) {
          this.mediaProduct = null;
          return;
        }

        this.mediaProduct = mediaCategory;
      });
  }

  private processProductsItems(products: any[]): void {
    products.forEach(item => {
      const mediaCategory = new MediaCategory();
      mediaCategory.Name  = item.title;

      for (const doc of item.productPdfs) {
        this.processPdf(doc, mediaCategory);
      }

      for (const doc of item.equipmentPdfs) {
        this.processPdf(doc, mediaCategory);
      }

      this.mergeMedias(this.products, mediaCategory);
    });

    this.products = this.products.filter((product) => product.Medias.length > 0);

    /*
    FIXME:
    TODO:
    OBSOLETE:
    NOTE:
    INFO:
    I did not approve of any of this!
    I strongly and firmly let be known that it is bullshit
    */
    const gasNames = [
      'arcal',
      'alphagaz',
      'aligal',
      'calgaz',
      'flamal',
      'fuel1',
      'fuel2',
      'fuel3',
      'fuel4',
      'lasal',
      'phargalis',
      'acetylene',
      'ammonia',
      'argon',
      'helium',
      'co2',
      'co',
      'h2',
      'n2',
      'o2'
    ];

    const observables = [];

    for (const gas of gasNames) {
      observables.push(this.translate.get(gas));
    }

    forkJoin(observables)
      .subscribe((gasStrings: string[]) => {
        this.products.map((product) => {
          for (const gas of gasStrings) {
            if (product.Name.trim().toLowerCase().includes(gas.trim().toLowerCase())) {
              if (!this.productCategories[gas]) {
                this.productCategories[gas] = [];
              }
              this.productCategories[gas].push(product);
              return;
            }
          }
        });

        this.productCategoryKeys =
          Object.keys(this.productCategories).sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
      });
  }

  /*INFO: SUPPLY MODES*/
  private processSupplyMode(data: AppData): void {
    if (data.supplyModes === undefined) {
      return;
    }

    const objKeys: any[]     = Object.keys(data.supplyModes);
    const supplyModes: any[] = [];

    objKeys.forEach(key => {
      const supplyMode = data.supplyModes[key];

      if (supplyMode !== null && supplyMode !== undefined) {
        supplyModes.push(supplyMode);
      }

    });

    this.processSupplyModeItems(supplyModes);

    this.translate.get('other')
      .subscribe((res: string) => {
        const mediaCategory = new MediaCategory();
        mediaCategory.Name  = res;
        for (const doc of data.mediaDocument.mediaSupplyModes) {
          this.processPdf(doc, mediaCategory);
        }

        if (mediaCategory.Medias !== undefined && mediaCategory.Medias.length === 0) {
          return;
        }

        this.supplyModes.push(mediaCategory);
      });
  }

  private processSupplyModeItems(supplyModes: any[]): void {
    supplyModes.forEach(
      item => {
        if (item.offer !== undefined) {
          const mediaCategory = new MediaCategory();
          mediaCategory.Name  = item.title;

          this.processOffer(item.offer, mediaCategory);

          this.mergeMedias(this.supplyModes, mediaCategory);
        }
      });

    this.supplyModes = this.supplyModes.filter((supply) => supply.Medias.length > 0);
  }

  /*INFO: BEYOND*/
  private processBeyond(data: AppData): void {
    if (data.beyondGasTiles === undefined) {
      return;
    }

    const beyondTiles: Tile[] = Object.keys(data.beyondGasTiles)
      .map((key) => data.beyondGasTiles[key])
      .filter((tile) => tile.type === TileType.Beyond);

    this.processBeyondItems(beyondTiles, data);

    this.translate.get('other')
      .subscribe((res: string) => {
        const mediaCategory = new MediaCategory();
        mediaCategory.Name  = res;
        for (const doc of data.mediaDocument.mediaBeyond) {
          this.processPdf(doc, mediaCategory);
        }

        if (mediaCategory.Medias !== undefined && mediaCategory.Medias.length === 0) {
          this.mediaBeyond = null;
          return;
        }
        this.mediaBeyond = mediaCategory;
      });
  }

  private processBeyondItems(beyondTiles: any[], data: AppData): void {
    beyondTiles.forEach(
      tile => {
        this.beyondCategories[tile.tileBeyond.text] = [];
        const serviceTiles                          = tile.tileBeyond.tiles.filter(t => t.type === TileType.Service);
        if (serviceTiles.length > 0) {
          serviceTiles.forEach(serviceTile => {
            const service       = data.services[serviceTile.tileService.id];
            const mediaCategory = new MediaCategory();
            mediaCategory.Name  = service.name;

            this.processService(service, mediaCategory);
            if (mediaCategory.Medias.length > 0) {
              this.mergeMedias(this.beyondCategories[tile.tileBeyond.text], mediaCategory);
            }
          });
        }
      });

    this.beyondCategories   = Object.keys(this.beyondCategories)
      .filter(key => this.beyondCategories[key].length > 0)
      .reduce((res, key) => (res[key] = this.beyondCategories[key], res), {});
    this.beyondCategoryKeys =
      Object.keys(this.beyondCategories).sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
  }

  private processService(service: Service, mediaCategory: MediaCategory): void {
    service.documents.forEach((doc) => this.processPdf(doc, mediaCategory));
  }

  /*INFO: COMMON*/
  private processOffer(offer: any, mediaCategory: MediaCategory): void {
    if (offer.defaultDetail !== undefined) {
      for (const slide of offer.defaultDetail.slides) {
        for (const doc of slide.documents) {
          this.processPdf(doc, mediaCategory);
        }
      }
    }

    if (offer.details !== undefined) {
      for (const detail of offer.details) {
        for (const slide2 of detail.slides) {
          for (const doc2 of slide2.documents) {
            this.processPdf(doc2, mediaCategory);
          }
        }
      }
    }
  }

  private processPdf(doc: any, mediaCategory: MediaCategory): void {
    if (doc === undefined || mediaCategory.Medias.some(m => m.Id === doc.id)) {
      return;
    }

    const media = new Media();

    media.Id   = doc.id.toString();
    media.Name = doc.displayName;
    media.Code = doc.path;
    media.Url  = doc.serverUrl;

    mediaCategory.Medias.push(media);
  }

  private showLoadingDialog(open: boolean): void {
    if (!open && this.loadingRef !== null) {
      this.loadingRef.close();
      this.loadingRef = null;
      return;
    }

    this.loadingRef = this.dialog.open(LoadingComponent, {disableClose: true});
  }

  private mergeMedias(items: MediaCategory[], mediaCategory: MediaCategory): void {
    const foundedItem = items.find(itm => itm.Name === mediaCategory.Name);
    if (foundedItem === undefined) {
      items.push(mediaCategory);
    } else {
      foundedItem.Medias.concat(mediaCategory.Medias);
    }
    items.sort((a, b) => a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase()));
  }
}
