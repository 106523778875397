// eslint-disable-next-line no-shadow
export enum TileType {
  Unknown        = 0,
  Text           = 1,
  TextBackground = 2,
  Video          = 3,
  Picture        = 4,
  Service        = 5,
  Offer          = 6,
  Product        = 7,
  Brand          = 8,
  Web            = 9,
  Application    = 10,
  Beyond         = 11
}
