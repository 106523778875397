export class Catalog {
  CountryId: string;
  LanguageId: string;

  public static New(key: string, value: string): Catalog {
    const catalog: Catalog = new Catalog();

    catalog.CountryId  = key;
    catalog.LanguageId = value;

    return catalog;
  }
}
