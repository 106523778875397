import {Injectable} from '@angular/core';
import {User} from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserHelper {
  private static readonly USER_KEY = 'user';

  constructor() {
  }

  public saveUser(user: any): void {
    const savedUser: User = new User();
    savedUser.UserName    = user.UserName;
    savedUser.Email       = user.Email;

    localStorage.setItem(UserHelper.USER_KEY, JSON.stringify(savedUser));
  }

  public getConnectedUser(): User {
    return JSON.parse(localStorage.getItem(UserHelper.USER_KEY));
  }

  public removeConnectedUser(): void {
    localStorage.removeItem(UserHelper.USER_KEY);
  }
}
