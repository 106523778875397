<div class="component_content">
  <div class="ui-g">
    <div
      [@applicationSelected]="!!selectedApplication"
      class="ui-g-4">
      <div class="card-header" [@hideHeader]="marketState">
        <h3>{{'list_header_markets' | translate}}</h3>
        <span class="middle"></span>
        <h3>{{markets.length}}</h3>
      </div>
      <div class="grid-container">
        <mat-card
          *ngFor="let market of markets"
          class="square market"
          [@expandMarket]="market.$$state"
          (click)="openMarket(market)">
          <img class="market-bg" [src]="market.backgroundPath | fromStorage" alt="market"/>
          <div class="back-process">
            <img *ngIf="market.$$state === 1"
                 src="./assets/icons/back.png" alt="back"/>
          </div>
          <img class="blue-filter" src="./assets/images/blue_background.png" alt="background"/>
          <mat-card-content>
            <div class="square-content market-content">
              <img [src]="market.logoPath | fromStorage" alt="marketLogo"/>
              <div class="market-name">{{market.name | uppercase}}</div>
              <div class="market-segment">
                <span class="market-segment-number">{{market.segments.length}}</span>
                <span class="market-segment-text">{{'market_segments' | translate}}</span>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div
      class="market_container"
      [@expandMarketColumn]="marketState"
      [@applicationSelected]="!!selectedApplication">
      <div
        class="ui-g-4 markets-tile"
        *ngFor="let segment of selectedMarket?.segments">
        <mat-card
          (click)="goToSegment(segment)"
          [ngStyle]="{'background-image': selectedMarket.getGradient(),
                      'height': '250px'}">
          <img class="foreground" src="./assets/images/segment_foreground.png" alt="segmentLogo"/>
          <mat-card-content>
            <img class="segment_image" [src]="segment.logoPath | fromStorage" alt="segment"/>
            <div class="segment_title">
              <h4>{{segment.name}}</h4>
            </div>
            <button
              class="play"
              mat-mini-fab>
              <img src="./assets/icons/ic_play.png" alt="play">
            </button>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="ui-g-4">
      <div class="card-header">
        <div *ngIf="!isSearch">
          <h3>{{'list_header_applications' | translate}}</h3>
        </div>
        <span class="middle" *ngIf="!isSearch"></span>
        <div *ngIf="!isSearch">
          <h3>{{(applications | andFilter:{name: filterProcess}).length}}</h3>
        </div>
        <mat-form-field
          class="search-process"
          *ngIf="isSearch">
          <label for="focus"></label>
          <input
            id="focus"
            matInput
            autofocus
            [formControl]="filterInput"
            placeholder="{{'SEARCH' | translate}}">
        </mat-form-field>
        <button
          mat-icon-button
          class="search_button"
          (click)="activeSearch()">
          <mat-icon>search</mat-icon>
        </button>
      </div>
      <div class="card-container" appVscroll>
        <div
          #applicationElm
          *ngFor="let application of applications | andFilter:{name: filterProcess}"
          (click)="openApplication(application)"
          class="application">
          <mat-card class="process_card">
            <mat-card-content>
              <span>{{application.name}}</span>
              <img src="./assets/icons/ic_arrow.png" alt="go"/>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
    <div
      class="ui-g-4"
      [@applicationSelected]="!!selectedApplication">
      <div class="card-header">
        <h3>{{'list_header_focus' | translate}}</h3>
        <span class="middle"></span>
        <h3>{{focuses.length}}</h3>
      </div>
      <mat-card class="focuses_carousel">
        <app-carousel [items]="focuses">
          <ng-template let-item="item">
            <a class="focus" (click)="openFocus(item)">
              <div class='ui-g-6'>
                <div class="focus_sides">
                  <img [src]="item.picturePath | fromStorage" alt="focus"/>
                </div>
              </div>
              <div class='ui-g-6'>
                <div class="focus_sides">
                  <div class="focus_right_side">
                    <h3>{{item.getTitle(currentData)}}</h3>
                    <h3>{{'read_more' | translate}}</h3>
                  </div>
                </div>
              </div>
            </a>
          </ng-template>
        </app-carousel>
      </mat-card>
      <mat-card class="supply-mode" (click)='gotToSupplyModes()'>
        <img class="supply-mode-image" src="./assets/images/supply_mode_btn_bg.png" alt="truck"/>
        <div class="supply-mode-text">
          <span>{{'supply_modes' | translate | uppercase}}</span>
          <span>{{'read_more' | translate}}</span>
        </div>
      </mat-card>
      <div class="static-container">
        <mat-card>
          <div class="static" (click)="openAboutAl()">
            <img src="./assets/icons/about.png" alt="about"/>
            <span>{{'about_al' | translate}}</span>
            <img src="./assets/icons/ic_arrow_blue.png" alt="go"/>
          </div>
        </mat-card>
        <mat-card>
          <div class="static" (click)='gotToBeyondGases()'>
            <img src="./assets/icons/beyond_gases.png" alt="beyondGases"/>
            <span>{{'beyond_gases' | translate}}</span>
            <img src="./assets/icons/ic_arrow_blue.png" alt="go"/>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
