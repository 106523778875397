import {ArrayTyper} from '@caliatys/array-typer';
import {Segment} from './segment.model';


export class Market {
  id: number;
  name: string;
  logoPath: string;
  backgroundPath: string;
  startColor: number;
  endColor: number;
  segments: Segment[];

  constructor(json: any) {
    if (!json) {
      return null;
    }
    this.id             = json.Id;
    this.name           = json.Name;
    this.logoPath       = json.LogoPath;
    this.backgroundPath = json.BackgroundPath;
    this.startColor     = json.StartColor;
    this.endColor       = json.EndColor;
    this.segments       = ArrayTyper.asArray(Segment, json.Segments || []).sort((a, b) => a.name.localeCompare(b.name));
  }

  getGradient(): string {
    return `radial-gradient(circle at bottom right , ${this.startColor}, ${this.endColor})`;
  }

  getSegmentCount(): number {
    return Object.keys(this.segments).length;
  }
}
