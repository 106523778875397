import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';
import {timer} from 'rxjs';
import {AppComponent} from '../app.component';

import {LoadingComponent} from '../shared/components/loading/loading.component';
import {AnalyticsType} from '../shared/enums/analytics.enum';

import {PreloadingStrategyHelper} from '../shared/helpers/preloading-strategy.helper';
import {StorageHelper} from '../shared/helpers/storage.helper';
import {ThemeHelper} from '../shared/helpers/theme.helper';
import {ZipHelper} from '../shared/helpers/zip.helper';
import {Catalog} from '../shared/models/catalog.models';
import {Country} from '../shared/models/country.model';

import {Language} from '../shared/models/language.model';
import {AnalyticsService} from '../shared/services/analytics.service';
import {CatalogService} from '../shared/services/catalog.service';
import {SynchronizeService} from '../shared/services/synchronize.service';
import {DownloadDialogComponent} from './download/download.component';
import {SelectLangDialogComponent} from './select-lang/select-lang.component';

@Component({
  templateUrl: 'synchronize.component.html',
  styleUrls  : ['synchronize.component.css'],
})

export class SynchronizeComponent {
  public countries: Country[];
  public files: string[]          = [];
  public languages: any[]         = [];
  public currentLang: string      = null;
  public updateNumber             = 0;
  public states: any[]            = [];
  private loadingRef: any         = null;
  private activatedLang: string[] = [];

  constructor(public  storageHelper: StorageHelper,
              public  dialog: MatDialog,
              public  syncService: SynchronizeService,
              private themeHelper: ThemeHelper,
              private catalogService: CatalogService,
              private snackBar: MatSnackBar,
              private preloadStrategy: PreloadingStrategyHelper,
              private zipHelper: ZipHelper,
              private parent: AppComponent,
              private router: Router,
              private translate: TranslateService,
              private analyticsService: AnalyticsService) {
    this.parent.activeMenu();

    this.updateTranslation();

    this.analyticsService.sendEvent(AnalyticsType.OPEN_VIEW, this.router.url);

    this.countries     = [];
    this.activatedLang = this.catalogService.getSelLocalLanguages();
    this.currentLang   = this.catalogService.getSelectedLanguage();

    this.processLanguages();

    this.syncService.isNotified = true;
    this.syncService.storeSize  = this.storageHelper.quotaToString();

    timer(1000, 1000)
      .subscribe(() => {
        let updateCounter = 0;

        if (!this.countries) {
          return;
        }

        for (const country of this.countries) {
          for (const lang of country.Languages) {
            let res: Language = null;
            let tmpLang: any  = null;

            tmpLang = lang;

            if (!this.syncService.countries) {
              continue;
            }

            for (const c of this.syncService.countries) {
              res = c.Languages.find(l => l.CountryId === tmpLang.CountryId && l.Id === tmpLang.LanguageId);

              if (!res) {
                continue;
              }

              lang.IsActive  = res.IsActive;
              lang.IsOffline = res.IsOffline;

              if (lang.IsActive && !lang.IsOffline) {
                updateCounter++;
              }
            }
          }
        }
        this.updateNumber = updateCounter;
      });
  }

  public synchronize(): void {
    if (!this.analyticsService.isOnline) {
      this.snackBar.open(this.translate.instant('no_network_connection_toast'), 'x',
        {
          duration: 5000
        });
      return;
    }

    if (this.currentLang === undefined || this.currentLang === null) {
      this.openSelectLangDialog();
      return;
    }

    this.showLoadingDialog(true);

    this.syncService.getAppData(this.processCatalogLanguage())
      .subscribe(data => {
          if (data === undefined) {
            throw new Error('SynchronizeComponent : synchronize -> No Data');
          }

          if (data.Data === undefined) {
            throw new Error('SynchronizeComponent : synchronize -> No Data in data');
          }

          if (data.Assets === undefined) {
            throw new Error('SynchronizeComponent : synchronize -> No Assets in data');
          }

          this.showLoadingDialog(false);

          setTimeout(() => // NOTE: Important fix for ios
          {
            this.openDownloadDialog(data);
          }, 500);
        },
        (e) => {
          this.showLoadingDialog(false);
          if (e.status === 401) {
            this.snackBar.open(this.translate.instant('log_in_again_and_retry'), 'x',
              {
                duration: 5000
              });
            // Redirect to Google auth screen
            this.router.navigate(['/login'], { queryParams: { returnUrl: '/synchronize' }});
          } else {
            this.snackBar.open(this.translate.instant('no_network_connection_toast'), 'x',
              {
                duration: 5000
              });
          }
        });
  }

  public getHeight(row: any): number {
    return row.Languages.length * 70;
  }

  public checkItem(language: Language): void {
    const key = Language.GetUniqueKey(language);

    language.IsOffline = false;
    language.IsActive  = !language.IsActive;

    if (!language.IsActive) {
      this.removeSelectedLanguage(key);
      this.updateCountries();
      this.generateLanguagesLabels();

      return;
    }

    this.activatedLang.push(key);
    this.updateCountries();
    this.generateLanguagesLabels();
  }

  public saveSelectedLanguage(): void {
    this.catalogService.saveSelectedLanguage(this.currentLang);

    const language = this.languages.find(l => l.key === this.currentLang);

    if (language === undefined || language === null) {
      return;
    }

    this.catalogService.saveSelectedLanguageLabel(language.value);

    this.analyticsService.sendEvent(AnalyticsType.INSTANCE_SELECT, language.value);

    this.updateTranslation();
  }

  private updateTranslation(): void {
    this.catalogService.fetchCurrentData()
      .then((data: any) => {
        this.catalogService.setCurrentTranslations(data);
        this.themeHelper.setPageTitle(this.translate.instant('sync'));
      })
      .catch((err: Error) => {
        this.themeHelper.setPageTitle(this.translate.instant('sync'));
        console.error(err);
      });
  }

  private removeSelectedLanguage(key: string): void {
    this.activatedLang = this.activatedLang.filter(item => key !== item);

    this.catalogService.saveSelLanguages(this.activatedLang);

    this.storageHelper.removeDbBlobItem(key);
    this.storageHelper.removeItem(key);

    if (key !== this.currentLang) {
      return;
    }

    this.currentLang = null;
    this.catalogService.saveSelectedLanguage(null);
  }

  private processCatalogLanguage(): Catalog[] {
    const catalog: Catalog[] = [];

    for (const country of this.countries) {
      for (const lang of country.Languages) {
        if (lang.IsActive) {
          catalog.push(Catalog.New(lang.CountryId, lang.Id));
        }
      }
    }

    return catalog;
  }

  private processLanguages(): void {
    this.showLoadingDialog(true);
    setTimeout(() => {
      this.catalogService.getLanguages()
        .subscribe((res) => {
            this.showLoadingDialog(false);
            this.countries = res;
            this.generateLanguagesLabels();
          },
          err => {
            this.showLoadingDialog(false);

            setTimeout(() => {
              this.countries = this.catalogService.getLocalLanguages(); // NOTE: if an error try to get Languages in local storage

              if (this.countries === null) // NOTE: if no local languages throw error
              {
                throw new Error('SynchronizeComponent : exception -> ' + err);
              }

              this.generateLanguagesLabels();
            }, 1000);
          });
    }, 1000);
  }

  private generateLanguagesLabels(): void {
    this.languages = [];

    for (const country of this.countries) {
      for (const lang of country.Languages) {
        const obj: any  = {};
        obj[country.Id] = lang.Id;

        if (lang.IsActive) {
          this.languages.push({value: country.Name + ' ' + lang.Name, key: Language.GetUniqueKey(lang)});
        }
      }
    }
  }

  private showLoadingDialog(open: boolean): void {
    if (!open && this.loadingRef !== null) {
      this.loadingRef.close();
      this.loadingRef = null;
      return;
    }

    this.loadingRef = this.dialog.open(LoadingComponent, {disableClose: true});
  }

  private openSelectLangDialog(): void {
    this.dialog.open(SelectLangDialogComponent,
      {
        data        : {languages: this.languages},
        disableClose: false
      });
  }

  private openDownloadDialog(data: any): void {
    const params    = {assets: data.Assets, manifest: data.Data};
    const dialogRef = this.dialog.open(DownloadDialogComponent,
      {
        data        : params,
        disableClose: true
      });

    dialogRef.afterClosed()
      .subscribe((ok) => {
        if (!ok) {
          return;
        }

        for (const country of this.countries) {
          for (const lang of country.Languages) {
            if (!lang.IsActive) {
              continue;
            }

            lang.IsOffline = true;
          }
        }

        this.updateCountries();
      });
  }

  private updateCountries(): void {
    this.syncService.countries = this.countries;

    this.syncService.refreshUpdateState();
  }
}
