<div class="customers_container">
  <div class="expectation_container">
    <mat-nav-list class="expectation_list">
      <div *ngIf="customer.Expectations.length === 0"
           class="no_data">
        {{ 'NO_DATA' | translate }}
      </div>
      <mat-list-item *ngFor="let expectation of customer.Expectations">
        <div class="expectation_process">
          <p>{{ expectation.Process }}</p>
        </div>
        <div class="expectation_label">
          <p>
            <span *ngFor="let exp of expectation.Expectations">{{exp}}</span>
          </p>
        </div>
        <button
          mat-icon-button
          class="delete_media button_grey"
          (click)="openRemoveExpectation(expectation)">

          <i class="far fa-trash-alt fa-2x"></i>
        </button>
      </mat-list-item>
    </mat-nav-list>
  </div>
  <div class="media_container">
    <div *ngIf="customer.Medias.length === 0"
         class="no_data">
      {{ 'NO_DATA' | translate }}
    </div>
    <mat-nav-list class="media_list">
      <mat-list-item
        *ngFor="let media of customer.Medias">
        <img [src]="mimeHelper.getIconFromFileName(media.Code)" alt="fileType"/>
        <div class="media_label"
             (click)="openMedia(media.Code)">
          <p>{{ media.Name }}</p>
        </div>
        <button
          mat-icon-button
          class="delete_media"
          (click)="openRemoveMedia(media)"
          [ngClass]="{'button_red' : selId === media.Id,
                   'button_grey': selId !== media.Id}">
          <i class="far fa-trash-alt fa-2x"></i>
        </button>
      </mat-list-item>
    </mat-nav-list>
  </div>
</div>
