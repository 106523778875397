import {FocusType} from '../enums/focustype.enum';
import {PageType} from '../enums/pagetype.enum';
import {AppData} from './appdata.model';
import {Page} from './page.model';

export class Focus {
  targetId: number;
  type: FocusType;
  picturePath: string;
  title: string;
  seeMoreAt: string;
  supplyModes: any;

  constructor(json: any) {
    if (!json) {
      return null;
    }

    this.targetId    = json.TargetId;
    this.type        = json.Type;
    this.picturePath = json.PicturePath;
    this.supplyModes = json.SupplyModes;
  }

  public static initBrand(data: AppData, id: any, callback: any): void // INFO: Is static for focus.model usage
  {
    let page: any = null;


    for (const application of
      Object.keys(data.applications)
        .map(key => data.applications[key])) {
      const pages = Object.keys(application.pages).map(key => application.pages[key]);

      if (pages.some(p => p.id === id)) {
        const foundedPage = pages.find(p => id === p.id);

        if (foundedPage) {
          page = foundedPage;
        }
      }
    }

    callback({
      tiles: page.tiles,
      title: page.title,
      id   : page.id
    });
  }

  getTitle(data: AppData): string {
    if (this.title === undefined) {
      this.init(data);
    }
    return this.title;
  }

  private init(data: AppData): void {
    switch (this.type) {
      case 'product':
        this.title     = data.products[this.targetId].title;
        this.seeMoreAt = '/product';
        break;
      case 'supply':
        this.title     = data.supplyModes[this.targetId].title;
        this.seeMoreAt = '/supplymode';
        break;
      case 'application':
        const application     = data.applications[this.targetId];
        const offerPageIndex  = Object.keys(application.pages)
          .find(key => application.pages[+key].type === PageType.Offer);
        const offerPage: Page = application.pages[offerPageIndex];
        this.title            = offerPage.title;
        this.seeMoreAt        = '/offer';
        break;
      case 'service':
        this.title     = data.services[this.targetId].name;
        this.seeMoreAt = '/service';
        break;
      case 'freefocus':
        this.title     = data.freeFocuses[this.targetId].name;
        this.seeMoreAt = '/freefocus';
        break;
      case 'brand':
        Focus.initBrand(data, this.targetId,
          (processedData) => {
            this.title = processedData.title;
          });
        this.seeMoreAt = '/brand';
        break;
    }
  }
}
