import {Component} from '@angular/core';
import {Router} from '@angular/router';

/*EXTERNAL LIBRARIES*/
import {TranslateService} from '@ngx-translate/core';

/*COMPONENTS*/
import {AppComponent} from '../app.component';

/*ENUMS*/
import {AnalyticsType} from '../shared/enums/analytics.enum';

/*HELPERS*/
import {NavigationHelper} from '../shared/helpers/navigation.helper';
import {ThemeHelper} from '../shared/helpers/theme.helper';

/*SERVICES*/
import {AnalyticsService} from '../shared/services/analytics.service';

@Component({
  templateUrl: 'brand.component.html',
  styleUrls  : ['brand.component.css'],
})

export class BrandComponent {
  public tiles: any = null;

  public constructor(private navigationHelper: NavigationHelper,
                     private themeHelper: ThemeHelper,
                     private translate: TranslateService,
                     private parent: AppComponent,
                     private analyticsService: AnalyticsService,
                     private router: Router) {
    this.parent.activeMenu();

    this.analyticsService.sendEvent(AnalyticsType.OPEN_VIEW, this.router.url);

    // this.themeHelper.setPageTitle(this.translate.instant('brand')); //FIXME: set the page title

    this.tiles = this.navigationHelper.routerData.tiles;

    if (this.tiles === undefined || this.tiles === null) // INFO: User refresh page case
    {
      this.goToHome();
      return;
    }

    this.navigationHelper.routerData = null;
  }

  public goToHome(): void {
    this.parent.goToHome();
  }
}
