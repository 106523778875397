<div class="carousel-container">
  <div class="carousel"
       [ngClass]="{'navTop': navTop, 'carousel-transition': !transitionBlocked}"
       [ngStyle]="{'width.%': (100 * items?.length).toString(), 'right.%': (100 * index).toString()}"
       (touchstart)="swipe($event, touchEventAction.Start)"
       (touchend)="swipe($event, touchEventAction.End, swipeResult)">
    <div class="carousel-item"
         [ngStyle]="{
          'width.%': (100/items.length).toString(),
          'paddingTop.px': (30*intNavTop).toString(),
          'paddingBottom.px': (30*intNavBottom).toString()
          }"
         *ngFor="let item of items; let i = index;">
      <ng-container
        *ngIf=displayContent(i)
        [ngTemplateOutletContext]="{item: item}"
        [ngTemplateOutlet]="templateRef"></ng-container>
    </div>
  </div>
  <i
    *ngIf="displayNext()"
    (click)="next()"
    class="fas fa-chevron-circle-right right"
    [ngClass]="{'navTop': navTop}"
    aria-hidden="true"></i>
  <i
    *ngIf="displayPrev()"
    (click)="prev()"
    class="fas fa-chevron-circle-left left"
    [ngClass]="{'navTop': navTop}"
    aria-hidden="true"></i>
  <div
    *ngIf="items?.length > 1"
    class="dots"
    [ngClass]="{'navTop': navTop}">
    <i *ngFor="let item of items; let i = index"
       class="far"
       [ngClass]="{'fa-circle': i !== index, 'fa-dot-circle': i === index}"
       (click)="setIndex(i)"></i>
  </div>
</div>
