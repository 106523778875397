import {ApplicationRef, ComponentFactoryResolver, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {AppComponent} from '../../app.component';
import {LoadingComponent} from '../components/loading/loading.component';
import {VideoFullscreenComponent} from '../components/video-fullscreen/video-fullscreen.component';
import {AnalyticsType} from '../enums/analytics.enum';
import {MediaType} from '../enums/media-type.enum';
import {AnalyticsService} from '../services/analytics.service';
import {MimeHelper} from './mime.helper';
import {StorageHelper} from './storage.helper';

@Injectable({
  providedIn: 'root'
})
export class MediaHelper {
  public loadingRef: any = null;

  public constructor(private appRef: ApplicationRef,
                     private componentFactoryResolver: ComponentFactoryResolver,
                     private storageHelper: StorageHelper,
                     private dialog: MatDialog,
                     private translate: TranslateService,
                     private analyticsService: AnalyticsService,
                     private snackBar: MatSnackBar,
                     private mimeHelper: MimeHelper) {
  }

  public openMedia(mediaPath: string): void {
    if (mediaPath === undefined || mediaPath === null) {
      this.snackBar.open(this.translate.instant('FILE_NOT_FOUND'), 'x',
        {
          duration: 5000
        });
      return;
    }

    this.analyticsService.sendEvent(AnalyticsType.OPEN_DOCUMENT, mediaPath);

    this.showLoadingDialog(true);
    const mimeType = MimeHelper.lookup(mediaPath.split('.').pop());
    mediaPath      = StorageHelper.normalizeKey(mediaPath);
    (
      this.openWebWebViewer(mediaPath, mimeType)
    )
      .catch(console.error)
      .finally(() => this.showLoadingDialog(false));

  }

  private openWebWebViewer(mediaPath: string, mimeType: string): Promise<void> {
    return this.storageHelper.getBlobDbItem(mediaPath, mimeType)
      .then(value => {
        if (!value) {
          this.snackBar.open(this.translate.instant('FILE_NOT_FOUND'), 'x',
            {
              duration: 5000
            });
          return;
        }

        const type = this.mimeHelper.getMediaType(mediaPath);
        if (type === MediaType.Video) {
          const factory       = this.componentFactoryResolver.resolveComponentFactory(VideoFullscreenComponent);
          const ref           = (this.appRef.components[0].instance as AppComponent).viewRef.createComponent(factory);
          ref.instance.path   = mediaPath;
          ref.instance.ownRef = ref;
          ref.changeDetectorRef.detectChanges();
          return;
        }

        const a  = document.createElement('a');
        a.href   = URL.createObjectURL(value);
        a.target = '_blank';
        a.click();
      });
  }

  private showLoadingDialog(open: boolean): void {
    if (!open && this.loadingRef !== null) {
      this.loadingRef.close();
      this.loadingRef = null;
      return;
    }

    this.loadingRef = this.dialog.open(LoadingComponent, {disableClose: true});
  }
}
