import {Component, ComponentFactoryResolver, ElementRef, ViewChild, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {CustomerComponent} from './customer/customer.component';
import {GasComponent} from './gas/gas.component';
import {MediaComponent} from './media/media.component';
import {NavigationHelper} from './shared/helpers/navigation.helper';
import {ThemeHelper} from './shared/helpers/theme.helper';
import {AnalyticsService} from './shared/services/analytics.service';
import {CatalogService} from './shared/services/catalog.service';
import {SynchronizeService} from './shared/services/synchronize.service';

@Component({
  selector   : 'app-root',
  templateUrl: './app.component.html',
  styleUrls  : ['./app.component.css']
})
export class AppComponent {
  @ViewChild('filter') filter: ElementRef;

  public dataSource: any    = null;
  public appTitle: string   = null;
  public appVersion: string = null;
  public media              = false;
  public mediaShown         = false;
  public gas                = false;
  public gasShown           = false;
  public customerShown      = false;
  public customer           = 0;
  public search             = false;
  public showSearch         = false;
  public showDetail         = false;
  public showMenu           = false;
  public data: any          = null;
  public pageType           = -1;
  public height             = 0;
  public width              = 0;
  public navStack: any[]    = [];
  public observer: any;
  public appComponent: any  = this;

  constructor(public viewRef: ViewContainerRef,
              public router: Router,
              public analyticsService: AnalyticsService,
              public themeHelper: ThemeHelper,
              public syncService: SynchronizeService,
              public navigationHelper: NavigationHelper,
              private catalogService: CatalogService,
              private translate: TranslateService,
              private componentFactoryResolver: ComponentFactoryResolver) {

    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.appTitle   = environment.appName;
    this.appVersion = environment.version;
  }

  public activeSearch(): void {
    this.search = !this.search;

    this.filter.nativeElement.value = '';
    this.dataSource.filter          = '';

    fromEvent(this.filter.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe(() => {
        if (!this.dataSource) {
          return;
        }

        this.dataSource.filter = this.filter.nativeElement.value;
      });

    setTimeout(() => {
      document.getElementById('focus-global').focus();
    }, 500);

    this.filter.nativeElement.focus();
  }

  public activeMenu(): void {
    if (this.router.url.indexOf('infography') !== -1) {
      this.hideMenu();
      return;
    }
    this.showMenu = true;
  }

  public hideMenu(): void {
    this.showMenu = false;
  }

  public clickOnLogo(): void {
    this.goToHome();

    if (this.observer === undefined || this.observer === null) {
      return;
    }

    this.observer.next(false);
  }

  public initClickOnLogo(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.observer = observer;
    });
  }

  public showLogo(): boolean {
    return this.router.url === '/home' && !this.mediaShown && !this.customerShown && !this.gasShown;
  }

  public goToHome(): void {

    const isSync: boolean = this.syncService.getLastUpdateDate() !== 'none';
    this.navStack         = [];
    this.hideDetail();

    if (!isSync) {
      this.goToSync();
      return;
    }

    if (this.navigationHelper.customerComponentObserver) {
      this.navigationHelper.customerComponentObserver.next();
    }
    this.navigationHelper.customerComponentObserver = null;

    if (this.navigationHelper.mediaDetailObserver) {
      this.navigationHelper.mediaDetailObserver.next();
    }
    this.navigationHelper.mediaDetailObserver = null;

    if (this.navigationHelper.mediaObserver) {
      this.navigationHelper.mediaObserver.next();
    }
    this.navigationHelper.mediaObserver = null;

    if (this.navigationHelper.mediaComponentObserver) {
      this.navigationHelper.mediaComponentObserver.next();
    }
    this.navigationHelper.mediaComponentObserver = null;

    if (this.navigationHelper.gasDetailComponentObserver) {
      this.navigationHelper.gasDetailComponentObserver.next();
    }
    this.navigationHelper.gasDetailComponentObserver = null;

    if (this.navigationHelper.gasComponentObserver) {
      this.navigationHelper.gasComponentObserver.next();
    }
    this.navigationHelper.gasComponentObserver = null;

    this.navigationHelper.selectedMarketId = null;
    this.router.navigate(['/home'])
      .catch(console.error);

  }

  public goToCustomer(): void {

    const isSync: boolean = this.syncService.getLastUpdateDate() !== 'none';
    if (!isSync) {
      this.goToSync();
      return;
    }

    if (this.router.url !== '/signature' &&
      this.router.url !== '/welcome' &&
      this.router.url !== '/synchronize') {
      if (!this.customerShown) {
        this.customerShown           = true;
        const factory                = this.componentFactoryResolver.resolveComponentFactory(CustomerComponent);
        const ref                    = this.viewRef.createComponent(factory);
        ref.instance.ownRef          = ref;
        ref.instance.appComponent    = this.appComponent;
        ref.instance.parentPageTitle = this.themeHelper.pageTitle;
        ref.instance.isReadOnly      = true;
        ref.instance.showDetail      = true;
        ref.changeDetectorRef.detectChanges();
        this.activeMenu();
      }
    } else {
      this.customerShown           = true;
      const factory                = this.componentFactoryResolver.resolveComponentFactory(CustomerComponent);
      const ref                    = this.viewRef.createComponent(factory);
      ref.instance.ownRef          = ref;
      ref.instance.appComponent    = this.appComponent;
      ref.instance.parentPageTitle = this.themeHelper.pageTitle;
      ref.instance.isReadOnly      = false;
      ref.instance.showDetail      = false;
      ref.changeDetectorRef.detectChanges();
      this.activeMenu();
      this.setDataSource(ref.instance);
    }
  }

  public goToMedia(): void {

    const isSync: boolean = this.syncService.getLastUpdateDate() !== 'none';
    if (!isSync) {
      this.goToSync();
      return;
    }

    if (!this.mediaShown) {
      this.gasShown                = false;
      this.mediaShown              = true;
      const factory                = this.componentFactoryResolver.resolveComponentFactory(MediaComponent);
      const ref                    = this.viewRef.createComponent(factory);
      ref.instance.ownRef          = ref;
      ref.instance.appComponent    = this.appComponent;
      ref.instance.parentPageTitle = this.themeHelper.pageTitle;
      ref.changeDetectorRef.detectChanges();
      this.activeMenu();
      this.setDataSource(ref.instance);
    }
  }

  public goToGas(fromInfography: boolean = false): void {
    const isSync: boolean = this.syncService.getLastUpdateDate() !== 'none';
    if (!isSync) {
      this.goToSync();
      return;
    }

    if (!this.gasShown) {
      this.mediaShown              = false;
      this.gasShown                = true;
      const factory                = this.componentFactoryResolver.resolveComponentFactory(GasComponent);
      const ref                    = this.viewRef.createComponent(factory);
      ref.instance.ownRef          = ref;
      ref.instance.fromInfography  = fromInfography;
      ref.instance.appComponent    = this.appComponent;
      ref.instance.parentPageTitle = this.themeHelper.pageTitle;
      ref.changeDetectorRef.detectChanges();
      this.activeMenu();
      this.setDataSource(ref.instance);
    }
  }

  public setDataSource(datasource: any): void {
    this.dataSource = datasource;
  }

  public hideDetail(): void {
    this.showDetail                = false;
    this.navigationHelper.observer = null;
    this.navStack                  = [];
  }

  public checkTheme(): number // INFO: 0 = white | 1 = blue | 2 = grey
  {
    this.customer = 0;
    this.media    = false;
    this.gas      = false;

    if (this.search) {
      return 2;
    }

    if (this.gasShown) {
      return this.setGasTheme();
    }

    if (this.mediaShown) {
      return this.setMediaTheme();
    }

    if (this.customerShown) {
      this.setCustomerTheme();
    }

    if (this.router.url === '/home') {
      return this.setCommonTheme();
    }

    if (this.router.url.indexOf('processList') !== -1) {
      return this.setCommonTheme();
    }

    if (this.router.url.indexOf('offer') !== -1) {
      return this.setCommonTheme();
    }

    if (this.router.url.indexOf('product') !== -1) {
      return this.setCommonTheme();
    }

    if (this.router.url.indexOf('beyondGases') !== -1) {
      return this.setCommonTheme();
    }

    if (this.router.url.indexOf('brand') !== -1) {
      return this.setCommonTheme();
    }

    if (this.router.url.indexOf('service') !== -1) {
      return this.setCommonTheme();
    }

    if (this.router.url.indexOf('supply') !== -1) {
      return this.setCommonTheme();
    }

    if (this.router.url === '/synchronize') {
      this.setSynchronizeTheme();
    }

    if (this.router.url === '/welcome') {
      this.setWelcomeTheme();
    }

    if (this.router.url === '/signature') {
      this.setSignatureTheme();
    }

    if (this.router.url === '/beyondGases') {
      this.showSearch = false;
      this.setWelcomeTheme();
    }
    return 1;
  }

  public openDetail(pageType: number, data: any, infographyFull: boolean = false): void {
    if (this.router.url.indexOf('processList') !== -1) {
      this.showDetailContainer(pageType, data, 100, 66.6);
      return;
    }

    if (this.router.url.indexOf('infography') !== -1 && infographyFull) {
      this.showDetailContainer(pageType, data, 100, 91.5);
      return;
    }

    if (this.router.url.indexOf('infography') !== -1) {
      this.showDetailContainer(pageType, data, 100, 73);
      return;
    }

    this.showDetailContainer(pageType, data, 100, 100);
  }

  private showDetailContainer(pageType: number, data: any, height: number, width: number): void {
    this.data       = data;
    this.pageType   = pageType;
    this.showDetail = true;
    this.height     = height;
    this.width      = width;

    this.navStack.push({pageType, data, height, width});

    this.navigationHelper.initObservableComponent()
      .subscribe(_ => {
        const lastIndex: number = this.navStack.length - 1;

        this.navStack.splice(lastIndex, 1);

        const lastItem: any = this.navStack[lastIndex - 1];

        if (lastItem === undefined) {
          this.hideDetail();
          this.navigationHelper.routerData = null;
          return;
        }

        this.data       = lastItem.data;
        this.pageType   = lastItem.pageType;
        this.showDetail = true;
        this.height     = lastItem.height;
        this.width      = lastItem.width;

        this.navigationHelper.routerData = lastItem.data;
      });
  }

  private setCommonTheme(): number {
    this.search     = false;
    this.showSearch = false;
    // this.hideDetail();
    return 0;
  }

  private setSignatureTheme(): void {
    this.showSearch = false;
  }

  private setSynchronizeTheme(): void {
    this.showSearch = false;

  }

  private setWelcomeTheme(): number {
    this.showSearch = true;

    return 1;
  }

  private setGasTheme(): number {
    this.customer = 0;
    this.gas      = true;
    this.media    = false;

    return 0;
  }

  private setMediaTheme(): number {
    this.customer   = 0;
    this.gas        = false;
    this.media      = true;
    this.showSearch = true;

    return 0;
  }

  private setCustomerTheme(): void {
    this.customer   = 1;
    this.gas        = false;
    this.media      = false;
    this.showSearch = true;
  }

  private goToSync(): void {
    this.router.navigate(['/synchronize'])
      .catch(console.error);

  }
}
