import {ArrayTyper} from '@caliatys/array-typer';
import {Application} from './application.model';
import {ALDocument} from './document.model';
import {Focus} from './focus.model';
import {Market} from './market.model';
import {MediaDocument} from './mediadocument.model';
import {Product} from './product.model';
import {Service} from './service.model';
import {SupplyMode} from './supplymode.model';
import {Tile} from './tile.model';
import {WelcomeDocument} from './welcomedocument.model';

export class AppData {
  timestamp: Date;
  aboutAl: ALDocument;
  userGuide: ALDocument;
  expectations: string[];
  welcomeDocument: WelcomeDocument;
  mediaDocument: MediaDocument;
  focuses: Focus[];
  othersMedia: any;
  markets: { [key: number]: Market };
  applications: { [key: number]: Application };
  stringRessources: { [key: string]: any };
  products: { [key: number]: Product };
  supplyModes: { [key: number]: SupplyMode };
  services: { [key: number]: Service };
  freeFocuses: { [key: number]: Service };
  supplyModeTiles: { [key: number]: Tile };
  beyondGasTiles: { [key: number]: Tile };

  constructor(json: any) {
    this.timestamp        = json.Timestamp;
    this.aboutAl          = new ALDocument(json.AboutAl);
    this.userGuide        = new ALDocument(json.UserGuide);
    this.expectations     = json.Expectations as string[];
    this.welcomeDocument  = new WelcomeDocument(json.WelcomeDocuments);
    this.mediaDocument    = new MediaDocument(json.MediaDocuments);
    this.focuses          = ArrayTyper.asArray(Focus, json.Focuses || []);
    this.markets          = ArrayTyper.asDict(Market, json.Markets || [], market => market.id);
    this.applications     = ArrayTyper.asDict(Application, json.Applications || [], application => application.id);
    this.stringRessources = json.StringRessources;
    this.products         = ArrayTyper.asDict(Product, json.Products || [], product => product.id);
    this.supplyModes      = ArrayTyper.asDict(SupplyMode, json.SupplyModes || [], supplyMode => supplyMode.id);
    this.services         = ArrayTyper.asDict(Service, json.Services || [], service => service.id);
    this.freeFocuses      = ArrayTyper.asDict(Service, json.FreeFocuses || [], service => service.id);
    this.supplyModeTiles  = ArrayTyper.asDict(Tile, json.SupplyModeTiles || [], tile => tile.order);
    this.beyondGasTiles   = ArrayTyper.asDict(Tile, json.BeyondGaseTiles || [], tile => tile.order);
    this.othersMedia      = json.OtherDocument;

  }
}
