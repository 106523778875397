import packageJson from '../../package.json';

export const environment = {
  production      : true,
  appName         : 'SalesApp v2 PPRD',
  version         : packageJson.version,
  googleAnalytics : 'G-W8J0Y915Z9',
  baseUrl         : 'https://salesapp-pprd.apps.airliquide.com/',
  api             : 'https://salesapp-pprd.apps.airliquide.com/api',
  defaultUsername : '',
  defaultPassword : '',
  minSpaceRequired: 100,                                               // NOTE: 100Mb
  maxWriteTime    : 20 * 1000,                                         // NOTE: 20s
  xhrTimeout      : 60 * 1000,                                         // NOTE: 60s
  zipSize         : 22,
  googleClientId  : '731699166886-i0i7ud7dht73adoafaebuvp8cvesiq1n.apps.googleusercontent.com'
};
