<div class="media_container" *ngIf="!filterString">
  <div class="ui-g scroll_container">
    <div class="ui-g-12" *ngIf="!selectedProductCategory && !selectedBeyondCategory">

      <!--PROCESS SOLUTION-->
      <div class="ui-g-3 titles">
        <h1>
          {{ 'process_solution' | translate }}
        </h1>
      </div>
      <div class="ui-g-9 media-grid">
        <div class="separator_line_container">
          <div class="separator_line"></div>
        </div>
      </div>
      <div class="ui-g-12 media-grid">
        <div *ngFor="let processSolution of processSolutions">
          <div class="ui-g-3" *ngIf="processSolution.Medias && processSolution.Medias.length > 0">
            <button
              color="primary"
              mat-raised-button
              class="media_category"
              (click)="selectProcessSolution(processSolution)">
              <h4>{{ processSolution.Name }}</h4>
            </button>
          </div>
        </div>
      </div>

       <!--GAS-->
      <div class="ui-g-3 titles">
        <h1>
            {{ 'premium_gas' | translate }}
        </h1>
      </div>
      <div class="ui-g-9 media-grid">
        <div class="separator_line_container">
          <div class="separator_line"></div>
        </div>
      </div>
      <div class="ui-g-12 media-grid">
        <div *ngFor="let key of productCategoryKeys">
          <div class="ui-g-3" *ngIf="topTileIsActive(productCategories, key)">
            <button
              color="primary"
              mat-raised-button
              class="media_category"
              (click)="selectProductCategory(key)">
              <h4>{{ key  | translate }}</h4>
            </button>
          </div>
        </div>
        <div *ngIf="mediaProduct" class="ui-g-3">
          <button
            color="primary"
            mat-raised-button
            class="media_category"
            (click)="selectMediaProduct()">
            <h4>{{ mediaProduct.Name  | translate }}</h4>
          </button>
        </div>
      </div>

      <!--SUPPLY MODE-->
      <div class="ui-g-3 titles">
        <h1>
          {{ 'supply_modes' | translate }}
        </h1>
      </div>
      <div class="ui-g-9 media-grid">
        <div class="separator_line_container">
          <div class="separator_line"></div>
        </div>
      </div>
      <div class="ui-g-12 media-grid">
        <div *ngFor="let supplyMode of supplyModes">
          <div class="ui-g-3" *ngIf="supplyMode.Medias && supplyMode.Medias.length > 0">
            <button
              color="primary"
              mat-raised-button
              class="media_category"
              (click)="selectSupplyMode(supplyMode)">
              <h4>{{ supplyMode.Name }}</h4>
            </button>
          </div>
        </div>
      </div>

       <!--BEYOND GASES-->
      <div class="ui-g-3 titles">
        <h1>
          {{ 'beyond_gases' | translate }}
        </h1>
      </div>
      <div class="ui-g-9 media-grid">
        <div class="separator_line_container">
          <div class="separator_line"></div>
        </div>
      </div>
      <div class="ui-g-12 media-grid">
        <div *ngFor="let key of beyondCategoryKeys">
          <div class="ui-g-3" *ngIf="topTileIsActive(beyondCategories, key)">
            <button
              color="primary"
              mat-raised-button
              class="media_category"
              (click)="selectBeyondCategories(key)">
              <h4>{{ key }}</h4>
            </button>
          </div>
        </div>
        <div *ngIf="mediaBeyond" class="ui-g-3">
          <button
            color="primary"
            mat-raised-button
            class="media_category"
            (click)="selectMediaBeyond()">
            <h4>{{ mediaBeyond.Name | translate }}</h4>
          </button>
        </div>
      </div>
    </div>

    <!--GAS-->
    <div class="ui-g-12" *ngIf="selectedProductCategory">
      <div class="ui-g-3 titles">
          <h1>
              {{ 'premium_gas' | translate }} > {{ selectedProductCategory | translate }}
          </h1>
        </div>
        <div class="ui-g-9 media-grid">
          <div class="separator_line_container">
            <div class="separator_line"></div>
          </div>
        </div>
        <div class="ui-g-12 media-grid">
          <div *ngFor="let product of productCategories[selectedProductCategory]">
            <div class="ui-g-3" >
              <button
                color="primary"
                mat-raised-button
                class="media_category"
                (click)="selectProducts(product)">
                <h4>{{ product.Name }}</h4>
              </button>
            </div>
          </div>
        </div>
    </div>

    <!--BEYOND GASES-->
    <div class="ui-g-12" *ngIf="selectedBeyondCategory">
      <div class="ui-g-3 titles">
          <h1>
              {{ 'beyond_gases' | translate }} > {{ selectedBeyondCategory | translate }}
          </h1>
        </div>
        <div class="ui-g-9 media-grid">
          <div class="separator_line_container">
            <div class="separator_line"></div>
          </div>
        </div>
        <div class="ui-g-12 media-grid">
          <div *ngFor="let beyond of beyondCategories[selectedBeyondCategory]">
            <div class="ui-g-3" *ngIf="beyond.Medias && beyond.Medias.length > 0">
              <button
                color="primary"
                mat-raised-button
                class="media_category"
                (click)="selectBeyond(beyond)">
                <h4>{{ beyond.Name }}</h4>
              </button>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>

<app-media-detail
  id="test"
  [mediasTitle]="mediasTitle"
  [mediaCategory]="mediaCategory"
  [isSearchView]="false"
  *ngIf="isDetail && !filterString">
</app-media-detail>

<div class="media_container" *ngIf="filterString">
  <div class="ui-g scroll_container">
    <app-media-detail
      class="app-media-detail"
      *ngFor="let processSolution of processSolutions"
      [mediasTitle]="processString"
      [mediaCategory]="processSolution"
      [filterString]="filterString">
    </app-media-detail>
    <div class="search_media_container" *ngFor="let key of productCategoryKeys">
        <app-media-detail  class="app-media-detail" *ngFor="let mediaCategory of productCategories[key]"
        [mediasTitle]="gasString + ' > ' + translate.instant(key)"
        [mediaCategory]="mediaCategory"
        [filterString]="filterString">
        </app-media-detail>
    </div>
    <app-media-detail
      *ngIf="mediaProduct"
      class="app-media-detail"
      [mediasTitle]="gasString"
      [mediaCategory]="mediaProduct"
      [filterString]="filterString">
    </app-media-detail>
    <app-media-detail class="app-media-detail" *ngFor="let supplyMode of supplyModes"
    [mediasTitle]="supplyString"
    [mediaCategory]="supplyMode"
    [filterString]="filterString">
    </app-media-detail>
    <div class="search_media_container" *ngFor="let key of beyondCategoryKeys">
        <app-media-detail  class="app-media-detail" *ngFor="let mediaCategory of beyondCategories[key]"
        [mediasTitle]="beyondString + ' > ' + translate.instant(key)"
        [mediaCategory]="mediaCategory"
        [filterString]="filterString">
        </app-media-detail>
    </div>
    <app-media-detail
      *ngIf="mediaBeyond"
      class="app-media-detail"
      [mediasTitle]="beyondString"
      [mediaCategory]="mediaBeyond"
      [filterString]="filterString">
    </app-media-detail>
  </div>
</div>
