import {v4 as uuid} from 'uuid';

import {Media} from './media.model';

export class MediaCategory {
  Id: string;
  Name: string;
  Medias: Media[];

  constructor() {
    this.Id     = uuid();
    this.Medias = [];
  }
}
