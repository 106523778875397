import {v4 as uuid} from 'uuid';

export class User {
  id: string;
  UserName: string;
  Email: string;
  Password: string;
  PushDate: Date;

  constructor() {
    this.id = uuid();
  }

  public static New(json: any): User {
    const user: User = new User();

    user.Email    = json.Email;
    user.UserName = json.UserName;
    user.Password = json.Password;

    return user;
  }
}
