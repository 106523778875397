<div [ngStyle]="getStyle()" class="process_detail_container">
  <app-product *ngIf="pageType === 0" [parent]="parent" [productData]="data">
  </app-product>
  <app-offer *ngIf="pageType === 1" [applicationId]="data.id">
  </app-offer>
  <app-tiles *ngIf="pageType === 2" [tiles]="data">
  </app-tiles>
  <app-service *ngIf="pageType === 3" [serviceData]="data">
  </app-service>
  <app-process *ngIf="pageType === 4" [application]="data">
  </app-process>
  <app-tiles *ngIf="pageType === 5" [tiles]="data">
  </app-tiles>
</div>
