import {animate, state, style, transition, trigger} from '@angular/animations';
import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';

/*EXTERNAL LIBRARIES*/
import {TranslateService} from '@ngx-translate/core';
import {timer} from 'rxjs';
import {AppComponent} from '../app.component';

/*COMPONENTS*/
import {CarouselComponent} from '../shared/components/carousel/carousel.component';
import {AnalyticsType} from '../shared/enums/analytics.enum';

/*ENUMS*/
import {MediaHelper} from '../shared/helpers/media.helper';
import {MimeHelper} from '../shared/helpers/mime.helper';
import {NavigationHelper} from '../shared/helpers/navigation.helper';

/*HELPERS*/
import {ThemeHelper} from '../shared/helpers/theme.helper';
import {AppData} from '../shared/models/appdata.model';

/*MODELS*/
import {Customer} from '../shared/models/customer.model';
import {Media} from '../shared/models/media.model';
import {Offer, OfferDetail} from '../shared/models/offer.model';
import {AnalyticsService} from '../shared/services/analytics.service';

/*SERVICES*/
import {CatalogService} from '../shared/services/catalog.service';
import {CustomerService} from '../shared/services/customer.service';

@Component({
  selector   : 'app-offer',
  templateUrl: 'offer.component.html',
  styleUrls  : ['offer.component.css'],
  animations : [
    trigger('activatePin', [
      state('1', style({height: '35px'})),
      state('0', style({height: '30px'})),
      transition('0 <=> 1', [
        animate(0)
      ])
    ])]
})

export class OfferComponent implements OnInit, AfterViewInit {
  @ViewChild(CarouselComponent) carouselComponent: CarouselComponent;
  @Input() applicationId: number;
  @Input() supplyModeId: number;

  @ViewChild('infography') infographyElmRef: ElementRef;

  public offer: Offer        = null;
  public height: number;
  public width: number;
  public isEmbedded          = true;
  public globalView          = false;
  public title: string;
  public selectedPin         = -1;
  public selectedOfferDetail: OfferDetail;
  public canAddToCart        = false;
  public documentsIds: any[] = [];
  public selectDocs: boolean;
  private imageTimer         = 0;

  constructor(public  parent: AppComponent,
              public  mediaHelper: MediaHelper,
              public  sanitizer: DomSanitizer,
              public  mimeHelper: MimeHelper,
              private route: ActivatedRoute,
              private customerService: CustomerService,
              private themeHelper: ThemeHelper,
              private catalogService: CatalogService,
              private navigationHelper: NavigationHelper,
              private analyticsService: AnalyticsService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private router: Router) {
    this.parent.activeMenu();

    const data: any = this.navigationHelper.routerData;

    if (data === undefined || data === null) {
      return;
    }

    this.offer               = data.offer;
    this.selectedOfferDetail = data.selectedOfferDetail;
    this.globalView          = data.globalView;
    this.title               = data.title;
    this.canAddToCart        = data.canAddToCart;

    this.navigationHelper.routerData = null;

  }

  private static processMedia(customer: Customer, doc: any): void {
    const media = new Media();

    media.Id   = doc.id.toString();
    media.Name = doc.displayName;
    media.Code = doc.path;
    media.Url  = doc.serverUrl;

    const exist = customer.Medias.find(m => m.Id === doc.id);

    if (exist !== undefined) {
      return;
    }

    customer.Medias.push(media);
  }


  public isImageReady(): boolean {
    return !!(this.imageTimer * this.width * this.height);
  }

  public ngOnInit(): void {
    if (this.offer !== null) {
      return;
    }

    if (this.applicationId === undefined && this.supplyModeId === undefined) {
      this.goToHome();
      return;
    }

    if (this.applicationId !== undefined) {
      this.catalogService.initOffer(this.applicationId,
        (processedData) => {
          this.offer               = processedData.offer;
          this.selectedOfferDetail = processedData.selectedOfferDetail;
          this.globalView          = processedData.globalView;
          this.title               = processedData.title;
          this.canAddToCart        = processedData.canAddToCart;
        });
      return;
    }

    if (this.supplyModeId !== undefined) {
      this.catalogService.initSupplyMode(this.supplyModeId,
        (processedData) => {
          this.offer               = processedData.offer;
          this.selectedOfferDetail = processedData.selectedOfferDetail;
          this.globalView          = processedData.globalView;
          this.title               = processedData.title;
          this.canAddToCart        = processedData.canAddToCart;
        });
    }
  }

  public ngAfterViewInit(): void {
    timer(500, 1000).subscribe(() => {
        if (this.infographyElmRef) {
          if (this.width !== this.infographyElmRef.nativeElement.width && this.infographyElmRef.nativeElement.width !== 0) {
            this.imageTimer = 1;
            this.width      = this.infographyElmRef.nativeElement.width;
          }
          if (this.height !== this.infographyElmRef.nativeElement.height && this.infographyElmRef.nativeElement.height !== 0) {
            this.height     = this.infographyElmRef.nativeElement.height;
            this.imageTimer = 1;
          }
        }
      }
    );
    const appData: AppData   = CatalogService.getCurrentData();
    const supplyModeOfferIds = Object.keys(appData.supplyModes).map(key => appData.supplyModes[key].offer.id);
    const isSupplyMode       = supplyModeOfferIds.some(id => id === this.offer.id);

    if (isSupplyMode) {
      this.analyticsService.sendEvent(AnalyticsType.OPEN_SUPPLY_MODE, this.title);
      return;
    }

    this.analyticsService.sendEvent(AnalyticsType.OPEN_OFFER, this.title);
  }

  public pinSelected(index: number): void {
    this.carouselComponent.setIndex(0);

    if (index === -1 || this.selectedPin === index) {
      this.selectedPin = -1;

      setTimeout(() => {
        this.selectedOfferDetail = this.offer.defaultDetail;
      }, 500);
      return;
    }

    this.selectedPin = index;

    setTimeout(() => {
      this.selectedOfferDetail = this.offer.details[index];
    }, 500);
  }

  public isActivatedPin(index: number): boolean {
    return this.selectedPin === index;
  }

  public getImageForPin(index: number): string {
    if (this.selectedPin === index) {
      return './assets/images/polygone_offer_on.png';
    }

    return './assets/images/polygone_offer.png';
  }

  public getHeightForPin(index: number): string {
    if (this.selectedPin === index) {
      return '50px';
    }

    return '35px';
  }

  public openProduct(productClicked: any): void {
    let supplyModes: any = null;

    for (const detail of this.offer.details) {
      for (const product of detail.products) {
        if (product.id !== productClicked.id) {
          continue;
        }

        supplyModes = product.supplyModes;
      }
    }

    this.catalogService.initProduct(productClicked.id, supplyModes,
      (processedData) => {
        this.navigationHelper.routerData = processedData;

        if (this.router.url.indexOf('infography') !== -1) {
          this.parent.openDetail(0, processedData, true);
          return;
        }

        this.parent.openDetail(0, processedData);
      });
  }

  public zoom(): void {
    this.globalView = false;
    if (this.offer.anchors && this.offer.anchors.length > 0) {
      this.pinSelected(-1);
      setTimeout(() => {
        this.pinSelected(-1);
      }, 200);
    }
  }

  public zoomOut(): void {
    this.globalView = true;
    if (this.offer.anchors && this.offer.anchors.length > 0) {
      this.pinSelected(-1);
      setTimeout(() => {
        this.pinSelected(-1);
      }, 200);
    }
  }

  public selectItem(event: any, doc: any): void {
    if (!event.checked) {
      this.documentsIds = this.documentsIds.filter(d => d.id !== doc.id);
      return;
    }

    this.documentsIds.push(doc);
  }

  public addToCart(): void {
    const selCustomer = this.customerService.getSelectedCustomer();

    if (selCustomer === null || selCustomer === undefined) {
      this.snackBar.open(this.translate.instant('PLS_SEL_BEFORE'), 'x',
        {
          duration: 5000
        });
      this.selectDocs = false;
      return;
    }

    if (this.offer.defaultDetail !== undefined) {
      for (const slide of this.offer.defaultDetail.slides) {
        for (const doc of slide.documents) {
          const exist = this.documentsIds.find(d => d.id === doc.id);

          if (exist === undefined) {
            continue;
          }

          OfferComponent.processMedia(selCustomer, doc);

          this.analyticsService.sendEvent(AnalyticsType.ADD_TO_CART, doc.fileName);
        }
      }
    }

    if (this.offer.details !== undefined) {
      for (const detail of this.offer.details) {
        for (const slide2 of detail.slides) {
          for (const doc2 of slide2.documents) {
            const exist2 = this.documentsIds.find(d => d.id === doc2.id);

            if (exist2 === undefined) {
              continue;
            }

            OfferComponent.processMedia(selCustomer, doc2);

            this.analyticsService.sendEvent(AnalyticsType.ADD_TO_CART, doc2.fileName);
          }
        }
      }
    }

    this.selectDocs = false;
    this.customerService.setSelectedCustomer(selCustomer);

    if (this.documentsIds.length > 0) {
      this.snackBar.open(this.translate.instant('added_to_cart'), 'x',
        {
          duration: 5000
        });
      this.documentsIds = [];
    }
  }

  public isSelected(doc): boolean {
    const selCustomer = this.customerService.getSelectedCustomer();

    if (selCustomer === undefined || selCustomer === null) {
      return false;
    }

    return selCustomer.Medias.some(item => item.Code === doc.path);
  }

  public activeSelection(): void {
    this.selectDocs = true;
  }

  private goToHome(): void {
    this.parent.goToHome();
  }
}
