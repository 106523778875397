import {v4 as uuid} from 'uuid';


export class ALDocument {
  id: uuid;
  lastModified: number;
  folderName: string;
  fileName: string;
  displayName: string;
  serverUrl: string;

  get path(): string {
    return `${this.folderName}/${this.fileName}`;
  }

  constructor(json: any) {
    if (!json) {
      return null;
    }

    this.id           = json.Id;
    this.folderName   = json.FolderName;
    this.fileName     = json.FileName;
    this.displayName  = json.DisplayName;
    this.serverUrl    = json.DocumentUrl;
    this.lastModified = new Date(json.LastModified).getTime();
  }

  public copyInto(object: any): void {
    if (!object) {
      return null;
    }

    this.id           = object.id;
    this.folderName   = object.folderName;
    this.fileName     = object.fileName;
    this.displayName  = object.displayName;
    this.serverUrl    = object.serverUrl;
    this.lastModified = new Date(object.lastModified).getTime();
  }
}
