import {ArrayTyper} from '@caliatys/array-typer';
import {PageType} from '../enums/pagetype.enum';
import {ALDocument} from './document.model';
import {Offer} from './offer.model';
import {Tile} from './tile.model';

export class Page {
  order: number;
  type: PageType;
  title: string;
  tiles: any;
  offer: Offer;
  productData: { id: any; supplyModes: any; equipment: any; equipmentTitle: any };
  about: any;
  expectations: any;
  proposals: any;
  id: number;

  constructor(json: any) {
    if (!json) {
      return null;
    }
    this.order = json.Order;
    this.type  = json.Type;
    this.title = json.Title;
    this.id    = json.Id;

    switch (this.type) {
      case PageType.About:
        this.about = {
          title        : json.Title,
          text         : json.Text,
          videoPath    : json.VideoPath,
          thumbnailPath: json.ThumbnailPath,
          documents    : ArrayTyper.asArray(ALDocument, json.Documents || [])
        };
        break;
      case PageType.Expectation:
        this.expectations = {
          title       : json.Title,
          expectations: json.Priorities
        };
        break;
      case PageType.Offer:
        this.offer = new Offer(json.Offer);
        break;
      case PageType.Product:
        this.productData = {
          id            : json.ProductId,
          supplyModes   : json.Focuses ? json.Focuses.map(supplyMode => supplyMode.Id) :
            null,
          equipment     : json.Equipment,
          equipmentTitle: json.EquipmentTitle
        };
        break;
      case PageType.Proposal:
        this.proposals = {
          title    : json.Title,
          proposals: json.Proposals,
          documents: ArrayTyper.asArray(ALDocument, json.Documents || [])
        };
        break;
      case PageType.Tiles:
        const tiles = ArrayTyper.asDict(Tile, json.Tiles || [], tile => tile.order);
        this.tiles  = {
          title: json.Title,
          tiles: Object.keys(tiles).sort().map(key => tiles[key])
        };
        break;
    }
  }
}
