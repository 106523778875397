import {v4 as uuid} from 'uuid';

import {Expectation} from './expectation.model';
import {Media} from './media.model';

export class Customer {
  Id: string;
  Name: string;
  CompanyName: string;
  IsDefault: boolean;
  DateOfCreation: Date;
  Expectations: Expectation[];
  Medias: Media[];

  constructor() {
    this.Id             = uuid();
    this.DateOfCreation = new Date();
    this.Medias         = [];
    this.Expectations   = [];
    this.IsDefault      = false;
  }
}
