import {v4 as uuid} from 'uuid';

export class Media {
  Id: string;
  Name: string; // Media name (optional)
  Code: string; // Storage key
  Url: string; // Serveur file url

  constructor() {
    this.Id = uuid();
  }
}
