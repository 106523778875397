import {ArrayTyper} from '@caliatys/array-typer';
import {ALDocument} from './document.model';
import {Sector} from './sector.model';
import {Service} from './service.model';


export class Segment {
  id: number;
  name: string;
  infographyPath: string;
  logoPath: string;
  services: { [key: number]: Service };
  sectors: Sector[];
  welcomeDocuments: ALDocument[];

  constructor(json: any) {
    if (!json) {
      return null;
    }
    this.id               = json.Id;
    this.name             = json.Name;                                        // segment name
    this.infographyPath   = json.InfographyPath;                              // infography image
    this.logoPath         = json.LogoPath;                                    // not used in infography
    this.services         = ArrayTyper.asDict(Service, json.Services || [], service => service.id);
    this.sectors          = ArrayTyper.asArray(Sector, json.Sectors || []);    // Zoomed zones
    this.welcomeDocuments = ArrayTyper.asArray(ALDocument, json.WelcomeDocument || []);
  }

  getProcessCount(): number {
    return this.sectors.reduce((aggregate: number, sector: Sector) => aggregate + sector.applicationIds.length, 0);
  }
}
