import {ArrayTyper} from '@caliatys/array-typer';
import {ALDocument} from './document.model';

export class Service {
  id: number;
  name: string;
  html: string;
  subtitle: string;
  backgroundPath: string;
  documents: ALDocument[];

  constructor(json: any) {
    if (!json) {
      return null;
    }
    this.id             = json.Id;
    this.name           = json.Name;
    this.html           = json.Html;
    this.subtitle       = json.Subtitle;
    this.backgroundPath = json.BackgroundPath;
    this.documents      = ArrayTyper.asArray(ALDocument, json.Documents || []);
  }
}
