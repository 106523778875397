<div class="component_content">
  <div *ngIf="isEmbedded" class="title">
    <h1>{{title}}</h1>
  </div>
  <img
    *ngIf="canAddToCart && !selectDocs"
    class="select_elements"
    (click)="activeSelection()"
    src="./assets/icons/add_to_cart.png" alt="activeSelection"/>
  <img
    class="select_elements select_elements-white"
    (click)="addToCart()"
    *ngIf="selectDocs"
    src="./assets/icons/ic_add_customer.png" alt="addToCart"/>
  <div class="offer-container">
    <div *ngIf="globalView" class="infography-container">
      <img class="infography" [src]="offer?.globalImagePath | fromStorage" alt="infography"/>
      <img class="zoom" (click)=zoom() src="./assets/icons/btn_plus.png" alt="zoom"/>
    </div>
    <div *ngIf="!globalView" class="infography-container">
      <img #infography class="infography" [src]="offer?.imagePath | fromStorage" alt="infography"/>
      <div [hidden]="!isImageReady()" [style.height.px]="height" [style.width.px]="width"
           class="infography-pin-container">
        <img *ngFor="let pin of offer?.anchors; let i = index"
             (click)="pinSelected(i)"
             class="pin"
             [src]="getImageForPin(i)"
             [ngStyle]="{ 'height' : getHeightForPin(i).toString(),
                          'top.%'  : pin.top.toString(),
                          'left.%' : pin.left.toString()
                        }" alt="pin"/>
      </div>
      <img class="zoom zoom-close" *ngIf="offer?.globalImagePath" (click)="zoomOut()"
           src="./assets/icons/btn_close.png" alt="zoomOut"/>
    </div>
    <div class="offer-detail-container">
      <div class="detail-title">
        <h3>{{selectedOfferDetail?.title}}</h3>
        <div class="gradient-underline"></div>
      </div>
      <app-carousel [items]="selectedOfferDetail?.slides">
        <ng-template let-item="item">
          <div class="slide">
            <div
              class="detail-text"
              [innerHTML]="sanitizer.bypassSecurityTrustHtml(item.description)">
            </div>
            <div class="detail-products">
              <div
                class="product-item"
                *ngFor="let product of selectedOfferDetail?.products">
                <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(product.html)">
                </div>
                <div
                  class="click-zone"
                  (click)="openProduct(product)">
                </div>
              </div>
            </div>
            <div class="docs-container-line" *ngFor="let doc of item.documents">
              <img [src]="mimeHelper.getIconFromFileName(doc.path)" alt="fileType"/>
              <a (click)="!selectDocs && mediaHelper.openMedia(doc.path)">
                <h6>{{doc.displayName}}</h6>
              </a>
              <mat-checkbox
                *ngIf="selectDocs"
                [checked]="isSelected(doc)"
                (change)="selectItem($event, doc)"
                color="primary">
              </mat-checkbox>
            </div>
          </div>
        </ng-template>
      </app-carousel>
    </div>
  </div>
</div>
