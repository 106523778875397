export class Point {
  x: number;
  y: number;

  constructor(json: any[]) {
    if (!json) {
      return null;
    }
    this.x = json[0];
    this.y = json[1];
  }

  get left(): number {
    return this.x * 100;
  }

  get top(): number {
    return this.y * 100;
  }

  isEqual(point: Point): boolean {
    return this.x === point.x && this.y === point.y;
  }
}
