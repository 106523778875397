// Angular modules
import { Injectable } from '@angular/core';

// External modules
import * as localForage from 'localforage';


@Injectable({
  providedIn: 'root'
})
export class StorageHelper {
  private static readonly KEY_STORAGE = 'storage';
  public quotaString                  = '';

  constructor() {
    localForage.config();
  }

  public static normalizeKey(key: string): string {
    let normKey = key.replace(/[\t\v\f\r \u00a0\u2000-\u200b\u2028-\u2029\u3000:"]+/g, '').trim();
    normKey     = normKey.replace(/<\/?[^>]+(>|$)/g, '');
    normKey     = normKey.replace(/\\/, '#_#');
    normKey     = normKey.replace('/', '#_#');

    return normKey.toLowerCase();
  }

  public removeItem(key: string): void {
    localStorage.removeItem(StorageHelper.normalizeKey(key));
  }

  public saveItem(jsonObject: any, storageKey: string): void {
    localStorage.setItem(StorageHelper.normalizeKey(storageKey), JSON.stringify(jsonObject));
  }

  public getItem(storageKey: string): unknown {
    return JSON.parse(localStorage.getItem(StorageHelper.normalizeKey(storageKey)));
  }

  public getStringDbItem(storageKey: string): Promise<string> {
    storageKey = StorageHelper.normalizeKey(storageKey);
    return localForage.getItem(storageKey);
  }

  public getBlobDbItem(storageKey: string, mimeType: string): Promise<Blob> {
    storageKey = StorageHelper.normalizeKey(storageKey);
    return localForage.getItem<Blob>(storageKey).then(blob => new Blob([blob], {type: mimeType}));
  }

  public saveDbItem(content: Blob | string, storageKey: string): Promise<void> {
    storageKey = StorageHelper.normalizeKey(storageKey);
    return localForage.setItem(storageKey, content).then();
  }

  public removeDbBlobItem(storageKey: string): Promise<void> {
    storageKey = StorageHelper.normalizeKey(storageKey);
    return localForage.removeItem(storageKey);
  }

  public quotaToString(): string {
    const res: number = Math.round((this.getItem(StorageHelper.KEY_STORAGE) ?? 0) as number) / 1024 / 1024;

    if (res >= (1073741824 / 1024 / 1024)) {
      const gbValue: number = res / 1000;

      return gbValue.toFixed(2) + ' Gb';
    }

    return res.toFixed(2) + ' Mb';
  }

  public listFiles(): Promise<string[]> {
    return localForage.keys();
  }

  public fetchStorage(): Promise<void> {
    this.saveItem(0, StorageHelper.KEY_STORAGE);

    return new Promise<void>((resolve, reject) => {
      const chrome: any = navigator;
      if (!chrome.webkitTemporaryStorage) {
        reject(new Error('Only Chrome is supported'));
      }

      chrome.webkitTemporaryStorage.queryUsageAndQuota(
        usedBytes => {
          this.saveItem(usedBytes, StorageHelper.KEY_STORAGE);
          resolve();
        },
        reject
      );
    });
  }
}
