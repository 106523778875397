import {v4 as uuid} from 'uuid';

export class Expectation {
  Id: string;
  Process: string;
  Expectations: string[];

  constructor() {
    this.Id = uuid();
  }
}
