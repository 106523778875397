<div class="component_content">
  <div class="ui-g">
    <div class="title" *ngIf="tiles.title">
      <h1>{{tiles.title}}</h1>
    </div>
    <div class="ui-g-12">
      <div [ngClass]="{'tile-container'      : tiles.title,
                 'tile-container-full' : !tiles.title}">
        <div class="ui-g-6" *ngFor="let tile of tiles.tiles; let i = index;" [ngSwitch]="tile.type">
          <mat-card class="tile" [ngStyle]="{ 'background' : getColor(tile) }">

            <app-tile-text *ngSwitchCase="TileType.Text" [data]="tile.tileText">
            </app-tile-text>
            <app-tile-text *ngSwitchCase="TileType.TextBackground" [data]="tile.tileText">
            </app-tile-text>
            <app-tile-text *ngSwitchCase="TileType.Web" [data]="tile.tileText">
            </app-tile-text>
            <app-tile-video *ngSwitchCase="TileType.Video" [data]="tile.tileVideo">
            </app-tile-video>
            <app-tile-picture *ngSwitchCase="TileType.Picture" [data]="tile.tileMedia">
            </app-tile-picture>
            <app-tile-service *ngSwitchCase="TileType.Service" [data]="tile.tileService">
            </app-tile-service>
            <app-tile-offer *ngSwitchCase="TileType.Offer" [data]="tile.tileOffer">
            </app-tile-offer>
            <app-tile-product *ngSwitchCase="TileType.Product" [data]="tile.tileProduct">
            </app-tile-product>
            <app-tile-brand *ngSwitchCase="TileType.Brand" [data]="tile.tileBrand">
            </app-tile-brand>
            <app-tile-application *ngSwitchCase="TileType.Application" [data]="tile.tileApplication">
            </app-tile-application>
            <app-tile-beyond *ngSwitchCase="TileType.Beyond" [data]="tile.tileBeyond">
            </app-tile-beyond>

          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
