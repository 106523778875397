<div class="infography-zoom-container">
  <img
    id="infography_zoom_image"
    class="infography-image"
    [src]="sector.infographyPath | fromStorage"/>
  <div
    class="pins-container"
    [ngStyle]="{'height' : containerH + 'px' , 'width' : containerW + 'px' }">
    <img
      *ngFor="let pin of allPins; let i = index"
      (click)="pinSelected(pin)"
      class="pin"
      [src]="isAssociated(pin)"
      [ngStyle]="{'z-index': getZIndex(),
                  'height' : isActivatedPin(pin),
                  'left'   : pin.left + '%',
                  'top'    : pin.top + '%'}" />
      <div *ngFor="let legend of sector.legends; let i = index">
        <div
          [@sectorAnimation] = "isLegend"
          class="sector-bubble"
          [ngStyle]="{'left' : legend.anchor.left + '%',
                      'top'  : legend.anchor.top + '%'}">
          <div class="sector-bubble-text" >
            <p>{{ legend.legend }}</p>
          </div>
        </div>
        <svg class="points-area" [@sectorAnimation] = "isLegend">
          <line
            [attr.x1]="getArrowTop(legend.tip.left)"
            [attr.y1]="getArrowBottom(legend.tip.top)"
            [attr.x2]="getTopArrowTop(legend.anchor.left)"
            [attr.y2]="getTopArrowBottom(legend.anchor.top)"
            stroke="black"/>
        </svg>
      </div>
  </div>
</div>
