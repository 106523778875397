<div class="component_content" (click)="open()" *ngIf="data">
    <div
      class="text"
      [innerHTML]="content"></div>
    <img class="foreground" src="./assets/images/tile_foreground.png" alt="tile_foreground">
    <button
      class="play"
      mat-mini-fab>
      <img src="./assets/icons/ic_play.png" alt="play">
    </button>
</div>
