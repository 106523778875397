import {animate, state, style, transition, trigger} from '@angular/animations';
import {AfterViewInit, Component, HostListener} from '@angular/core';
import {Router} from '@angular/router';

/*COMPONENTS*/
import {AppComponent} from '../app.component';
import {AnalyticsType} from '../shared/enums/analytics.enum';

/*HELPERS*/
import {NavigationHelper} from '../shared/helpers/navigation.helper';
import {ThemeHelper} from '../shared/helpers/theme.helper';
import {Application} from '../shared/models/application.model';
import {Market} from '../shared/models/market.model';
import {Sector} from '../shared/models/sector.model';

/*MODELS*/
import {Segment} from '../shared/models/segment.model';
import {AnalyticsService} from '../shared/services/analytics.service';

/*SERVICES*/
import {CatalogService} from '../shared/services/catalog.service';

@Component({
  templateUrl: 'infography.component.html',
  styleUrls  : ['infography.component.css'],
  animations :
    [
      trigger('sectorAnimation', [
        state('0', style({transform: 'translateY(-100%)', opacity: 0})),
        state('1', style({transform: 'translateY(0%)', opacity: 1})),
        transition('0 => 1', animate(0)),
        transition('1 => 0', animate(0))
      ]),
      trigger('zoomMenuAnimation', [
        state('0', style({width: '0%'})),
        state('1', style({width: '250px'})),
        transition('0 => 1', animate(0)),
        transition('1 => 0', animate(0))
      ]),
      trigger('detailAnimation', [
        state('-1', style({height: '0%'})),
        state('0', style({height: '5%'})),
        state('1', style({height: '100%'})),
        transition('-1 => 0', animate(0)),
        transition('0 => 1', animate(0)),
        transition('1 => 0', animate(0)),
        transition('1 => -1', animate(0)),
        transition('0 => -1', animate(0))
      ])
    ]
})

export class InfographyComponent implements AfterViewInit {

  public segment: Segment             = null;
  public market: Market               = null;
  public isSector                     = false;
  public isZoomed                     = false;
  public sector: Sector               = null;
  public applications: Application[]  = [];
  public selectedAppIndex: number     = null;
  public isLegend                     = false;
  public isDetail                     = -1;
  public containerH                   = 0;
  public containerW                   = 0;
  public sectors: any                 = null;
  public menuState                    = 1;
  public application: any             = null;
  private readonly beyondGases: any   = {tiles: [], title: null};
  private readonly supplyModes: any   = {tiles: [], title: null};
  private areas: Map<any, any>        = new Map<any, any>();
  private events: Map<string, Sector> = new Map<string, Sector>();


  constructor(public parent: AppComponent,
              public navigationHelper: NavigationHelper,
              private router: Router,
              private themeHelper: ThemeHelper,
              private analyticsService: AnalyticsService,
              private catalogService: CatalogService) {
    this.parent.hideMenu();

    this.analyticsService.sendEvent(AnalyticsType.OPEN_VIEW, this.router.url);

    this.themeHelper.setPageTitle('');

    const data: any = this.navigationHelper.routerData;

    if (data === undefined || data === null) // INFO: User refresh page case
    {
      this.goToHome();
      return;
    }

    this.segment     = data.segment;
    this.market      = data.market;
    this.supplyModes = data.supplyModes;
    this.beyondGases = data.beyondGases;

    this.navigationHelper.routerData = null;
  }

  @HostListener('window:resize')
  initSvg(): void {
    setTimeout(() => // INFO: Needed for fluid int process
    {
      this.processContainer();
    }, 1000);

    setTimeout(() => // INFO: Needed for fluid int process
    {
      this.sectors = this.segment.sectors;
      this.processClickableAreas();
      this.isSector = true;
    }, 1500);
  }

  public ngAfterViewInit(): void {
    this.initSvg();
  }

  public goToHome(): void {
    this.parent.goToHome();
  }

  public getTopArrowBottom(percentLeft: number): string {
    return percentLeft + 5 + '%';
  }

  public getTopArrowTop(percentTop: number): string {
    return percentTop + 5 + '%';
  }

  public getArrowBottom(percentLeft: number): string {
    return percentLeft + '%';
  }

  public getArrowTop(percentTop: number): string {
    return percentTop + '%';
  }

  public activeLegends(event: any): void {
    this.isLegend = event.checked;
  }

  public getClickableArea(sector: Sector): any[] {
    const points: any[] = [];

    sector.boundary.points
      .forEach((item) => {
        const x = (this.containerW * item.x);
        const y = (this.containerH * item.y);
        points.push(x + ',' + y);
      });

    this.areas[sector.id] = points;

    return points;
  }

  public processContainer(): void {
    const elem: any = document.getElementById('infography_image');
    this.containerW = elem.width;
    this.containerH = elem.height;
  }

  public getPointsBySector(sector: Sector): any {
    return this.areas[sector.id];
  }

  public zoomSector(sector: Sector): void {
    this.isZoomed = !this.isZoomed;
    this.sector   = sector;

    this.getSectorProcesses(sector);
    this.navigationHelper.initZoomInfoObserver()
      .subscribe(_ => {
        this.goToInfography(false, true);
      });
  }

  public extendDetail(): void {
    if (this.isDetail === 0) {
      this.isDetail = 1;
      this.navigationHelper.initViewProcessObserver()
        .subscribe(_ => {
          this.isDetail = 0;
        });
    } else {
      this.navigationHelper.back();
    }
  }

  public onSelectedAppIndexChanged(index: number): void {
    this.navigationHelper.resetCarouselIndex();
    if (index === null || index === this.selectedAppIndex) {
      this.selectedAppIndex = null;
      this.isDetail         = -1;
      return;
    }

    this.application = this.applications[index];

    this.catalogService.initProcess(this.application.id, true,
      (processedData) => {
        this.application      = processedData.application;
        this.selectedAppIndex = index;
        this.isDetail         = 0;
      });
  }

  public isSelected(appIndex: number): boolean {
    return this.selectedAppIndex === appIndex;
  }

  public isSelectedAsset(appIndex: number): string {
    if (this.selectedAppIndex === appIndex) {
      return './assets/images/polygone_offer_on.png';
    }

    return './assets/images/polygone_offer.png';
  }

  public goBack(): void {
    if (this.navigationHelper.back() === 0) {
      this.menuState = 1;
    }
  }

  public goToInfography(skipCheck: boolean = false, fromZoomedOut: boolean = false): void {
    if (!fromZoomedOut && this.menuState === 1) {
      return;
    }
    if (!skipCheck) {
      this.handleGasLib();
    }
    this.menuState               = 1;
    this.isLegend                = false;
    this.isZoomed                = false;
    this.isDetail                = -1;
    this.application             = null;
    this.selectedAppIndex        = null;
    this.themeHelper.customTiles = false;

    this.parent.hideDetail();
  }

  public gotToSupplyModes(): void {
    if (this.menuState === 2) {
      return;
    }
    this.handleGasLib();
    this.menuState               = 2;
    this.isDetail                = -1;
    this.themeHelper.customTiles = true;

    this.parent.openDetail(5, this.supplyModes, true);
  }

  public gotToBeyondGases(): void {
    if (this.menuState === 3) {
      return;
    }
    this.handleGasLib();
    this.menuState = 3;
    this.isDetail  = -1;
    this.parent.openDetail(5, this.beyondGases, true);
  }

  public gotToGasLibrary(): void {
    if (this.menuState === 4) {
      return;
    }
    this.handleGasLib();
    this.menuState = 4;
    this.isDetail  = -1;
    this.parent.goToGas(true);
  }

  private handleGasLib(): void {
    this.navigationHelper.resetNavItem();
    if (this.menuState === 1) {
      if (this.navigationHelper.zoomInfoObserver !== undefined && this.navigationHelper.zoomInfoObserver !== null) {
        this.goToInfography(true, true);
        this.navigationHelper.zoomInfoObserver = null;
      }
      return;
    }
    if (this.menuState !== 4) {
      return this.goToInfography(true);
    }
    if (this.navigationHelper.gasDetailComponentObserver !== null) {
      this.navigationHelper.back();
    }
    this.navigationHelper.back();
  }

  private getSectorProcesses(sector: Sector): void {
    this.applications = [];

    const data = CatalogService.getCurrentData();

    sector.applicationIds
      .forEach((item => {
        const app = data.applications[item];
        this.applications.push(app);
      }));

    const list = [];
    for (let i = 0; i < this.applications.length; i++) {
      list.push({app: this.applications[i], pins: sector.pins[i]});
    }
    list.sort((a, b) => a.app.name.localeCompare(b.app.name));

    this.applications = list.map((a) => a.app);
    if (!sector.pinSorted) {
      sector.pinSorted = true;
      sector.pins      = list.map((a) => a.pins);
    }
  }

  private processClickableAreas(): void {
    let index      = 0;
    const listener = (event) => {
      const target: any = event.currentTarget;
      const id: string  = target.id;

      this.zoomSector(this.events[id]);
    };
    this.segment.sectors
      .forEach((sector) => {
        this.getClickableArea(sector);

        const key     = 'area_' + index;
        const element = document.getElementById(key);
        element.removeEventListener('click', listener);
        element.addEventListener('click', listener);
        this.events[key] = sector;
        index += 1;
      });
  }
}
