import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeHelper {
  public pageTitle   = '';
  public customTiles = false; // INFO: define custom tiles theme (grey vs coloring)

  public constructor() {
  }

  public setPageTitle(newTitle: string): void {
    this.pageTitle = newTitle;
  }

  public setBreadCrumbTitle(newTitle: string): void {
    this.pageTitle = this.pageTitle + ' / ' + newTitle;
  }
}
