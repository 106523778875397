import {ArrayTyper} from '@caliatys/array-typer';
import {ALDocument} from './document.model';

export class Product {
  id: number;
  title: string;
  productPdfs: any[];
  equipmentPdfs: any[];
  composition: string;
  specifications: string[];
  quality: string;
  equipment: string;
  downloadPath: string;
  imagePath: string;

  constructor(json: any) {

    if (!json) {
      return null;
    }

    this.id             = json.Id;
    this.title          = json.Title;
    this.imagePath      = json.ImagePath;
    this.productPdfs    = ArrayTyper.asArray(ALDocument, json.Pdfs || []);
    this.equipmentPdfs  = ArrayTyper.asArray(ALDocument, json.EquipmentPdfs || []);
    this.composition    = json.Composition;
    this.specifications = JSON.parse(json.Specification);
    this.quality        = json.Quality;
    this.equipment      = json.Equipment;
    this.downloadPath   = json.PdfUrl;
  }
}
