import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';

/*EXTERNAL LIBRARIES*/
import {TranslateService} from '@ngx-translate/core';
import {AnalyticsType} from '../shared/enums/analytics.enum';
import {MediaHelper} from '../shared/helpers/media.helper';
import {MimeHelper} from '../shared/helpers/mime.helper';
import {NavigationHelper} from '../shared/helpers/navigation.helper';

/*HELPERS*/
import {ThemeHelper} from '../shared/helpers/theme.helper';
import {Customer} from '../shared/models/customer.model';
import {Media} from '../shared/models/media.model';
import {SupplyMode} from '../shared/models/offer.model';

/*MODELS*/
import {Product} from '../shared/models/product.model';
import {AnalyticsService} from '../shared/services/analytics.service';

/*SERVICES*/
import {CatalogService} from '../shared/services/catalog.service';
import {CustomerService} from '../shared/services/customer.service';

/*COMPONENTS*/

@Component({
  selector   : 'app-product',
  templateUrl: 'product.component.html',
  styleUrls  : ['product.component.css']
})

export class ProductComponent implements OnInit, AfterViewInit {
  @ViewChild('scrollMenu') scrollMenu: ElementRef;
  @Input() productData;
  @Input() parent;

  public product: Product    = null;
  public supplyModes: SupplyMode[];
  public equipment: SafeHtml;
  public equipmentTitle: string;
  public isEmbedded          = true;
  public canAddToCart        = false;
  public documentsIds: any[] = [];
  public selectDocs          = false;
  private isInit             = false;

  constructor(public  mediaHelper: MediaHelper,
              public  mimeHelper: MimeHelper,
              public  sanitizer: DomSanitizer,
              private navigationHelper: NavigationHelper,
              private customerService: CustomerService,
              private route: ActivatedRoute,
              private themeHelper: ThemeHelper,
              private catalogService: CatalogService,
              private snackBar: MatSnackBar,
              private analyticsService: AnalyticsService,
              private router: Router,
              private translate: TranslateService) {

    const data: any = this.navigationHelper.routerData;

    if (data === undefined || data === null) {
      return;
    }

    this.product        = data.product;
    this.supplyModes    = data.supplyModes;
    this.equipmentTitle = data.equipmentTitle;
    this.equipment      = data.equipment;
    this.canAddToCart   = data.canAddToCart;

    this.navigationHelper.routerData = null;
  }

  private static processMedia(customer: Customer, doc: any): void {
    const media = new Media();

    media.Id   = doc.id.toString();
    media.Name = doc.displayName;
    media.Code = doc.path;
    media.Url  = doc.serverUrl;

    const exist = customer.Medias.find(m => m.Id === doc.id);

    if (exist !== undefined) {
      return;
    }

    customer.Medias.push(media);
  }

  public ngOnInit(): void {
    this.parent.activeMenu();
    setTimeout(() => {
      this.isInit = true;
    }, 500);

    if (this.product !== null) {
      return;
    }

    if (this.productData === undefined) {
      this.parent.goToHome();
      return;
    }

    this.catalogService.initProduct(this.productData.id, this.productData.supplyModes,
      (processedData) => {
        this.product        = processedData.product;
        this.supplyModes    = processedData.supplyModes;
        this.equipmentTitle = processedData.equipmentTitle;
        this.equipment      = processedData.equipment;
        this.canAddToCart   = processedData.canAddToCart;
      });
  }

  public ngAfterViewInit(): void {
    this.analyticsService.sendEvent(AnalyticsType.OPEN_PRODUCT, this.product.title);
  }

  public openSupplyMode(supplymode: SupplyMode): void {

    // FIX
    if (this.parent.gasShown) {
      return;
    }

    if (!this.isInit) {
      return;
    }

    if (!supplymode.offer.defaultDetail) {
      return;
    }

    this.analyticsService.sendEvent(AnalyticsType.OPEN_SUPPLY_MODE_FROM_PRODUCT, supplymode.title);

    this.catalogService.initSupplyMode(supplymode.id,
      (processedData) => {
        this.navigationHelper.routerData = processedData;

        if (this.router.url.indexOf('infography') !== -1) {
          this.parent.openDetail(1, processedData, true);
          return;
        }

        this.parent.openDetail(1, processedData);
      });
  }

  public selectItem(event: any, doc: any): void {
    if (!event.checked) {
      this.documentsIds = this.documentsIds.filter(d => d.Id !== doc.id);
      return;
    }

    this.documentsIds.push(doc);
  }

  public addToCart(): void {
    const selCustomer = this.customerService.getSelectedCustomer();
    if (selCustomer === null || selCustomer === undefined) {
      this.snackBar.open(this.translate.instant('PLS_SEL_BEFORE'), 'x',
        {
          duration: 5000
        });
      this.selectDocs = false;
      return;
    }

    if (this.product.productPdfs !== undefined) {
      for (const doc of this.product.productPdfs) {
        const exist = this.documentsIds.find(d => d.id === doc.id);

        if (exist === undefined) {
          continue;
        }

        ProductComponent.processMedia(selCustomer, doc);
        this.analyticsService.sendEvent(AnalyticsType.ADD_TO_CART, doc.fileName);
      }
    }

    if (this.product.equipmentPdfs !== undefined) {
      for (const doc2 of this.product.equipmentPdfs) {
        const exist2 = this.documentsIds.find(d => d.id === doc2.id);

        if (exist2 === undefined) {
          continue;
        }

        ProductComponent.processMedia(selCustomer, doc2);
        this.analyticsService.sendEvent(AnalyticsType.ADD_TO_CART, doc2.fileName);
      }
    }

    this.selectDocs = false;
    this.customerService.setSelectedCustomer(selCustomer);

    if (this.documentsIds.length > 0) {
      this.snackBar.open(this.translate.instant('added_to_cart'), 'x',
        {
          duration: 5000
        });
      this.documentsIds = [];
    }
  }

  public isSelected(doc): boolean {
    const selCustomer = this.customerService.getSelectedCustomer();

    if (selCustomer === undefined || selCustomer === null) {
      return false;
    }

    return selCustomer.Medias.some(item => item.Code === doc.path);
  }

  public activeSelection(): void {
    this.selectDocs = true;
  }

  public next(): void {
    this.scrollMenu.nativeElement.scrollLeft = this.scrollMenu.nativeElement.scrollLeft + 100;
  }

  public prev(): void {
    this.scrollMenu.nativeElement.scrollLeft = this.scrollMenu.nativeElement.scrollLeft - 100;
  }
}
