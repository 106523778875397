import {Point} from './point.model';


export class Legend {
  anchor: Point;
  tip: Point;
  legend: string;

  constructor(json: any) {
    if (!json) {
      return null;
    }
    this.anchor = new Point(json.anchor);
    this.tip    = new Point(json.tip);
    this.legend = json.legend;
  }
}
