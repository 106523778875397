<h1 mat-dialog-title>{{ 'INSTANCES' | translate }}</h1>
<mat-dialog-content>
  <p>{{ 'PLS_SEL_LANG' | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    class="cancel_button"
    mat-raised-button
    type="button"
    color="primary"
    (click)="saveLang()">
    {{ 'ok' | translate }}
  </button>
</mat-dialog-actions>
