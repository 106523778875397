// Angular modules
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

// Internal modules
import {AppModule} from './app/app.module';

// Const
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

GoogleAuth.initialize({
  clientId: environment.googleClientId,
  scopes: ['profile', 'email'],
  grantOfflineAccess: true,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(console.error);
