import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {AuthService} from '../services/auth.service';
import {SynchronizeService} from '../services/synchronize.service';
import {UserHelper} from './user.helper';

@Injectable()
export class AuthGuardHelper implements CanActivate {
  constructor(private authService: AuthService,
              private router: Router,
              private syncService: SynchronizeService,
              private userHelper: UserHelper) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const connectUser          = this.userHelper.getConnectedUser();
    const isSync: boolean      = this.syncService.getLastUpdateDate() !== 'none';
    const goingToSync: boolean = state.url.includes('synchronize');

    if (!connectUser) {
      return this.router.createUrlTree(['/login']);
    }

    if (isSync) {
      this.authService.isLoggedIn    = true;
      this.authService.connectedMail = connectUser.Email;
    }
    return isSync || goingToSync || this.router.createUrlTree(['/synchronize']);

  }
}
