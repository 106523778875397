import {HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {ArrayTyper} from '@caliatys/array-typer';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';

import {Observable, throwError} from 'rxjs';
import {catchError, filter, map, tap, timeout} from 'rxjs/operators';

import {v4 as uuid} from 'uuid';
import {environment} from '../../../environments/environment';

import {StorageHelper} from '../helpers/storage.helper';
import {UserHelper} from '../helpers/user.helper';

import {Catalog} from '../models/catalog.models';
import {Country} from '../models/country.model';
import {ALDocument} from '../models/document.model';
import {Language} from '../models/language.model';

import {CatalogService} from './catalog.service';

type AppAssetResponse = { blob: Blob; hash: string; status: number };

@Injectable({
  providedIn: 'root'
})
export class SynchronizeService {
  public static readonly SYNC_KEY   = 'SYNC';
  public static readonly ASSETS_KEY = 'ASSETS';

  public countries: Country[];
  public langMap: Map<string, string>;
  public isLoggedIn                 = false;
  public connectedMail              = '';
  public update                     = false;
  public storeSize: any             = 0;
  public isNotified                 = false;
  public checkedLang: Language[]    = [];
  private headers: HttpHeaders      = null;
  private options: { headers: any, withCredentials: true } = null;

  constructor(public  http: HttpClient,
              private cookieService: CookieService,
              private userHelper: UserHelper,
              private router: Router,
              private storageHelper: StorageHelper,
              private translate: TranslateService,
              private snackBar: MatSnackBar,
              private catalogService: CatalogService) {
    this.setHeaders();
    this.langMap = new Map<string, string>();
  }

  public getAppData(selLanguages: Catalog[]): Observable<any> {
    this.setHeaders();
    return this.http.post<any>(environment.api + '/getJson', selLanguages, this.options)
      .pipe(
        timeout(environment.xhrTimeout),
        map(json => {
          json.Assets = ArrayTyper.asArray(ALDocument, json.Assets || []);
          return json;
        }),
        catchError(err => throwError(err || 'Server error'))
      );
  }

  public getAppUpdates(countryId: string, languageId: string): Observable<any> {
    this.setHeaders();
    const params = '/jsonData/lastSync?countryId=' + countryId + '&languageId=' + languageId;

    return this.http.get<any>(environment.api + params, this.options)
      .pipe(
        timeout(environment.xhrTimeout),
        catchError(err => throwError(err || 'Server error'))
      );
  }

  public getAppAssets(assets: uuid[], downCallBack: any): Promise<AppAssetResponse> {
    this.setHeaders();
    const req = new HttpRequest('POST', environment.api + '/getDocuments', assets, {
      headers        : this.headers,
      withCredentials: true,
      reportProgress : true,
      responseType   : 'blob'
    });
    return this.http.request<Blob>(req)
      .pipe(
        tap(event => {
            if (event.type === HttpEventType.DownloadProgress) {
              downCallBack(Math.round(100 * event.loaded / event.total));
            }
          }
        ),
        filter(event => event.type === HttpEventType.Response),
        map(event => {
          const res = event as HttpResponse<Blob>;
          if (res.status === 204) {
            return {blob: null, hash: null, status: res.status};
          }

          const checksum: string = res.headers.get('X-ZIP-HASH');
          return {blob: res.body, hash: checksum, status: res.status};
        })
      ).toPromise();
  }

  public getLastUpdateDate(): any {
    const lastUpdate = this.storageHelper.getItem(SynchronizeService.SYNC_KEY) as Date;

    if (lastUpdate === null || lastUpdate === undefined) {
      return 'none';
    }

    return new Date(lastUpdate).toDateString();
  }

  public endSyncProcess(assets: ALDocument[], manifest: any): Promise<void> {
    this.storeDataAndAssets(assets, manifest);
    this.update = false;
    this.storageHelper.saveItem(new Date(), SynchronizeService.SYNC_KEY);
    return this.updateStorage();
  }

  public storeDataAndAssets(assets: any[], manifest: any): void {
    if (assets === undefined) {
      return;
    }

    if (manifest === undefined) {
      return;
    }

    manifest.forEach(
      async item => {
        const key: Record<string, unknown> = {};
        key[item.CountryId]                = item.LanguageId;
        this.storageHelper.saveDbItem(JSON.stringify(item.Json), StorageHelper.normalizeKey(JSON.stringify(key))).catch(console.error);

        const value: any = {timestamp: item.Json.Timestamp, update: false};
        await this.storageHelper.saveItem(value, JSON.stringify(key));
      });

    this.storageHelper.saveDbItem(JSON.stringify(assets), SynchronizeService.ASSETS_KEY).catch(console.error);
  }

  public async checkForUpdate(): Promise<void> {
    this.langMap = new Map<string, string>();

    if (!this.countries || this.countries.length === 0) {
      this.countries = await this.catalogService.getLocalLanguages();
    }

    if (!this.countries) // NOTE: if no local languages throw error
    {
      return;
    }

    this.refreshUpdateState();

    // NOTE: remote update check process

    this.checkedLang = [];
    for (const country of this.countries) {
      for (const lang of country.Languages) {
        if (!lang.IsActive && !lang.IsOffline) {
          continue;
        }

        this.checkedLang.push(lang);
      }
    }

    this.processLangStack(this.checkedLang);
  }

  public refreshUpdateState(): void {
    if (!this.langMap) {
      return;
    }

    if (!this.countries) {
      return;
    }

    this.catalogService.updateCountriesData(this.countries);

    let isUpdate = false;

    for (const country of this.countries) {
      for (const lang of country.Languages) {
        if (lang.IsActive && !lang.IsOffline) {
          isUpdate = true;
        }
      }
    }

    this.update = isUpdate;

    if (!this.isNotified || !this.update) {
      return;
    }

    if (this.router.url === '/synchronize' || this.router.url === '/welcome') {
      this.snackBar.open(this.translate.instant('update_available'), 'x',
        {
          duration: 5000
        });
      this.isNotified = false;
    }
  }

  private processLangStack(languages: Language[]): void {
    let tmpCounter = 0;

    for (const lang of languages) {
      this.getAppUpdates(lang.CountryId, lang.Id) // NOTE: remote project updates
        .subscribe(res => {
          const key       = Language.GetUniqueKey(lang);
          const localLang = this.storageHelper.getItem(key) as any;

          if (localLang !== undefined && localLang !== null) {
            const serverDate = new Date(res.TimeStamp).getTime();
            const localDate  = new Date(localLang.timestamp).getTime();

            if (serverDate !== localDate) {
              localLang.update = true;
              lang.IsOffline   = false;
            } else {
              localLang.update = false;
            }

            this.storageHelper.saveItem(localLang, key);

            tmpCounter++;

            if (tmpCounter === languages.length) {
              this.refreshUpdateState();
            }
          }
        });
    }
  }

  private setHeaders(): void {
    this.headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        Accept        : 'application/json',
      });
    this.options = {headers: this.headers, withCredentials: true};
  }

  private updateStorage(): Promise<void> {
    return this.storageHelper.fetchStorage()
      .then(() => {
        this.storeSize = this.storageHelper.quotaToString();
      });
  }
}
