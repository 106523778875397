<div
  class="media_detail_container_search"
  *ngIf="(mediaCategory.Medias | search:'Name':filterString).length !== 0"
  [ngClass]="{'media_detail_container': !isSearchView, 'media_detail_container_search': isSearchView}">
  <div class="ui-g">
    <div class="ui-g-4">
      <h2 class="media_detail_container_breadcrumb">
        {{ mediasTitle }} > {{mediaCategory.Name }}
      </h2>
    </div>
    <div class="ui-g-8">
      <div class="separator_line_container">
        <div class="separator_line"></div>
      </div>
    </div>
  </div>
  <mat-nav-list class="media_list">
    <mat-list-item
      *ngFor="let media of mediaCategory.Medias | search:'Name':filterString"
      (click)="selectMedia(media)">
      <img [src]="mimeHelper.getIconFromFileName(media.Code)"  alt="filetype"/>
      <div class="media_label">
        <p>{{ media.Name }}</p>
      </div>
        <mat-checkbox
          color="primary"
          *ngIf="selMode"
          [checked]="isSelected(media)"
          (change)="onSelMedia($event, media)">
        </mat-checkbox>
    </mat-list-item>
  </mat-nav-list >
  <button
    mat-raised-button
    type="button"
    class="select_elements"
    (click)="setSelMode()"
    *ngIf="!selMode"
    color="primary">
      {{ 'select_elements' | translate }}
      <img src="./assets/icons/ic_add_customer.png"  alt="add_customer"/>
  </button>
  <button
    mat-raised-button
    type="button"
    class="select_elements"
    (click)="saveCustomer()"
    *ngIf="selMode"
    color="primary">
      {{ 'done' | translate }}
      <img src="./assets/icons/ic_add_customer.png"  alt="add customer"/>
  </button>
</div>

